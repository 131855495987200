import { ChainId } from "@certusone/wormhole-sdk";
import { makeStyles, Box } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useMemo, useState } from "react";

import CustomDialog from "./CustomDialog";
import DialogList, { DialogListItemType } from "./DialogList";
import DisableWrapper from "./DisableWrapper";
import { ChainInfo, CHAINS_RECORD } from "../utils/consts";

interface ChainSelectProps {
  chains: ChainInfo[];
  label: string;
  value: ChainId;
  onChange: (chain: ChainId) => void;
  disabled: any;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  selectBox: {
    cursor: "pointer",
    padding: "5px 20px",
    border: "1px solid",
    borderColor: theme.palette.divider,
    borderRadius: "12px",
    backgroundColor: theme.palette.secondary.main,
    width: "100%",
    "&:hover": {
      filter: "brightness(1.1)",
    },
  },
  chainBox: {
    display: "flex",
    alignItems: "center",
    padding: "2px 0 5px 0",
  },
  label: {
    fontSize: "10px",
    margin: "4px 0",
    color: theme.palette.secondary.light,
  },
  icon: {
    height: 28,
    maxWidth: 28,
    "@media (max-width:450px)": {
      height: 20,
      maxWidth: 20,
    },
  },
  chainName: {
    fontSize: "16px",
    margin: "0 6px",
    fontWeight: 600,
    "@media (max-width:450px)": {
      margin: "0 2px",
    },
  },
}));

export default function ChainSelect({
  chains,
  label,
  value,
  onChange,
  disabled,
  className,
}: ChainSelectProps) {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const formattedChains = useMemo<DialogListItemType<ChainInfo>[]>(
    () =>
      chains.map((chain) => ({
        icon: chain.logo,
        title: chain.name,
        item: chain,
        onSelect: () => {
          onChange(chain.id);
          handleClose();
        },
      })),
    [chains]
  );

  const selectedChain = useMemo<ChainInfo>(() => CHAINS_RECORD[value], [value]);

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  return (
    <>
      <DisableWrapper className={className} disable={disabled}>
        <Box className={classes.selectBox} onClick={handleOpen}>
          <div className={classes.label}>{label}</div>
          <Box className={classes.chainBox}>
            <img
              src={selectedChain.logo}
              alt={selectedChain.name}
              className={classes.icon}
            />
            <div className={classes.chainName}>{selectedChain.name}</div>
            <ExpandMoreIcon fontSize="medium" />
          </Box>
        </Box>
      </DisableWrapper>
      <CustomDialog
        title="Select Chain"
        open={open}
        handleClose={handleClose}
        content={<DialogList items={formattedChains} />}
      />
    </>
  );
}
