import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  disable: {
    pointerEvents: "none",
    opacity: 0.5,
  },
  default: {
    width: "100%",
  },
}));

export default function DisableWrapper({
  children,
  disable,
  className,
}: {
  children: React.ReactNode;
  disable?: boolean;
  className?: string;
}) {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.default,
        disable ? classes.disable : "",
        className ? className : ""
      )}
    >
      {children}
    </div>
  );
}
