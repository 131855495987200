import {
  CHAIN_ID_BSC,
  CHAIN_ID_ETH,
  CHAIN_ID_SOLANA,
  ChainId,
} from "@certusone/wormhole-sdk";
import { makeStyles } from "@material-ui/core";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import useIsWalletReady from "../../hooks/useIsWalletReady";
import {
  selectTransferAmount,
  selectTransferIsSourceComplete,
  selectTransferShouldLockFields,
  selectTransferSourceBalanceString,
  selectTransferSourceChain,
  selectTransferSourceError,
  selectTransferSourceParsedTokenAccount,
  selectTransferTargetChain,
} from "../../store/selectors";
import {
  incrementStep,
  setAmount,
  setSourceChain,
  setTargetChain,
} from "../../store/transferSlice";
import { CHAINS, CLUSTER } from "../../utils/consts";
import ButtonWithLoader from "../ButtonWithLoader";
import ChainSelect from "../ChainSelect";
import ChainSelectArrow from "../ChainSelectArrow";
import KeyAndBalance from "../KeyAndBalance";
import LowBalanceWarning from "../LowBalanceWarning";
import SolanaTPSWarning from "../SolanaTPSWarning";
import { TokenSelector } from "../TokenSelectors/SourceTokenSelector";
import SourceAssetWarning from "./SourceAssetWarning";
import ChainWarningMessage from "../ChainWarningMessage";
import useIsTransferLimited from "../../hooks/useIsTransferLimited";
import TransferLimitedWarning from "./TransferLimitedWarning";
import { RootState } from "../../store";
import useTransferControl from "../../hooks/useTransferControl";
import transferRules from "../../config/transferRules";
import useRoundTripTransfer from "../../hooks/useRoundTripTransfer";
import useMinimumAmountGuard from "../../hooks/useMinimumAmountGuard";
import AmountField from "../AmountField";

const useStyles = makeStyles((theme) => ({
  chainSelectWrapper: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  chainSelectContainer: {
    flexBasis: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  chainSelectArrow: {
    position: "relative",
    top: "12px",
    [theme.breakpoints.down("sm")]: { transform: "rotate(90deg)" },
  },
  transferField: {
    marginTop: "10px",
  },
  chainCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
  },
  firstCS: {
    marginRight: "10px",
  },
}));

function Source() {
  const chains = CHAINS;
  const classes = useStyles();
  const dispatch = useDispatch();
  const sourceChain = useSelector(selectTransferSourceChain);
  const targetChain = useSelector(selectTransferTargetChain);
  const targetChainOptions = useMemo(
    () => chains.filter((c) => c.id !== sourceChain),
    [sourceChain, chains]
  );
  const parsedTokenAccount = useSelector(
    selectTransferSourceParsedTokenAccount
  );
  const uiAmountString = useSelector(selectTransferSourceBalanceString);
  const amount = useSelector(selectTransferAmount);
  const error = useSelector(selectTransferSourceError);
  const isSourceComplete = useSelector(selectTransferIsSourceComplete);
  const shouldLockFields = useSelector(selectTransferShouldLockFields);
  const { isReady, statusMessage, walletAddress } =
    useIsWalletReady(sourceChain);
  const isTransferLimited = useIsTransferLimited();

  const handleSourceChange = useCallback(
    (chain: ChainId) => {
      dispatch(setSourceChain(chain));
    },
    [dispatch]
  );
  const handleTargetChange = useCallback(
    (chain: ChainId) => {
      dispatch(setTargetChain(chain));
    },
    [dispatch]
  );
  const handleAmountChange = useCallback(
    (event) => {
      dispatch(setAmount(event.target.value));
    },
    [dispatch]
  );
  const handleMaxClick = useCallback(() => {
    if (uiAmountString) {
      dispatch(setAmount(uiAmountString));
    }
  }, [dispatch, uiAmountString]);
  const handleNextClick = useCallback(() => {
    dispatch(incrementStep());
  }, [dispatch]);

  const selectedTokenAddress = useSelector(
    (state: RootState) => state.transfer.sourceParsedTokenAccount?.mintKey
  );
  const { isTransferDisabled, warnings, ids } = useTransferControl(
    transferRules,
    sourceChain,
    targetChain,
    selectedTokenAddress
  );
  /* Only allow sending from ETH <-> BSC Pandle Token */
  const isPandle = (id: string) => id === "pandle";
  const isRoundTripTransfer = useRoundTripTransfer(
    CHAIN_ID_ETH,
    CHAIN_ID_BSC,
    sourceChain,
    // (chainId: number) => handleTargetChange({ target: { value: chainId } }),
    (chainId: number) => handleTargetChange(chainId as ChainId),
    ids,
    isPandle
  );
  /* End pandle token check */
  const { decimals = 0, isNativeAsset = false } = parsedTokenAccount || {};
  const { isBelowMinimum, minimum } = useMinimumAmountGuard({
    amount,
    sourceChain,
    decimals,
    isNativeAsset,
  });
  return (
    <>
      <div className={classes.chainCont}>
        <ChainSelect
          label="Source Chain"
          value={sourceChain}
          onChange={handleSourceChange}
          disabled={shouldLockFields}
          chains={chains}
          className={classes.firstCS}
        />

        <ChainSelectArrow
          onClick={() => {
            dispatch(setSourceChain(targetChain));
          }}
          disabled={shouldLockFields}
        />
        <ChainSelect
          label="Target Chain"
          value={targetChain}
          onChange={handleTargetChange}
          disabled={shouldLockFields || isRoundTripTransfer}
          chains={targetChainOptions}
        />
      </div>

      <div className={classes.transferField}>
        <AmountField
          value={amount}
          onChange={handleAmountChange}
          disabled={
            !((isReady || uiAmountString) && !!walletAddress) ||
            isTransferDisabled ||
            shouldLockFields
          }
          error={isBelowMinimum}
          balance={uiAmountString}
          // onMaxClick={
          //   uiAmountString && !parsedTokenAccount?.isNativeAsset
          //     ? handleMaxClick
          //     : undefined
          // }
          onMaxClick={handleMaxClick}
          selector={
            <TokenSelector
              disabled={
                !((isReady || uiAmountString) && !!walletAddress) ||
                shouldLockFields
              }
            />
          }
        />
      </div>
      <KeyAndBalance chainId={sourceChain} />
      <LowBalanceWarning chainId={sourceChain} />
      {sourceChain === CHAIN_ID_SOLANA && CLUSTER === "mainnet" && (
        <SolanaTPSWarning />
      )}
      <SourceAssetWarning
        sourceChain={sourceChain}
        sourceAsset={parsedTokenAccount?.mintKey}
      />
      {warnings.map((message, key) => (
        <ChainWarningMessage key={key} message={message} />
      ))}
      <TransferLimitedWarning isTransferLimited={isTransferLimited} />
      {walletAddress && (
        <ButtonWithLoader
          disabled={isTransferDisabled || !isSourceComplete || isBelowMinimum}
          onClick={handleNextClick}
          showLoader={false}
          error={isTransferDisabled ? "" : statusMessage || error}
        >
          Next
        </ButtonWithLoader>
      )}
    </>
  );
}

export default Source;
