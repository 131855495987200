import {
  ChainId,
  CHAIN_ID_SOLANA,
  getEmitterAddressEth,
  getEmitterAddressSolana,
  hexToUint8Array,
  isEVMChain,
  ParsedVaa,
  parseSequenceFromLogEth,
  parseSequenceFromLogSolana,
  parseTransferPayload,
  parseVaa,
  uint8ArrayToHex,
  CHAIN_ID_TO_NAME,
} from "@certusone/wormhole-sdk";
import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Connection } from "@solana/web3.js";
import { ethers } from "ethers";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { repairVaa } from "../utils/repairVaa";
import { useEthereumProvider } from "../contexts/EthereumProviderContext";
import useIsWalletReady from "../hooks/useIsWalletReady";
import { setRecoveryVaa } from "../store/transferSlice";
import {
  getBridgeAddressForChain,
  getTokenBridgeAddressForChain,
  SOLANA_HOST,
  SOL_TOKEN_BRIDGE_ADDRESS,
  WORMHOLE_RPC_HOSTS,
  CLUSTER,
  getTxFromVaaApiRef,
  CHAINS,
} from "../utils/consts";
import { getSignedVAAWithRetry } from "../utils/getSignedVAAWithRetry";
import parseError from "../utils/parseError";
import ButtonWithLoader from "./ButtonWithLoader";
import ChainSelect from "./ChainSelect";
import KeyAndBalance from "./KeyAndBalance";
import PendingVAAWarning from "./Transfer/PendingVAAWarning";
import { useVaaVerifier } from "../hooks/useVaaVerifier";
import ChainWarningMessage from "./ChainWarningMessage";
import CustomTextField from "./CustomTextField";
import { useDeepLinkRecoveryParams } from "../hooks/useDeepLinkRecoveryParams";
import {
  setIsRecovery,
  setSignedVAAHex,
  setSourceChain,
} from "../store/attestSlice";

const NOT_SUPPORTED_VAA_WARNING_MESSAGE = (
  <>
    This VAA was not generated from Token Bridge, or the type is not supported
    yet.
  </>
);

const useStyles = makeStyles((theme) => ({
  mainCard: {
    padding: "30px 20px",
  },
  textField: {
    margin: "10px 0",
    padding: "5px 20px",
    borderColor: theme.palette.divider,
  },
  select: {
    marginTop: "10px",
  },
  header: {
    textAlign: "center",
    fontWeight: 600,
    letterSpacing: 2,
  },
  advancedContainer: {
    padding: theme.spacing(2, 0),
  },
  relayAlert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& > .MuiAlert-message": {
      width: "100%",
    },
  },
}));

async function fetchSignedVAA(
  chainId: ChainId,
  emitterAddress: string,
  sequence: string
) {
  const { vaaBytes, isPending } = await getSignedVAAWithRetry(
    chainId,
    emitterAddress,
    sequence,
    WORMHOLE_RPC_HOSTS.length
  );

  const GUARDIAN_SET =
    CLUSTER === "mainnet"
      ? {
          index: 4,
          keys: [
            "0x5893b5a76c3f739645648885bdccc06cd70a3cd3",
            "0xff6cb952589bde862c25ef4392132fb9d4a42157",
            "0x114de8460193bdf3a2fcf81f86a09765f4762fd1",
            "0x107a0086b32d7a0977926a205131d8731d39cbeb",
            "0x8c82b2fd82faed2711d59af0f2499d16e726f6b2",
            "0x11b39756c042441be6d8650b69b54ebe715e2343",
            "0x54ce5b4d348fb74b958e8966e2ec3dbd4958a7cd",
            "0x15e7caf07c4e3dc8e7c469f92c8cd88fb8005a20",
            "0x74a3bf913953d695260d88bc1aa25a4eee363ef0",
            "0x000ac0076727b35fbea2dac28fee5ccb0fea768e",
            "0xaf45ced136b9d9e24903464ae889f5c8a723fc14",
            "0xf93124b7c738843cbb89e864c862c38cddcccf95",
            "0xd2cc37a4dc036a8d232b48f62cdd4731412f4890",
            "0xda798f6896a3331f64b48c12d1d57fd9cbe70811",
            "0x71aa1be1d36cafe3867910f99c09e347899c19c3",
            "0x8192b6e7387ccd768277c17dab1b7a5027c0b3cf",
            "0x178e21ad2e77ae06711549cfbb1f9c7a9d8096e8",
            "0x5e1487f35515d02a92753504a8d75471b9f49edb",
            "0x6fbebc898f403e4773e95feb15e80c9a99c8348d",
          ],
          expiry: 0,
        }
      : {
          index: 0,
          keys: ["0x13947Bd48b18E53fdAeEe77F3473391aC727C638"],
          expiry: 0,
        };

  const vaa = vaaBytes
    ? repairVaa(uint8ArrayToHex(vaaBytes), GUARDIAN_SET)
    : undefined;
  return {
    vaa,
    isPending,
    error: null,
  };
}

function handleError(e: any, enqueueSnackbar: any) {
  console.error(e);
  enqueueSnackbar(null, {
    content: <Alert severity="error">{parseError(e)}</Alert>,
  });
  return { vaa: null, isPending: false, error: parseError(e) };
}

async function evm(
  provider: ethers.providers.Web3Provider,
  tx: string,
  enqueueSnackbar: any,
  chainId: ChainId,
  nft: boolean
) {
  try {
    const receipt = await provider.getTransactionReceipt(tx);
    const sequence = parseSequenceFromLogEth(
      receipt,
      getBridgeAddressForChain(chainId)
    );
    const emitterAddress = getEmitterAddressEth(
      // nft
      //   ? getNFTBridgeAddressForChain(chainId)
      //   :
      getTokenBridgeAddressForChain(chainId)
    );
    return await fetchSignedVAA(chainId, emitterAddress, sequence);
  } catch (e) {
    return handleError(e, enqueueSnackbar);
  }
}

async function solana(tx: string, enqueueSnackbar: any, nft: boolean) {
  try {
    const connection = new Connection(SOLANA_HOST, "confirmed");
    const info = await connection.getTransaction(tx);
    if (!info) {
      throw new Error("An error occurred while fetching the transaction info");
    }
    const sequence = parseSequenceFromLogSolana(info);
    // const emitterAddress = await getEmitterAddressSolana(
    //   nft ? SOL_NFT_BRIDGE_ADDRESS : SOL_TOKEN_BRIDGE_ADDRESS
    // );
    const emitterAddress = await getEmitterAddressSolana(
      SOL_TOKEN_BRIDGE_ADDRESS
    );
    return await fetchSignedVAA(CHAIN_ID_SOLANA, emitterAddress, sequence);
  } catch (e) {
    return handleError(e, enqueueSnackbar);
  }
}

export default function Recovery() {
  const classes = useStyles();
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [recoverySourceChain, setRecoverySourceChain] =
    useState<ChainId>(CHAIN_ID_SOLANA);
  const { provider } = useEthereumProvider(recoverySourceChain as any);

  const [recoverySourceTx, setRecoverySourceTx] = useState("");
  const [recoverySourceTxIsLoading, setRecoverySourceTxIsLoading] =
    useState(false);
  const [, setRecoverySourceTxError] = useState("");
  const [recoverySignedVAA, setRecoverySignedVAA] = useState("");
  const { isNFTTransfer, isTokenBridgeTransfer, isTokenBridgetAttest } =
    useVaaVerifier(recoverySignedVAA);
  const [recoveryParsedVAA, setRecoveryParsedVAA] = useState<ParsedVaa | null>(
    null
  );
  const [isVAAPending, setIsVAAPending] = useState(false);
  const { isReady, statusMessage } = useIsWalletReady(recoverySourceChain);
  const walletConnectError =
    isEVMChain(recoverySourceChain) && !isReady ? statusMessage : "";

  const parsedPayload = useMemo(() => {
    try {
      return recoveryParsedVAA?.payload
        ? parseTransferPayload(
            Buffer.from(new Uint8Array(recoveryParsedVAA.payload))
          )
        : null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [recoveryParsedVAA]);

  const { search } = useLocation();
  const { sourceChain, transactionId, vaaHex } =
    useDeepLinkRecoveryParams(search);
  //This effect initializes the state based on the path params.

  useEffect(() => {
    try {
      if (sourceChain && transactionId) {
        setRecoverySourceChain(sourceChain);
        setRecoverySourceTx(transactionId);
      } else if (vaaHex) {
        setRecoverySignedVAA(vaaHex);
      }
    } catch (e) {
      console.error(e);
      console.error("Invalid path params specified.");
    }
  }, [sourceChain, transactionId, vaaHex]);

  useEffect(() => {
    if (recoverySourceTx && (!isEVMChain(recoverySourceChain) || isReady)) {
      let cancelled = false;
      if (isEVMChain(recoverySourceChain) && provider) {
        setRecoverySourceTxError("");
        setRecoverySourceTxIsLoading(true);
        (async () => {
          const { vaa, isPending, error } = await evm(
            provider,
            recoverySourceTx,
            enqueueSnackbar,
            recoverySourceChain,
            false
          );
          if (!cancelled) {
            setRecoverySourceTxIsLoading(false);
            if (vaa) {
              setRecoverySignedVAA(vaa);
            }
            if (error) {
              setRecoverySourceTxError(error);
            }
            setIsVAAPending(isPending);
          }
        })();
      } else if (recoverySourceChain === CHAIN_ID_SOLANA) {
        setRecoverySourceTxError("");
        setRecoverySourceTxIsLoading(true);
        (async () => {
          const { vaa, isPending, error } = await solana(
            recoverySourceTx,
            enqueueSnackbar,
            false
          );
          if (!cancelled) {
            setRecoverySourceTxIsLoading(false);
            if (vaa) {
              setRecoverySignedVAA(vaa);
            }
            if (error) {
              setRecoverySourceTxError(error);
            }
            setIsVAAPending(isPending);
          }
        })();
      }
      return () => {
        cancelled = true;
      };
    }
  }, [
    recoverySourceChain,
    recoverySourceTx,
    provider,
    enqueueSnackbar,
    isReady,
  ]);

  const handleSourceChainChange = useCallback((chain: ChainId) => {
    setRecoverySourceTx("");
    setRecoverySourceChain(chain);
  }, []);

  const handleSourceTxChange = useCallback((event) => {
    setRecoverySourceTx(event.target.value.trim());
  }, []);

  useEffect(() => {
    if (recoverySignedVAA) {
      try {
        const parsedVAA = parseVaa(hexToUint8Array(recoverySignedVAA));
        setRecoveryParsedVAA(parsedVAA);
      } catch (e) {
        console.error(e);
        setRecoveryParsedVAA(null);
      }
    }
  }, [recoverySignedVAA]);

  const parsedPayloadTargetChain = parsedPayload?.targetChain;
  const enableAdvToolsRecovery =
    isTokenBridgetAttest || (recoverySignedVAA && parsedPayloadTargetChain);
  //&& (isNFTTransfer || isTokenBridgeTransfer);
  const enableRecovery = !!recoveryParsedVAA?.emitterChain;

  const handleRecoverClickBase = useCallback(
    (useRelayer: boolean) => {
      console.log("parsedPayload", parsedPayload);
      console.log("parsedPayloadTargetChain", parsedPayloadTargetChain);
      if (isTokenBridgetAttest) {
        dispatch(setSourceChain(recoverySourceChain));
        dispatch(setIsRecovery(true));
        dispatch(setSignedVAAHex(recoverySignedVAA));
        push("/register");
      } else if (
        enableAdvToolsRecovery &&
        recoverySignedVAA &&
        parsedPayloadTargetChain
      ) {
        dispatch(
          setRecoveryVaa({
            vaa: recoverySignedVAA,
            useRelayer,
            parsedPayload: {
              targetChain: parsedPayload.targetChain as ChainId,
              targetAddress: parsedPayload.targetAddress,
              originChain: parsedPayload.originChain as ChainId,
              originAddress: parsedPayload.originAddress,
              amount:
                "amount" in parsedPayload
                  ? parsedPayload.amount.toString()
                  : "",
            },
          })
        );
        push("/transfer");
      }
    },
    [
      dispatch,
      enableAdvToolsRecovery,
      recoverySignedVAA,
      parsedPayloadTargetChain,
      parsedPayload,
      isTokenBridgetAttest,
      recoverySourceChain,
      push,
    ]
  );

  const handleRecoverClick = useCallback(async () => {
    if (enableAdvToolsRecovery) return handleRecoverClickBase(false);
    const { emitterChain, emitterAddress, sequence } = recoveryParsedVAA!;
    const txDetails = await fetch(
      getTxFromVaaApiRef(
        [emitterChain, emitterAddress.toString("hex"), sequence].join("/")
      )
    ).then((res) => res.json());
    const txHash = txDetails?.sourceChain?.transaction?.txHash;
    const sourceChain = CHAIN_ID_TO_NAME[emitterChain as ChainId];

    if ([sourceChain, txHash].every((i) => typeof i === "string")) {
      window.location.href = `${window.location.origin}?txHash=${txHash}&sourceChain=${sourceChain}`;
    }
  }, [enableAdvToolsRecovery, handleRecoverClickBase, recoveryParsedVAA]);

  return (
    <Container style={{ marginTop: "80px" }} maxWidth="sm">
      <Typography className={classes.header} variant="h2">
        Redeem Tokens
      </Typography>
      <Box className={classes.mainCard}>
        <Alert
          severity="info"
          variant="outlined"
          style={{ borderRadius: "12px" }}
        >
          If you have sent your tokens but have not redeemed them, you may paste
          in the Source Transaction ID (from Step 3) to resume your transfer.
        </Alert>
        <ChainSelect
          label="Source Chain"
          value={recoverySourceChain}
          onChange={handleSourceChainChange}
          disabled={!!recoverySignedVAA}
          chains={CHAINS}
          className={classes.select}
        />

        {isEVMChain(recoverySourceChain) ? (
          <KeyAndBalance chainId={recoverySourceChain} />
        ) : null}

        <CustomTextField
          placeholder="Source Tx (paste here)"
          disabled={
            !!recoverySignedVAA ||
            recoverySourceTxIsLoading ||
            !!walletConnectError
          }
          value={recoverySourceTx}
          className={classes.textField}
          onChange={handleSourceTxChange}
          hideIcon
        />

        {!enableRecovery &&
          !(isNFTTransfer || isTokenBridgeTransfer || isTokenBridgetAttest) && (
            <ChainWarningMessage>
              {NOT_SUPPORTED_VAA_WARNING_MESSAGE}
            </ChainWarningMessage>
          )}

        {isVAAPending && (
          <PendingVAAWarning sourceChain={recoverySourceChain} />
        )}

        <ButtonWithLoader
          onClick={handleRecoverClick}
          disabled={!enableRecovery}
          showLoader={recoverySourceTxIsLoading}
        >
          Recover
        </ButtonWithLoader>
      </Box>
    </Container>
  );
}
