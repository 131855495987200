import {
  Step,
  Stepper,
  StepLabel,
  StepConnector,
  StepIconProps,
  makeStyles,
  withStyles,
} from "@material-ui/core";

import Check from "@material-ui/icons/Check";
import clsx from "clsx";

const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    "& $line": {
      borderColor: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector);

const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: theme.palette.primary.main,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 18,
    fontWeight: 600,
  },
}));

function QontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    // "& .MuiStepLabel-active": {
    //   fontWeight: 600,
    // },
  },
}));

function CustomStepper({
  activeStep,
  steps,
  content,
  actions,
}: {
  activeStep: number;
  steps: string[];
  content: React.ReactNode;
  actions?: Record<string, () => void>;
}) {
  const classes = useStyles();

  return (
    <>
      <Stepper
        className={classes.root}
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step key={label} onClick={actions?.[label]}>
            <StepLabel
              style={actions?.[label] ? { cursor: "pointer" } : {}}
              StepIconComponent={QontoStepIcon}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {content}
    </>
  );
}

export default CustomStepper;
