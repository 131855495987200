import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ChainId } from "@certusone/wormhole-sdk";
import { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import useFetchForeignAsset from "../../hooks/useFetchForeignAsset";
import { useHandleCreateWrapped } from "../../hooks/useHandleCreateWrapped";
import useIsWalletReady from "../../hooks/useIsWalletReady";
import {
  selectAttestSourceAsset,
  selectAttestSourceChain,
  selectAttestTargetChain,
  selectAttestIsRecovery,
} from "../../store/selectors";
import { setTargetChain } from "../../store/attestSlice";
import ButtonWithLoader from "../ButtonWithLoader";
import KeyAndBalance from "../KeyAndBalance";
import WaitingForWalletMessage from "./WaitingForWalletMessage";
import ChainSelect from "../ChainSelect";
import { ATTEST_CHAINS } from "../../utils/consts";

const useStyles = makeStyles((theme) => ({
  alignCenter: {
    margin: "0 auto",
    display: "block",
    textAlign: "center",
  },
  spacer: {
    height: theme.spacing(2),
  },
  alert: {
    marginTop: "10px",
    borderRadius: "12px",
  },
}));

function Create() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const targetChain = useSelector(selectAttestTargetChain);
  const originAsset = useSelector(selectAttestSourceAsset);
  const originChain = useSelector(selectAttestSourceChain);
  const isRecovery = useSelector(selectAttestIsRecovery);
  const { isReady, statusMessage } = useIsWalletReady(targetChain);
  const foreignAssetInfo = useFetchForeignAsset(
    originChain,
    originAsset,
    targetChain
  );
  const shouldUpdate = foreignAssetInfo.data?.doesExist;
  const foreignAddress = foreignAssetInfo.data?.address;
  const error = foreignAssetInfo.error || statusMessage;
  const { handleClick, disabled, showLoader } = useHandleCreateWrapped(
    shouldUpdate || false,
    foreignAddress
  );

  const chains = useMemo(
    () => ATTEST_CHAINS.filter((c) => c.id !== originChain),
    [originChain]
  );

  const handleTargetChange = useCallback(
    (chain: ChainId) => {
      dispatch(setTargetChain(chain));
    },
    [dispatch]
  );

  return (
    <>
      {isRecovery && (
        <Alert
          severity="info"
          variant="outlined"
          style={{ marginBottom: "10px", borderRadius: "12px" }}
        >
          <Typography>
            Make sure that you have selected the correct target chain for
            recovering the attest transaction.
          </Typography>
        </Alert>
      )}

      {isRecovery && (
        <ChainSelect
          label="Target Chain"
          value={targetChain}
          onChange={handleTargetChange}
          disabled={false}
          chains={chains}
        />
      )}

      <KeyAndBalance chainId={targetChain} />

      {foreignAssetInfo.isFetching ? (
        <>
          <div className={classes.spacer} />
          <CircularProgress className={classes.alignCenter} />
        </>
      ) : (
        <>
          <ButtonWithLoader
            disabled={!isReady || disabled}
            onClick={handleClick}
            showLoader={showLoader}
            error={error}
          >
            {/* {shouldUpdate ? "Update" : "Create"} */}
            Confirm wrap creation
          </ButtonWithLoader>
          <WaitingForWalletMessage />
        </>
      )}
    </>
  );
}

export default Create;
