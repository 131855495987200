import React, { useState, useEffect } from "react";
import { Button, makeStyles, Tooltip } from "@material-ui/core";
import { LinkOff } from "@material-ui/icons";
import { Connection, PublicKey } from "@solana/web3.js";
import { getAllDomains, performReverseLookup } from "@bonfida/spl-name-service";
import { SOLANA_HOST } from "../utils/consts";

// Create Solana connection
const SOLANA_CONNECTION = new Connection(SOLANA_HOST);

const useStyles = makeStyles((theme) => ({
  button: {
    display: "flex",
    width: "100%",
    borderRadius: "12px",
    height: "50px",
    fontSize: "18px",
    marginTop: "10px",
  },
  buttonConnected: {
    backgroundColor: theme.palette.secondary.main,
    border: "1px solid",
    borderColor: theme.palette.divider,
    borderRadius: "12px",
    width: "100%",
    marginTop: "10px",
    height: "40px",
    letterSpacing: "0.5px",
    "&:hover": {
      filter: "brightness(1.1)",
      backgroundColor: theme.palette.secondary.main,
    },
  },
  icon: {
    height: 24,
    width: 24,
  },
}));

const ToggleConnectedButton = ({
                                 connect,
                                 disconnect,
                                 connected,
                                 pk,
                                 walletIcon,
                                 disabled = false,
                               }: {
  connect(): any;
  disconnect(): any;
  connected: boolean;
  pk: string;
  walletIcon?: string;
  disabled?: boolean;
}) => {
  const classes = useStyles();
  const [solDomain, setSolDomain] = useState<string | null>(null);

  useEffect(() => {
    const fetchSolDomain = async () => {
      try {
        debugger
        if (connected && pk) {
          debugger
          const SOLANA_HOST = process.env.REACT_APP_RPC; // Use appropriate cluster
          // @ts-ignore
          const SOLANA_CONNECTION_NEW = new Connection(SOLANA_HOST);
          debugger
          const publicKey = new PublicKey(pk);
          debugger
          const allDomainKeys = await getAllDomains(SOLANA_CONNECTION_NEW, publicKey);
          debugger
          const allDomainNames = await Promise.all(allDomainKeys.map(key=>{return performReverseLookup(SOLANA_CONNECTION_NEW,key)}));
          console.log(`${publicKey} owns the following SNS domains:`)
          console.log('all domains', allDomainNames);
          debugger
          console.log(allDomainKeys)
          // Filter to ensure only valid strings are processed
          const validDomains = allDomainNames.filter(name => typeof name === 'string' && name.trim() !== '');

          // Set the first valid domain, if any
          if (validDomains.length > 0) {
            debugger
            setSolDomain(validDomains[0] + '.SOL');
            console.log('Set domain to:', validDomains[0]);
          } else {
            console.log('No valid SNS domains found.');
          }
        }
      } catch (error) {
        console.error("Error fetching .sol domain:", error);
      }
    };

    fetchSolDomain();
  }, [connected, pk]);

  const is0x = pk.startsWith("0x");
  return (
    <div>
      {connected ? (
        <Tooltip title={pk}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={disconnect}
            className={classes.buttonConnected}
            startIcon={
              walletIcon ? (
                <img className={classes.icon} src={walletIcon} alt="Wallet" />
              ) : (
                <LinkOff />
              )
            }
          >
            {solDomain
              ? `Disconnect ${solDomain}` // Show the .sol domain if available
              : `Disconnect ${pk.substring(0, is0x ? 6 : 3)}...${pk.substr(
                pk.length - (is0x ? 4 : 3)
              )}`}
          </Button>
        </Tooltip>
      ) : (
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={connect}
          className={classes.button}
          disabled={disabled}
        >
          Connect
        </Button>
      )}
    </div>
  );
};

export default ToggleConnectedButton;
