import {
  attestFromEth,
  attestFromSolana,
  ChainId,
  CHAIN_ID_SOLANA,
  getEmitterAddressEth,
  getEmitterAddressSolana,
  getSignedVAAWithRetry,
  isEVMChain,
  parseSequenceFromLogEth,
  parseSequenceFromLogSolana,
  uint8ArrayToHex,
} from "@certusone/wormhole-sdk";
import { Alert } from "@material-ui/lab";
import { Connection, PublicKey } from "@solana/web3.js";
import { SolanaWallet } from "@xlabs-libs/wallet-aggregator-solana";
import { Signer } from "ethers";
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";

import { useEthereumProvider } from "../contexts/EthereumProviderContext";
import { useSolanaWallet } from "../contexts/SolanaWalletContext";
import {
  setAttestTx,
  setIsSending,
  setSignedVAAHex,
} from "../store/attestSlice";
import {
  selectAttestIsSendComplete,
  selectAttestIsSending,
  selectAttestIsTargetComplete,
  selectAttestSourceAsset,
  selectAttestSourceChain,
  selectAttestTargetChain,
} from "../store/selectors";
import {
  getBridgeAddressForChain,
  getTokenBridgeAddressForChain,
  SOLANA_HOST,
  SOL_BRIDGE_ADDRESS,
  SOL_TOKEN_BRIDGE_ADDRESS,
  WORMHOLE_RPC_HOSTS,
} from "../utils/consts";
import parseError from "../utils/parseError";
import { signSendAndConfirm } from "../utils/solana";
import { CHAINS_RECORD } from "../utils/consts";
// import { createToken } from "../utils/indexing";

async function evm(
  dispatch: any,
  enqueueSnackbar: any,
  signer: Signer,
  sourceAsset: string,
  chainId: ChainId,
  onError: (error: any) => void,
  onStart: (extra?: Partial<any>) => void,
  emit: (wallet: string, txId?: string) => void
) {
  dispatch(setIsSending(true));
  try {
    // Klaytn requires specifying gasPrice
    // const overrides =
    //   chainId === CHAIN_ID_KLAYTN
    //     ? { gasPrice: (await signer.getGasPrice()).toString() }
    //     : {};
    const overrides = {};
    const receipt = await attestFromEth(
      getTokenBridgeAddressForChain(chainId),
      signer,
      sourceAsset,
      overrides
    );
    // createToken(chainId, receipt.transactionHash);
    onStart?.({ txId: receipt.transactionHash });
    dispatch(
      setAttestTx({ id: receipt.transactionHash, block: receipt.blockNumber })
    );
    enqueueSnackbar(null, {
      content: <Alert severity="success">Transaction confirmed</Alert>,
    });

    const sequence = parseSequenceFromLogEth(
      receipt,
      getBridgeAddressForChain(chainId)
    );
    const emitterAddress = getEmitterAddressEth(
      getTokenBridgeAddressForChain(chainId)
    );
    enqueueSnackbar(null, {
      content: <Alert severity="info">Fetching VAA</Alert>,
    });

    const wAddress = await signer.getAddress();
    emit(wAddress, receipt.transactionHash);

    const { vaaBytes } = await getSignedVAAWithRetry(
      WORMHOLE_RPC_HOSTS,
      chainId,
      emitterAddress,
      sequence
    );
    dispatch(setSignedVAAHex(uint8ArrayToHex(vaaBytes)));
    enqueueSnackbar(null, {
      content: <Alert severity="success">Fetched Signed VAA</Alert>,
    });
  } catch (e) {
    console.error(e);
    enqueueSnackbar(null, {
      content: <Alert severity="error">{parseError(e)}</Alert>,
    });
    dispatch(setIsSending(false));
    onError(e);
    const wAddress = await signer.getAddress();
    emit(wAddress);
  }
}

async function solana(
  dispatch: any,
  enqueueSnackbar: any,
  solPK: PublicKey,
  sourceAsset: string,
  wallet: SolanaWallet,
  onError: (error: any) => void,
  onStart: (extra?: Partial<any>) => void,
  emit: (wallet: string, txId?: string) => void
) {
  dispatch(setIsSending(true));
  try {
    const connection = new Connection(SOLANA_HOST, "confirmed");
    const transaction = await attestFromSolana(
      connection,
      SOL_BRIDGE_ADDRESS,
      SOL_TOKEN_BRIDGE_ADDRESS,
      solPK,
      sourceAsset
    );
    const txid = await signSendAndConfirm(wallet, transaction);
    // createToken(CHAIN_ID_SOLANA, txid);
    enqueueSnackbar(null, {
      content: <Alert severity="success">Transaction confirmed</Alert>,
    });
    const info = await connection.getTransaction(txid);
    if (!info) {
      // TODO: error state
      throw new Error("An error occurred while fetching the transaction info");
    }
    onStart?.({ txId: txid });
    dispatch(setAttestTx({ id: txid, block: info.slot }));
    const sequence = parseSequenceFromLogSolana(info);
    const emitterAddress = await getEmitterAddressSolana(
      SOL_TOKEN_BRIDGE_ADDRESS
    );
    enqueueSnackbar(null, {
      content: <Alert severity="info">Fetching VAA</Alert>,
    });

    emit(solPK.toBase58(), txid);

    const { vaaBytes } = await getSignedVAAWithRetry(
      WORMHOLE_RPC_HOSTS,
      CHAIN_ID_SOLANA,
      emitterAddress,
      sequence
    );
    dispatch(setSignedVAAHex(uint8ArrayToHex(vaaBytes)));
    enqueueSnackbar(null, {
      content: <Alert severity="success">Fetched Signed VAA</Alert>,
    });
  } catch (e) {
    console.error(e);
    enqueueSnackbar(null, {
      content: <Alert severity="error">{parseError(e)}</Alert>,
    });
    dispatch(setIsSending(false));
    onError(e);
    emit(solPK.toBase58());
  }
}

export function useHandleAttest() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const sourceChain = useSelector(selectAttestSourceChain);
  const targetChain = useSelector(selectAttestTargetChain);
  const sourceAsset = useSelector(selectAttestSourceAsset);
  const isTargetComplete = useSelector(selectAttestIsTargetComplete);
  const isSending = useSelector(selectAttestIsSending);
  const isSendComplete = useSelector(selectAttestIsSendComplete);
  const { signer } = useEthereumProvider(sourceChain as any);
  const { publicKey: solPK, wallet: solanaWallet } = useSolanaWallet();
  // const { walletAddress: terraAddress, wallet: terraWallet } = useTerraWallet(
  //   sourceChain as any
  // );
  // const terraFeeDenom = useSelector(selectTerraFeeDenom);
  // const xplaWallet = useXplaWallet();
  // const { address: algoAccount, wallet: algoWallet } = useAlgorandWallet();
  // const { account: aptosAddress, wallet: aptosWallet } = useAptosContext();
  // const { accountId: nearAccountId, wallet } = useNearContext();
  // const { wallet: injWallet, address: injAddress } = useInjectiveContext();
  // const seiWallet = useSeiWallet();
  // const seiAddress = seiWallet?.getAddress();
  // const suiWallet = useSuiWallet();
  const disabled = !isTargetComplete || isSending || isSendComplete;
  const handleAttestClick = useCallback(() => {
    // const telemetryProps: TelemetryTxEvent = {
    //   fromChainId: sourceChain,
    //   toChainId: targetChain,
    //   fromTokenSymbol: undefined,
    //   toTokenSymbol: undefined,
    //   fromTokenAddress: sourceAsset,
    //   toTokenAddress: undefined,
    //   amount: undefined,
    // };
    // telemetry.on.transferInit(telemetryProps);

    const emit = (wallet: string, txId?: string) => {
      mixpanel.track(`Attest Txn ${txId ? "" : "Rejected"}`, {
        Source: CHAINS_RECORD[sourceChain].name,
        Target: CHAINS_RECORD[targetChain].name,
        "Source Asset": sourceAsset,
        Wallet: wallet,
        "Txn Hash": txId,
      });
    };

    const onError = (error: any) => {
      // telemetry.on.error({ ...telemetryProps, error });
    };

    const onStart = (extra?: Partial<any>) => {
      // telemetry.on.transferStart({ ...telemetryProps, ...extra });
    };

    if (isEVMChain(sourceChain) && !!signer) {
      evm(
        dispatch,
        enqueueSnackbar,
        signer,
        sourceAsset,
        sourceChain,
        onError,
        onStart,
        emit
      );
    } else if (sourceChain === CHAIN_ID_SOLANA && !!solanaWallet && !!solPK) {
      solana(
        dispatch,
        enqueueSnackbar,
        new PublicKey(solPK),
        sourceAsset,
        solanaWallet,
        onError,
        onStart,
        emit
      );
    }
    // else if (isTerraChain(sourceChain) && !!terraAddress && terraWallet) {
    //   terra(
    //     dispatch,
    //     enqueueSnackbar,
    //     terraWallet,
    //     sourceAsset,
    //     terraFeeDenom,
    //     sourceChain,
    //     onError,
    //     onStart
    //   );
    // } else if (sourceChain === CHAIN_ID_XPLA && !!xplaWallet) {
    //   xpla(
    //     dispatch,
    //     enqueueSnackbar,
    //     xplaWallet,
    //     sourceAsset,
    //     onError,
    //     onStart
    //   );
    // } else if (sourceChain === CHAIN_ID_ALGORAND && algoAccount) {
    //   algo(
    //     dispatch,
    //     enqueueSnackbar,
    //     algoWallet,
    //     sourceAsset,
    //     onError,
    //     onStart
    //   );
    // } else if (sourceChain === CHAIN_ID_APTOS && aptosAddress) {
    //   aptos(
    //     dispatch,
    //     enqueueSnackbar,
    //     sourceAsset,
    //     aptosWallet!,
    //     onError,
    //     onStart
    //   );
    // } else if (sourceChain === CHAIN_ID_NEAR && nearAccountId && wallet) {
    //   near(
    //     dispatch,
    //     enqueueSnackbar,
    //     nearAccountId,
    //     sourceAsset,
    //     wallet,
    //     onError,
    //     onStart
    //   );
    // } else if (sourceChain === CHAIN_ID_INJECTIVE && injWallet && injAddress) {
    //   injective(
    //     dispatch,
    //     enqueueSnackbar,
    //     injWallet,
    //     injAddress,
    //     sourceAsset,
    //     onError,
    //     onStart
    //   );
    // } else if (sourceChain === CHAIN_ID_SEI && seiWallet && seiAddress) {
    //   sei(dispatch, enqueueSnackbar, seiWallet, sourceAsset, onError, onStart);
    // } else if (
    //   sourceChain === CHAIN_ID_SUI &&
    //   suiWallet?.isConnected() &&
    //   suiWallet.getAddress()
    // ) {
    //   sui(dispatch, enqueueSnackbar, suiWallet, sourceAsset, onError, onStart);
    // }
  }, [
    sourceChain,
    targetChain,
    sourceAsset,
    signer,
    solanaWallet,
    solPK,
    // terraAddress,
    // terraWallet,
    // xplaWallet,
    // algoAccount,
    // aptosAddress,
    // nearAccountId,
    // wallet,
    // injWallet,
    // injAddress,
    // seiWallet,
    // seiAddress,
    // suiWallet,
    dispatch,
    enqueueSnackbar,
    // terraFeeDenom,
    // algoWallet,
    // aptosWallet,
  ]);
  return useMemo(
    () => ({
      handleClick: handleAttestClick,
      disabled,
      showLoader: isSending,
    }),
    [handleAttestClick, disabled, isSending]
  );
}
