import { CHAIN_ID_SOLANA } from "@certusone/wormhole-sdk";
import { Alert } from "@material-ui/lab";
import { Link, makeStyles, Typography, Box } from "@material-ui/core";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useHandleAttest } from "../../hooks/useHandleAttest";
import useIsWalletReady from "../../hooks/useIsWalletReady";
import useMetaplexData from "../../hooks/useMetaplexData";
import {
  selectAttestAttestTx,
  selectAttestIsSendComplete,
  selectAttestSourceAsset,
  selectAttestSourceChain,
  selectAttestTargetChain,
} from "../../store/selectors";
import ButtonWithLoader from "../ButtonWithLoader";
import KeyAndBalance from "../KeyAndBalance";
import TransactionProgress from "../TransactionProgress";
import WaitingForWalletMessage from "./WaitingForWalletMessage";
import { SOLANA_TOKEN_METADATA_PROGRAM_URL } from "../../utils/consts";
import { CHAINS_RECORD } from "../../utils/consts";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: "10px",
    borderRadius: "12px",
  },
}));

const SolanaTokenMetadataWarning = () => {
  const sourceAsset = useSelector(selectAttestSourceAsset);
  const sourceAssetArrayed = useMemo(() => {
    return [sourceAsset];
  }, [sourceAsset]);
  const metaplexData = useMetaplexData(sourceAssetArrayed);
  const classes = useStyles();

  if (metaplexData.isFetching || metaplexData.error) {
    return null;
  }

  return !metaplexData.data?.get(sourceAsset) ? (
    <Alert severity="warning" variant="outlined" className={classes.alert}>
      This token is missing on-chain (Metaplex) metadata. Without it, the
      wrapped token's name and symbol will be empty. See the{" "}
      <Link
        href={SOLANA_TOKEN_METADATA_PROGRAM_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        metaplex repository
      </Link>{" "}
      for details.
    </Alert>
  ) : null;
};

function Send() {
  const classes = useStyles();
  const { handleClick, disabled, showLoader } = useHandleAttest();
  const sourceChain = useSelector(selectAttestSourceChain);
  const targetChain = useSelector(selectAttestTargetChain);
  const attestTx = useSelector(selectAttestAttestTx);
  const isSendComplete = useSelector(selectAttestIsSendComplete);
  const { isReady, statusMessage } = useIsWalletReady(sourceChain);

  return (
    <>
      <Alert severity="info" variant="outlined" className={classes.alert}>
        <Typography component="div"></Typography>
        Attesting on the{" "}
        <Box fontWeight={900} display="inline">
          {CHAINS_RECORD[sourceChain].name}
        </Box>{" "}
        will create requisite addresses and metadata that will allow the wrapped
        asset to exist on{" "}
        <Box fontWeight={900} display="inline">
          {CHAINS_RECORD[targetChain].name}
        </Box>
        .
      </Alert>

      <Alert severity="warning" variant="outlined" className={classes.alert}>
        Please do not forget to proceed to the next step once you have attested.
        After attesting, you will need to send another transaction for wrapped
        token creation.
      </Alert>

      <KeyAndBalance chainId={sourceChain} />

      <ButtonWithLoader
        disabled={!isReady || disabled}
        onClick={handleClick}
        showLoader={showLoader}
        error={statusMessage}
      >
        Initiate wrap
      </ButtonWithLoader>

      {sourceChain === CHAIN_ID_SOLANA && <SolanaTokenMetadataWarning />}

      <WaitingForWalletMessage />

      <TransactionProgress
        chainId={sourceChain}
        tx={attestTx}
        isSendComplete={isSendComplete}
      />
    </>
  );
}

export default Send;
