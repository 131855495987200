import { CHAIN_ID_SOLANA, hexToNativeString } from "@certusone/wormhole-sdk";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWallet } from "@xlabs-libs/wallet-aggregator-react";

import useGetTargetParsedTokenAccounts from "../../hooks/useGetTargetParsedTokenAccounts";
import useIsWalletReady from "../../hooks/useIsWalletReady";
import useSyncTargetAddress from "../../hooks/useSyncTargetAddress";
import {
  selectTransferAmount,
  selectTransferIsTargetComplete,
  selectTransferShouldLockFields,
  selectTransferSourceChain,
  selectTransferTargetAddressHex,
  selectTransferTargetAsset,
  selectTransferTargetAssetWrapper,
  selectTransferTargetBalanceString,
  selectTransferTargetChain,
  selectTransferTargetError,
  selectTransferTargetParsedTokenAccount,
} from "../../store/selectors";
import { incrementStep, setTargetChain } from "../../store/transferSlice";
import { CHAINS, CLUSTER } from "../../utils/consts";
import ButtonWithLoader from "../ButtonWithLoader";
import ChainSelect from "../ChainSelect";
import KeyAndBalance from "../KeyAndBalance";
import LowBalanceWarning from "../LowBalanceWarning";
import SmartAddress from "../SmartAddress";
import SolanaCreateAssociatedAddress, {
  useAssociatedAccountExistsState,
} from "../SolanaCreateAssociatedAddress";
import SolanaTPSWarning from "../SolanaTPSWarning";
import RegisterNowButton from "./RegisterNowButton";
import AddressTable from "../AddressTable";

export const useTargetInfo = () => {
  // const { accountId: nearAccountId } = useNearContext();

  const targetChain = useSelector(selectTransferTargetChain);
  const targetAddressHex = useSelector(selectTransferTargetAddressHex);
  const targetAsset = useSelector(selectTransferTargetAsset);
  const targetParsedTokenAccount = useSelector(
    selectTransferTargetParsedTokenAccount
  );
  const tokenName = targetParsedTokenAccount?.name;
  const symbol = targetParsedTokenAccount?.symbol;
  const logo = targetParsedTokenAccount?.logo;
  // const readableTargetAddress =
  //   targetChain === CHAIN_ID_NEAR
  //     ? // Near uses a hashed address, which isn't very readable - check that the hash matches and show them their account id
  //       nearAccountId &&
  //       // this just happens to be the same hashing mechanism as emitters
  //       getEmitterAddressNear(nearAccountId) === targetAddressHex
  //       ? nearAccountId
  //       : targetAddressHex || ""
  //     : targetChain === CHAIN_ID_APTOS || targetChain === CHAIN_ID_SUI
  //     ? targetAddressHex
  //       ? `0x${targetAddressHex}`
  //       : ""
  //     : hexToNativeString(targetAddressHex, targetChain) || "";
  const readableTargetAddress =
    hexToNativeString(targetAddressHex, targetChain) || "";
  return useMemo(
    () => ({
      targetChain,
      targetAsset,
      tokenName,
      symbol,
      logo,
      readableTargetAddress,
    }),
    [targetChain, targetAsset, tokenName, symbol, logo, readableTargetAddress]
  );
};

function Target() {
  useGetTargetParsedTokenAccounts();

  const dispatch = useDispatch();
  const sourceChain = useSelector(selectTransferSourceChain);
  const chains = useMemo(
    () => CHAINS.filter((c) => c.id !== sourceChain),
    [sourceChain]
  );
  const { error: targetAssetError, data } = useSelector(
    selectTransferTargetAssetWrapper
  );
  const {
    targetChain,
    targetAsset,
    tokenName,
    symbol,
    logo,
    readableTargetAddress,
  } = useTargetInfo();
  const uiAmountString = useSelector(selectTransferTargetBalanceString);
  const transferAmount = useSelector(selectTransferAmount);
  const error = useSelector(selectTransferTargetError);
  const isTargetComplete = useSelector(selectTransferIsTargetComplete);
  const shouldLockFields = useSelector(selectTransferShouldLockFields);
  const { statusMessage, isReady } = useIsWalletReady(targetChain);
  const wallet = useWallet(targetChain);
  const isLoading = !statusMessage && !targetAssetError && !data;
  const { associatedAccountExists, setAssociatedAccountExists } =
    useAssociatedAccountExistsState(
      targetChain,
      targetAsset,
      readableTargetAddress
    );

  useSyncTargetAddress(!shouldLockFields);

  const handleTargetChange = useCallback(
    (event) => {
      dispatch(setTargetChain(event.target.value));
    },
    [dispatch]
  );

  const handleNextClick = useCallback(() => {
    dispatch(incrementStep());
  }, [dispatch]);

  return (
    <>
      <ChainSelect
        label="Target Chain"
        value={targetChain}
        onChange={handleTargetChange}
        disabled={true}
        chains={chains}
      />

      <KeyAndBalance chainId={targetChain} />

      {readableTargetAddress ? (
        <AddressTable
          rows={[
            {
              title: "Sent to",
              address: (
                <SmartAddress
                  chainId={targetChain}
                  address={readableTargetAddress}
                  variant="body1"
                />
              ),
              info: `Current balance: ${uiAmountString || "0"}`,
            },
            targetAsset
              ? {
                  title: "Bridged tokens",
                  address: (
                    <SmartAddress
                      chainId={targetChain}
                      address={targetAsset}
                      symbol={symbol}
                      tokenName={tokenName}
                      logo={logo}
                      variant="body1"
                      isAsset
                    />
                  ),
                  info: `Amount: ${transferAmount}`,
                }
              : null,
          ]}
        />
      ) : null}

      {targetChain === CHAIN_ID_SOLANA && targetAsset ? (
        <SolanaCreateAssociatedAddress
          mintAddress={targetAsset}
          readableTargetAddress={readableTargetAddress}
          associatedAccountExists={associatedAccountExists}
          setAssociatedAccountExists={setAssociatedAccountExists}
        />
      ) : null}

      <LowBalanceWarning chainId={targetChain} />

      {targetChain === CHAIN_ID_SOLANA && CLUSTER === "mainnet" && (
        <SolanaTPSWarning />
      )}

      {wallet && (
        <ButtonWithLoader
          disabled={!isTargetComplete || !associatedAccountExists}
          onClick={handleNextClick}
          showLoader={isLoading}
          error={
            statusMessage || (isLoading ? undefined : error || targetAssetError)
          }
        >
          Next
        </ButtonWithLoader>
      )}

      {!statusMessage && data && !data.doesExist ? <RegisterNowButton /> : null}
    </>
  );
}

export default Target;
