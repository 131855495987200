import React from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    title: {
      margin: 0,
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const MDialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.title} variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

interface CustomDialogProps {
  title: string;
  open: boolean;
  handleClose: () => void;
  content?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  dc: {
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // overflowY: "auto",
  },
  dg: {
    "@media (max-width:550px)": {
      width: "95%",
      margin: 0,
      maxWidth: "95%",
    },
  },
}));

export default function CustomDialog({
  title,
  open,
  handleClose,
  content,
}: CustomDialogProps) {
  const classes = useStyles();

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      classes={{ paper: classes.dg }}
    >
      <MDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </MDialogTitle>
      {content && (
        <DialogContent className={classes.dc}>{content}</DialogContent>
      )}
    </Dialog>
  );
}
