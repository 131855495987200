import {
  ChainId,
  CHAIN_ID_SOLANA,
  createWrappedOnEth,
  createWrappedOnSolana,
  isEVMChain,
  updateWrappedOnEth,
  updateWrappedOnSolana,
} from "@certusone/wormhole-sdk";
import mixpanel from "mixpanel-browser";
import { Alert } from "@material-ui/lab";
import { Connection } from "@solana/web3.js";
import { Signer } from "ethers";
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEthereumProvider } from "../contexts/EthereumProviderContext";
import { useSolanaWallet } from "../contexts/SolanaWalletContext";
import { setCreateTx, setIsCreating } from "../store/attestSlice";
import {
  selectAttestIsCreating,
  selectAttestSourceAsset,
  selectAttestSourceChain,
  selectAttestTargetChain,
} from "../store/selectors";
import {
  getTokenBridgeAddressForChain,
  SOLANA_HOST,
  SOL_BRIDGE_ADDRESS,
  SOL_TOKEN_BRIDGE_ADDRESS,
  MAX_VAA_UPLOAD_RETRIES_SOLANA,
} from "../utils/consts";
import parseError from "../utils/parseError";
import { postVaa, signSendAndConfirm } from "../utils/solana";
import useAttestSignedVAA from "./useAttestSignedVAA";
import { SolanaWallet } from "@xlabs-libs/wallet-aggregator-solana";
import { createToken } from "../utils/indexing";
import { CHAINS_RECORD } from "../utils/consts";

// TODO: replace with SDK method -
// export async function updateWrappedOnSui(
//   provider: JsonRpcProvider,
//   coreBridgeStateObjectId: string,
//   tokenBridgeStateObjectId: string,
//   coinPackageId: string,
//   attestVAA: Uint8Array
// ): Promise<TransactionBlock> {
//   const coreBridgePackageId = await getPackageId(
//     provider,
//     coreBridgeStateObjectId
//   );
//   const tokenBridgePackageId = await getPackageId(
//     provider,
//     tokenBridgeStateObjectId
//   );

//   // Get coin metadata
//   const coinType = getWrappedCoinType(coinPackageId);
//   const coinMetadataObjectId = (await provider.getCoinMetadata({ coinType }))
//     ?.id;
//   if (!coinMetadataObjectId) {
//     throw new Error(
//       `Coin metadata object not found for coin type ${coinType}.`
//     );
//   }

//   // Get TokenBridgeMessage
//   const tx = new TransactionBlock();
//   const [vaa] = tx.moveCall({
//     target: `${coreBridgePackageId}::vaa::parse_and_verify`,
//     arguments: [
//       tx.object(coreBridgeStateObjectId),
//       tx.pure([...attestVAA]),
//       tx.object(SUI_CLOCK_OBJECT_ID),
//     ],
//   });
//   const [message] = tx.moveCall({
//     target: `${tokenBridgePackageId}::vaa::verify_only_once`,
//     arguments: [tx.object(tokenBridgeStateObjectId), vaa],
//   });

//   // Construct complete registration payload
//   tx.moveCall({
//     target: `${tokenBridgePackageId}::create_wrapped::update_attestation`,
//     arguments: [
//       tx.object(tokenBridgeStateObjectId),
//       tx.object(coinMetadataObjectId),
//       message,
//     ],
//     typeArguments: [coinType],
//   });
//   return tx;
// }

async function evm(
  dispatch: any,
  enqueueSnackbar: any,
  signer: Signer,
  signedVAA: Uint8Array,
  chainId: ChainId,
  shouldUpdate: boolean,
  onError: (error: any) => void,
  onStart: (extra?: Partial<any>) => void,
  emit: (wallet: string, txId?: string) => void
) {
  dispatch(setIsCreating(true));
  try {
    // Karura and Acala need gas params for contract deploys
    // Klaytn requires specifying gasPrice
    // const overrides =
    //   chainId === CHAIN_ID_KARURA
    //     ? await getKaruraGasParams(KARURA_HOST)
    //     : chainId === CHAIN_ID_ACALA
    //     ? await getKaruraGasParams(ACALA_HOST)
    //     : chainId === CHAIN_ID_KLAYTN
    //     ? { gasPrice: (await signer.getGasPrice()).toString() }
    //     : {};
    const overrides = {};
    const receipt = shouldUpdate
      ? await updateWrappedOnEth(
          getTokenBridgeAddressForChain(chainId),
          signer,
          signedVAA,
          overrides
        )
      : await createWrappedOnEth(
          getTokenBridgeAddressForChain(chainId),
          signer,
          signedVAA,
          overrides
        );
    createToken(chainId, receipt.transactionHash);
    onStart({ txId: receipt.transactionHash });
    dispatch(
      setCreateTx({ id: receipt.transactionHash, block: receipt.blockNumber })
    );
    enqueueSnackbar(null, {
      content: <Alert severity="success">Transaction confirmed</Alert>,
    });
    const wAddress = await signer.getAddress();
    emit(wAddress, receipt.transactionHash);
  } catch (e) {
    enqueueSnackbar(null, {
      content: <Alert severity="error">{parseError(e)}</Alert>,
    });
    dispatch(setIsCreating(false));
    onError(e);
    const wAddress = await signer.getAddress();
    emit(wAddress);
  }
}

async function solana(
  dispatch: any,
  enqueueSnackbar: any,
  wallet: SolanaWallet,
  payerAddress: string, // TODO: we may not need this since we have wallet
  signedVAA: Uint8Array,
  shouldUpdate: boolean,
  onError: (error: any) => void,
  onStart: (extra?: Partial<any>) => void,
  emit: (wallet: string, txId?: string) => void
) {
  dispatch(setIsCreating(true));
  try {
    if (!wallet.signTransaction) {
      throw new Error("wallet.signTransaction is undefined");
    }
    const connection = new Connection(SOLANA_HOST, "confirmed");
    await postVaa(
      connection,
      wallet.signTransaction.bind(wallet),
      SOL_BRIDGE_ADDRESS,
      payerAddress,
      Buffer.from(signedVAA),
      { maxRetries: MAX_VAA_UPLOAD_RETRIES_SOLANA }
    );
    const transaction = shouldUpdate
      ? await updateWrappedOnSolana(
          connection,
          SOL_BRIDGE_ADDRESS,
          SOL_TOKEN_BRIDGE_ADDRESS,
          payerAddress,
          signedVAA
        )
      : await createWrappedOnSolana(
          connection,
          SOL_BRIDGE_ADDRESS,
          SOL_TOKEN_BRIDGE_ADDRESS,
          payerAddress,
          signedVAA
        );
    const txid = await signSendAndConfirm(wallet, transaction);
    createToken(CHAIN_ID_SOLANA, txid);
    onStart({ txId: txid });
    // TODO: didn't want to make an info call we didn't need, can we get the block without it by modifying the above call?
    dispatch(setCreateTx({ id: txid, block: 1 }));
    enqueueSnackbar(null, {
      content: <Alert severity="success">Transaction confirmed</Alert>,
    });

    emit(payerAddress, txid);
  } catch (e) {
    enqueueSnackbar(null, {
      content: <Alert severity="error">{parseError(e)}</Alert>,
    });
    dispatch(setIsCreating(false));
    onError(e);
    emit(payerAddress);
  }
}

export function useHandleCreateWrapped(
  shouldUpdate: boolean,
  foreignAddress: string | null | undefined
) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const targetChain = useSelector(selectAttestTargetChain);
  const sourceChain = useSelector(selectAttestSourceChain);
  const sourceAsset = useSelector(selectAttestSourceAsset);
  const { publicKey: solPK, wallet: solanaWallet } = useSolanaWallet();
  const signedVAA = useAttestSignedVAA();
  const isCreating = useSelector(selectAttestIsCreating);
  const { signer } = useEthereumProvider(targetChain as any);
  // const terraWallet = useTerraWallet(targetChain as any);
  // const terraFeeDenom = useSelector(selectTerraFeeDenom);
  // const xplaWallet = useXplaWallet();
  // const { address: algoAccount, wallet: algoWallet } = useAlgorandWallet();
  // const { account: aptosAddress, wallet: aptosWallet } = useAptosContext();
  // const { wallet: injWallet, address: injAddress } = useInjectiveContext();
  // const { accountId: nearAccountId, wallet } = useNearContext();
  // const suiWallet = useSuiWallet();
  // const seiWallet = useSeiWallet();
  // const seiAddress = seiWallet?.getAddress();
  const handleCreateClick = useCallback(() => {
    // const telemetryProps: TelemetryTxEvent = {
    //   fromChainId: sourceChain,
    //   toChainId: targetChain,
    //   fromTokenSymbol: sourceAsset,
    //   toTokenSymbol: undefined,
    //   fromTokenAddress: undefined,
    //   toTokenAddress: undefined,
    //   amount: undefined,
    // };
    // telemetry.on.transferInit(telemetryProps);

    const emit = (wallet: string, txId?: string) => {
      mixpanel.track(`Create Wrap Txn ${txId ? "" : "Rejected"}`, {
        Source: CHAINS_RECORD[sourceChain].name,
        Target: CHAINS_RECORD[targetChain].name,
        "Source Asset": sourceAsset,
        Wallet: wallet,
        "Txn Hash": txId,
      });
    };

    const onError = (error: any) => {
      // telemetry.on.error({ ...telemetryProps, error });
    };

    // const onStart = (extra?: Partial<TelemetryTxEvent>) => {
    //   telemetry.on.transferStart({ ...telemetryProps, ...extra });
    // };

    const onStart = (extra?: Partial<any>) => {
      // telemetry.on.transferStart({ ...telemetryProps, ...extra });
    };

    if (isEVMChain(targetChain) && !!signer && !!signedVAA) {
      evm(
        dispatch,
        enqueueSnackbar,
        signer,
        signedVAA,
        targetChain,
        shouldUpdate,
        onError,
        onStart,
        emit
      );
    } else if (
      targetChain === CHAIN_ID_SOLANA &&
      !!solanaWallet &&
      !!solPK &&
      !!signedVAA
    ) {
      solana(
        dispatch,
        enqueueSnackbar,
        solanaWallet,
        solPK,
        signedVAA,
        shouldUpdate,
        onError,
        onStart,
        emit
      );
    }

    // else if (
    //   isTerraChain(targetChain) &&
    //   !!terraWallet.walletAddress &&
    //   !!terraWallet.wallet &&
    //   !!signedVAA
    // ) {
    //   terra(
    //     dispatch,
    //     enqueueSnackbar,
    //     terraWallet.wallet,
    //     signedVAA,
    //     shouldUpdate,
    //     terraFeeDenom,
    //     targetChain,
    //     onError,
    //     onStart
    //   );
    // } else if (targetChain === CHAIN_ID_XPLA && !!xplaWallet && !!signedVAA) {
    //   xpla(
    //     dispatch,
    //     enqueueSnackbar,
    //     xplaWallet,
    //     signedVAA,
    //     shouldUpdate,
    //     onError,
    //     onStart
    //   );
    // } else if (
    //   targetChain === CHAIN_ID_SEI &&
    //   seiWallet &&
    //   seiAddress &&
    //   !!signedVAA
    // ) {
    //   sei(
    //     dispatch,
    //     enqueueSnackbar,
    //     seiWallet,
    //     signedVAA,
    //     shouldUpdate,
    //     onError,
    //     onStart
    //   );
    // } else if (
    //   targetChain === CHAIN_ID_APTOS &&
    //   !!aptosAddress &&
    //   !!signedVAA
    // ) {
    //   aptos(
    //     dispatch,
    //     enqueueSnackbar,
    //     aptosAddress,
    //     signedVAA,
    //     shouldUpdate,
    //     aptosWallet!,
    //     onError,
    //     onStart
    //   );
    // } else if (
    //   targetChain === CHAIN_ID_ALGORAND &&
    //   algoAccount &&
    //   !!signedVAA
    // ) {
    //   algo(dispatch, enqueueSnackbar, algoWallet, signedVAA, onError, onStart);
    // } else if (
    //   targetChain === CHAIN_ID_NEAR &&
    //   nearAccountId &&
    //   wallet &&
    //   !!signedVAA
    // ) {
    //   near(
    //     dispatch,
    //     enqueueSnackbar,
    //     nearAccountId,
    //     signedVAA,
    //     wallet,
    //     onError,
    //     onStart
    //   );
    // } else if (
    //   targetChain === CHAIN_ID_INJECTIVE &&
    //   injWallet &&
    //   injAddress &&
    //   !!signedVAA
    // ) {
    //   injective(
    //     dispatch,
    //     enqueueSnackbar,
    //     injWallet,
    //     injAddress,
    //     signedVAA,
    //     shouldUpdate,
    //     onError,
    //     onStart
    //   );
    // } else if (
    //   targetChain === CHAIN_ID_SUI &&
    //   suiWallet &&
    //   suiWallet.isConnected() &&
    //   suiWallet.getAddress() &&
    //   !!signedVAA
    // ) {
    //   sui(
    //     dispatch,
    //     enqueueSnackbar,
    //     suiWallet,
    //     signedVAA,
    //     foreignAddress,
    //     onError,
    //     onStart
    //   );
    // } else if (isGatewayCosmosChain(targetChain as any) && !!signedVAA) {
    //   cosmos(
    //     dispatch,
    //     enqueueSnackbar,
    //     signedVAA,
    //     foreignAddress,
    //     sourceChain,
    //     sourceAsset,
    //     onError,
    //     onStart
    //   );
    // } else {
    //   // enqueueSnackbar(
    //   //   "Creating wrapped tokens on this chain is not yet supported",
    //   //   {
    //   //     variant: "error",
    //   //   }
    //   // );
    // }
  }, [
    dispatch,
    enqueueSnackbar,
    targetChain,
    solanaWallet,
    solPK,
    // terraWallet,
    signedVAA,
    signer,
    shouldUpdate,
    // terraFeeDenom,
    // algoAccount,
    // algoWallet,
    // nearAccountId,
    // wallet,
    // xplaWallet,
    // aptosAddress,
    // aptosWallet,
    // injWallet,
    // injAddress,
    foreignAddress,
    // suiWallet,
    // seiWallet,
    // seiAddress,
    sourceAsset,
    sourceChain,
  ]);
  return useMemo(
    () => ({
      handleClick: handleCreateClick,
      disabled: !!isCreating,
      showLoader: !!isCreating,
    }),
    [handleCreateClick, isCreating]
  );
}
