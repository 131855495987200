import { makeStyles } from "@material-ui/core/styles";
import { List, Typography, ListItem, Box } from "@material-ui/core";
import clsx from "clsx";

import CustomIcon from "./CustomIcon";

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
  },
  itemContainer: {
    display: "flex",
    alignItems: "center",
    minWidth: "400px",
    "@media (max-width:550px)": {
      width: "100%",
      minWidth: "auto",
    },
  },
  icon: {
    width: 35,
    height: 35,
  },
  itemSubContainer: {
    width: "100%",
    marginLeft: "10px",
  },
  itemTop: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  itemBottom: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& p": {
      fontWeight: 400,
      fontSize: 14,
      color: "rgb(148, 163, 184)",
    },
  },
}));

export type DialogListItemType<T> = {
  icon?: string;
  title?: string;
  value?: string;
  subTitle?: string;
  subValue?: string;
  item?: T;
  onSelect?: () => void;
};

export default function DialogList<T>({
  items,
  className,
}: {
  items: Array<DialogListItemType<T>>;
  className?: string;
}) {
  const classes = useStyles();

  return (
    <List className={clsx([classes.list, className ? className : ""])}>
      {items.map((item, index) => (
        <ListItem
          key={index}
          button
          onClick={() => item.onSelect && item.onSelect()}
        >
          <Box className={classes.itemContainer}>
            <CustomIcon
              src={item.icon}
              className={classes.icon}
              alt={item.title}
            />
            <Box className={classes.itemSubContainer}>
              <Box className={classes.itemTop}>
                <Typography variant="h6">{item.title}</Typography>
                {item.value && (
                  <Typography variant="h6">{item.value}</Typography>
                )}
              </Box>
              <Box className={classes.itemBottom}>
                {item.subTitle && (
                  <Typography variant="body1">{item.subTitle}</Typography>
                )}
                {item.subValue && (
                  <Typography variant="body1">{item.subValue}</Typography>
                )}
              </Box>
            </Box>
          </Box>
        </ListItem>
      ))}
    </List>
  );
}
