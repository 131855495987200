import {
  AppBar,
  Hidden,
  IconButton,
  Link,
  makeStyles,
  Toolbar,
  List,
  ListItem,
  Popover,
  Box,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import clsx from "clsx";
import { useState, useRef } from "react";
import {
  Switch,
  Route,
  Redirect,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";

import Transfer from "./components/Transfer";
import Recovery from "./components/Recovery";
import Attest from "./components/Attest";
import Tokens from "./components/Tokens";
import WebLogo from "./icons/tokenwraplogo.png";
import MobileLogo from "./icons/logo_mob.png";
import { COLORS } from "./muiTheme";
import Disclaimer from "./components/Disclaimer";

type RouteItem = {
  name: string;
  pathname: string;
  renderCompoenet: React.ReactNode;
};
const routes: Array<RouteItem> = [
  {
    name: "Bridge",
    pathname: "/bridge",
    renderCompoenet: <Transfer />,
  },
  {
    name: "Redeem",
    pathname: "/redeem",
    renderCompoenet: <Recovery />,
  },
  {
    name: "Register",
    pathname: "/register",
    renderCompoenet: <Attest />,
  },
  // {
  //   name: "Tokens",
  //   pathname: "/tokens",
  //   renderCompoenet: <Tokens />,
  // },
];

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: 50,
    background: COLORS.darkBackground,
    padding: "0 20px",
    "& > .MuiToolbar-root": {
      margin: "auto",
      width: "100%",
      maxWidth: 1440,
    },
  },
  bg: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    position: "relative",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
    width: "100vw",
  },
  link: {
    fontWeight: 400,
    fontSize: "16px",
    color: theme.palette.primary.light,
    textDecoration: "none",
    "&:hover": {
      // color: theme.palette.primary.light,
      color: "inhert",
      textDecoration: "none",
    },
  },
  brandLink: {
    display: "inline-flex",
    alignItems: "center",
    "&:hover": {
      textDecoration: "none",
    },
  },
  wrapifyIcon: {
    height: 25,
    "&:hover": {
      filter: "contrast(1)",
    },
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
    display: "inline-block",
  },
  activeLink: {
    color: "#a881fc",
    fontWeight: 600,
  },
  popover: {
    width: "100%",
    background: COLORS.darkBackground,
    borderRadius: "12px",
    border: "1px solid",
    borderColor: "#383241",
    marginTop: "3px",
    left: "0px",
  },
  listItem: {
    padding: "14px",
  },
  icon: {
    "& svg": {
      color: theme.palette.primary.light,
    },
  },
  weblink: {
    margin: "0 15px",
    fontSize: "16px",
  },
}));

function App() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const appBarRef = useRef();
  const [anchorEl, setAnchorEl] = useState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.bg}>

      <AppBar
        ref={appBarRef}
        position="static"
        color="inherit"
        className={classes.appBar}
        elevation={0}
      >
        <Toolbar disableGutters>
          <Link
            component={RouterLink}
            rel="noopener noreferrer"
            className={classes.brandLink}
            to="transfer"
          >
            <img
              src={isMobile ? MobileLogo : WebLogo}
              alt="Tokenwrap"
              className={classes.wrapifyIcon}
            />
          </Link>

          <div className={classes.spacer} />
          <Hidden implementation="css" smDown>
            <div style={{ display: "flex", alignItems: "center" }}>
              {routes.map((route, index) => (
                <Link
                  key={index}
                  component={RouterLink}
                  rel="noopener noreferrer"
                  color="inherit"
                  className={clsx([
                    classes.link,
                    classes.weblink,
                    route.pathname === pathname ? classes.activeLink : "",
                  ])}
                  to={route.pathname}
                >
                  {route.name}
                </Link>
              ))}
            </div>
          </Hidden>

          <Hidden implementation="css" mdUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={() => setAnchorEl(appBarRef?.current)}
              className={classes.icon}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>

      </AppBar>

      <Popover
        id={open ? "simple-popover" : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{ className: classes.popover }}
      >
        <Box>
          <List style={{ padding: "0" }}>
            {routes.map((route, index) => (
              <Link
                component={RouterLink}
                rel="noopener noreferrer"
                color="inherit"
                key={index}
                className={clsx([
                  classes.link,
                  route.pathname === pathname ? classes.activeLink : "",
                ])}
                to={route.pathname}
                onClick={() => setAnchorEl(undefined)}
              >
                <ListItem className={classes.listItem} button key={index}>
                  {route.name}
                </ListItem>
              </Link>
            ))}
          </List>
        </Box>
      </Popover>

      <Switch>
        {routes.map((route, index) => (
          <Route key={index} exact path={route.pathname}>
            {route.renderCompoenet}
          </Route>
        ))}
        <Route>
          <Redirect to="/bridge" />
        </Route>
      </Switch>

      <Disclaimer></Disclaimer>
    </div>
  );
}

export default App;
