import {
  Box,
  TextField,
  makeStyles,
  TextFieldProps,
  IconButton,
} from "@material-ui/core";

import DisableWrapper from "./DisableWrapper";
import { balancePretty } from "../utils/balancePretty";

type AmountFieldProps = {
  disabled?: boolean;
  selector: React.ReactNode;
  error: boolean;
  balance?: string;
  onMaxClick?: () => void;
} & TextFieldProps;

const useStyles = makeStyles((theme) => ({
  disableCont: {
    position: "relative",
  },
  root: {
    backgroundColor: theme.palette.secondary.main,
    border: "1px solid",
    borderColor: theme.palette.divider,
    borderRadius: "12px",
    padding: "5px 20px",
  },
  fieldContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fkexShrink: 0,
  },
  bottomContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  field: {
    margin: "0",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  inputField: {
    fontSize: "35px",
    // fontWeight: 500,
  },
  select: {
    display: "flex",
  },
  disableSelect: {
    position: "absolute",
    right: "5%",
    top: "30%",
  },
  label: {
    fontSize: "10px",
    margin: "4px 0",
    color: theme.palette.secondary.light,
  },
  max: {
    cursor: "pointer",
    borderRadius: "12px",
    padding: "5px 8px",
    fontSize: "14px",
    fontWeight: 600,
  },
}));

export default function AmountField({
                                      value,
                                      selector,
                                      onChange,
                                      disabled,
                                      error,
                                      balance,
                                      onMaxClick,
                                    }: AmountFieldProps) {
  const classes = useStyles();

  return (
    <Box className={classes.disableCont}>
      <DisableWrapper disable={disabled}>
        <Box className={classes.root}>
          <div className={classes.label}>Amount</div>
          <Box className={classes.fieldContainer}>
            <TextField
              type="number"
              placeholder="0.0"
              value={value}
              onChange={onChange}
              fullWidth
              InputProps={{
                inputProps: {
                  min: 0,
                },
                disableUnderline: true,
                classes: { input: classes.inputField },
              }}
              className={classes.field}
            />
            {!disabled && <Box className={classes.select}>{selector}</Box>}
          </Box>
          <Box className={classes.bottomContainer}>
            <div>Balance: {balancePretty(balance ?? "0")}</div>
            <IconButton
              size="small"
              className={classes.max}
              onClick={onMaxClick}
            >
              <div>Max</div>
            </IconButton>
          </Box>
        </Box>
      </DisableWrapper>
      {disabled && <Box className={classes.disableSelect}>{selector}</Box>}
    </Box>
  );
}
