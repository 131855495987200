import {
  CHAIN_ID_ARBITRUM,
  CHAIN_ID_BSC,
  CHAIN_ID_ETH,
  CHAIN_ID_FANTOM,
  CHAIN_ID_POLYGON,
  CHAIN_ID_SOLANA,
  ChainId,
  isEVMChain,
  WSOL_ADDRESS,
} from "@certusone/wormhole-sdk";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Link,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useGetIsTransferCompleted from "../../hooks/useGetIsTransferCompleted";
import { useHandleRedeem } from "../../hooks/useHandleRedeem";
import useIsWalletReady from "../../hooks/useIsWalletReady";
import {
  selectTransferIsRecovery,
  selectTransferTargetAsset,
  selectTransferTargetChain,
  selectTransferUseRelayer,
} from "../../store/selectors";
import { reset } from "../../store/transferSlice";
import {
  ARBWETH_ADDRESS,
  // CHAINS_BY_ID,
  CLUSTER,
  // getHowToAddTokensToWalletUrl,
  // WAVAX_ADDRESS,
  WBNB_ADDRESS,
  WETH_ADDRESS,
  // WETH_AURORA_ADDRESS,
  WFTM_ADDRESS,
  // WGLMR_ADDRESS,
  // WKLAY_ADDRESS,
  WMATIC_ADDRESS,
  // WNEON_ADDRESS,
  // WROSE_ADDRESS,
} from "../../utils/consts";
import ButtonWithLoader from "../ButtonWithLoader";
import KeyAndBalance from "../KeyAndBalance";
import SmartAddress from "../SmartAddress";
import { SolanaCreateAssociatedAddressAlternate } from "../SolanaCreateAssociatedAddress";
import SolanaTPSWarning from "../SolanaTPSWarning";
import AddToMetamask from "./AddToMetamask";
// import RedeemPreview from "./RedeemPreview";
import WaitingForWalletMessage from "./WaitingForWalletMessage";
import ChainWarningMessage from "../ChainWarningMessage";
import { useRedeemControl } from "../../hooks/useRedeemControl";
import transferRules from "../../config/transferRules";
import { RootState } from "../../store";
import { CHAIN_ID_SEI } from "@xlabs-libs/wallet-aggregator-core";

// const useStyles = makeStyles((theme) => ({
//   alert: {
//     marginTop: theme.spacing(1),
//     marginBottom: theme.spacing(1),
//   },
//   centered: {
//     margin: theme.spacing(4, 0, 2),
//     textAlign: "center",
//   },
//   description: {
//     textAlign: "center",
//     marginBottom: theme.spacing(2),
//   },
// }));

function Redeem() {
  const { handleClick, handleNativeClick, disabled, showLoader } =
    useHandleRedeem();
  const useRelayer = useSelector(selectTransferUseRelayer);

  const targetChain = useSelector(selectTransferTargetChain);
  const targetAsset = useSelector(selectTransferTargetAsset);
  const isRecovery = useSelector(selectTransferIsRecovery);
  // const classes = useStyles();
  const dispatch = useDispatch();
  const { isReady, statusMessage } = useIsWalletReady(targetChain);

  //TODO better check, probably involving a hook & the VAA
  const isEthNative =
    targetChain === CHAIN_ID_ETH &&
    targetAsset &&
    targetAsset.toLowerCase() === WETH_ADDRESS.toLowerCase();
  const isBscNative =
    targetChain === CHAIN_ID_BSC &&
    targetAsset &&
    targetAsset.toLowerCase() === WBNB_ADDRESS.toLowerCase();
  const isPolygonNative =
    targetChain === CHAIN_ID_POLYGON &&
    targetAsset &&
    targetAsset.toLowerCase() === WMATIC_ADDRESS.toLowerCase();
  const isFantomNative =
    targetChain === CHAIN_ID_FANTOM &&
    targetAsset &&
    targetAsset.toLowerCase() === WFTM_ADDRESS.toLowerCase();
  const isArbitrumNative =
    targetChain === CHAIN_ID_ARBITRUM &&
    targetAsset &&
    targetAsset.toLowerCase() === ARBWETH_ADDRESS.toLowerCase();
  const isSolNative =
    targetChain === CHAIN_ID_SOLANA &&
    targetAsset &&
    targetAsset === WSOL_ADDRESS;
  const isNativeEligible =
    isEthNative ||
    isBscNative ||
    isPolygonNative ||
    isFantomNative ||
    isArbitrumNative ||
    isSolNative;

  const [useNativeRedeem, setUseNativeRedeem] = useState(true);

  const toggleNativeRedeem = useCallback(() => {
    setUseNativeRedeem(!useNativeRedeem);
  }, [useNativeRedeem]);

  const handleResetClick = useCallback(() => {
    dispatch(reset());
  }, [dispatch]);

  // const howToAddTokensUrl = getHowToAddTokensToWalletUrl(targetChain);

  const originAsset = useSelector(
    (state: RootState) => state.transfer.originAsset
  );
  const originChain = useSelector(
    (state: RootState) => state.transfer.originChain
  );
  const sourceChain = useSelector(
    (state: RootState) => state.transfer.sourceChain
  );
  const { warnings, isRedeemDisabled } = useRedeemControl(
    transferRules,
    sourceChain,
    targetChain,
    originAsset,
    originChain
  );
  const useAutomaticRelay = useMemo(
    () => targetAsset && targetChain === CHAIN_ID_SEI,
    [targetAsset, targetChain]
  );

  const isRelayed = useMemo(
    () => useRelayer || useAutomaticRelay,
    [useRelayer, useAutomaticRelay]
  );

  const { isTransferCompletedLoading, isTransferCompleted } =
    useGetIsTransferCompleted(
      isRelayed ? false : true,
      isRelayed ? 5000 : undefined
    );

  const nonRelayContent = (
    <>
      <KeyAndBalance chainId={targetChain} />

      {isNativeEligible && (
        <FormControlLabel
          control={
            <Checkbox
              checked={useNativeRedeem}
              onChange={toggleNativeRedeem}
              color="primary"
            />
          }
          label="Automatically unwrap to native currency"
        />
      )}

      {targetChain === CHAIN_ID_SOLANA && CLUSTER === "mainnet" && (
        <SolanaTPSWarning />
      )}

      {targetChain === CHAIN_ID_SOLANA ? (
        <SolanaCreateAssociatedAddressAlternate />
      ) : null}

      {warnings.map((message, key) => (
        <ChainWarningMessage message={message} key={key} />
      ))}

      <>
        <ButtonWithLoader
          //TODO disable when the associated token account is confirmed to not exist
          disabled={
            isRedeemDisabled ||
            !isReady ||
            disabled ||
            (isRecovery && (isTransferCompletedLoading || isTransferCompleted))
          }
          onClick={
            isNativeEligible && useNativeRedeem
              ? handleNativeClick
              : handleClick
          }
          showLoader={
            !isRedeemDisabled &&
            (showLoader || (isRecovery && isTransferCompletedLoading))
          }
          error={statusMessage}
        >
          Redeem
        </ButtonWithLoader>

        <WaitingForWalletMessage />
      </>

      {isRecovery && isReady && isTransferCompleted ? (
        <>
          {/* <Alert severity="info" variant="outlined" className={classes.alert}>
            These tokens have already been redeemed.{" "}
            {!isEVMChain(targetChain) && howToAddTokensUrl ? (
              <Link
                href={howToAddTokensUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to see how to add them to your wallet.
              </Link>
            ) : null}
          </Alert> */}

          {targetAsset ? (
            <>
              <span>Token Address:</span>
              <SmartAddress
                chainId={targetChain}
                address={targetAsset || undefined}
                isAsset
              />
            </>
          ) : null}

          {isEVMChain(targetChain) ? (
            <AddToMetamask chainId={targetChain} />
          ) : null}

          <ButtonWithLoader onClick={handleResetClick}>
            Transfer More Tokens!
          </ButtonWithLoader>
        </>
      ) : null}
    </>
  );

  return <>{nonRelayContent}</>;
}

export default Redeem;
