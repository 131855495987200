import { CHAIN_ID_SOLANA, ChainId } from "@certusone/wormhole-sdk";
import {
  CircularProgress,
  createStyles,
  Divider,
  makeStyles,
  Tooltip,
  Typography,
  Box,
  IconButton,
} from "@material-ui/core";
import { InfoOutlined, ExpandMore, Refresh } from "@material-ui/icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";

import useMarketsMap from "../../hooks/useMarketsMap";
import { NFTParsedTokenAccount } from "../../store/nftSlice";
import { selectTransferTargetChain } from "../../store/selectors";
import { balancePretty } from "../../utils/balancePretty";
import { CHAINS_BY_ID } from "../../utils/consts";
import { shortenAddress } from "../../utils/solana";
import CustomDialog from "../CustomDialog";
import CustomTextField from "../CustomTextField";
import DisableWrapper from "../DisableWrapper";
import DialogList from "../DialogList";
import CustomIcon from "../CustomIcon";

const useStyles = makeStyles((theme) =>
  createStyles({
    loader: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
      margin: "40px 0",
      "& p": {
        color: theme.palette.secondary.light,
        marginTop: "10px",
      },
    },
    pickerDc: {
      minWidth: "400px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        minWidth: "auto",
      },
    },
    alignCenter: {
      textAlign: "center",
      justifyContent: "center",
      margin: "40px 0",
    },
    tokenBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      padding: "2px 0 5px 0",
      cursor: "pointer",
      border: "1px solid",
      borderColor: theme.palette.divider,
      borderRadius: "12px",
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        filter: "brightness(1.1)",
      },
    },
    emptyBox: {
      backgroundColor: theme.palette.primary.main,
    },
    icon: {
      height: 28,
      maxWidth: 28,
    },
    tokenName: {
      fontSize: "16px",
      margin: "0px 6px 0 6px",
      fontWeight: 600,
      whiteSpace: "nowrap",
    },
    listSec: {
      margin: "0 15px",
    },
    listContainer: {
      maxHeight: "400px",
      overflowY: "auto",
    },
    fieldWrapper: {
      display: "flex",
      alignItems: "center",
    },
    optionContainer: {
      padding: 0,
    },
    optionContent: {
      padding: theme.spacing(1),
    },
    tokenList: {
      maxHeight: theme.spacing(60),
      height: theme.spacing(60),
      overflow: "auto",
    },
    dialogContent: {
      overflowX: "hidden",
    },
    selectionButtonContainer: {
      //display: "flex",
      textAlign: "center",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    selectionButton: {
      maxWidth: "100%",
      width: theme.breakpoints.values.sm,
    },
    tokenOverviewContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      "& div": {
        margin: theme.spacing(1),
        flexBasis: "25%",
        "&$tokenImageContainer": {
          maxWidth: 40,
        },
        "&$tokenMarketsList": {
          marginTop: theme.spacing(-0.5),
          marginLeft: 0,
          flexBasis: "100%",
        },
        "&:last-child": {
          textAlign: "right",
        },
        flexShrink: 1,
      },
      flexWrap: "wrap",
    },
    tokenImageContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 40,
    },
    tokenImage: {
      maxHeight: "2.5rem", //Eyeballing this based off the text size
    },
    tokenMarketsList: {
      order: 1,
      textAlign: "left",
      width: "100%",
      "& > .MuiButton-root": {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
    migrationAlert: {
      width: "100%",
      "& .MuiAlert-message": {
        width: "100%",
      },
    },
    flexTitle: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    grower: {
      flexGrow: 1,
    },
    disabledTokenAlert: {
      borderStyle: "none",
    },
    symbolText: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: 115,
      overflow: "hidden",
    },
  })
);

interface MarketParsedTokenAccount extends NFTParsedTokenAccount {
  markets?: string[];
}

export default function TokenPicker({
  value,
  options,
  onChange,
  isValidAddress,
  getAddress,
  disabled,
  resetAccounts,
  nft,
  chainId,
  showLoader,
  useTokenId,
  displayBalance,
}: {
  value: NFTParsedTokenAccount | null;
  options: NFTParsedTokenAccount[];
  onChange: (
    newValue: NFTParsedTokenAccount | null,
    hotFixSolana?: boolean
  ) => Promise<void>;
  isValidAddress?: (address: string, chainId: ChainId) => boolean;
  getAddress?: (
    address: string,
    tokenId?: string
  ) => Promise<NFTParsedTokenAccount>;
  disabled: boolean;
  resetAccounts: (() => void) | undefined;
  nft: boolean;
  chainId: ChainId;
  error?: string;
  showLoader?: boolean;
  useTokenId?: boolean;
  displayBalance?: (account: NFTParsedTokenAccount) => boolean;
}) {
  const classes = useStyles();
  const [holderString, setHolderString] = useState("");
  const [tokenIdHolderString, setTokenIdHolderString] = useState("");
  const [loadingError, setLoadingError] = useState("");
  const [isLocalLoading, setLocalLoading] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [selectionError, setSelectionError] = useState("");

  const targetChain = useSelector(selectTransferTargetChain);
  const { data: marketsData } = useMarketsMap(true);

  const openDialog = useCallback(() => {
    setHolderString("");
    setSelectionError("");
    setDialogIsOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setDialogIsOpen(false);
  }, []);

  const handleSelectOption = useCallback(
    async (option: NFTParsedTokenAccount, hotFixSolana?: boolean) => {
      setSelectionError("");
      try {
        // covalent balances tend to be stale, so we make an attempt to correct it at selection time.
        const updatedOption: Partial<NFTParsedTokenAccount> =
          typeof getAddress === "function" &&
          !option.isNativeAsset &&
          !hotFixSolana
            ? await (async () => {
                try {
                  const response = await getAddress(
                    option.mintKey,
                    option.tokenId
                  );
                  return response;
                } catch (error: any) {
                  // using stale value when updated value can't be found
                  if (error?.message === "Asset not found") return option;
                  throw error;
                }
              })()
            : {};

        await onChange(
          {
            ...option,
            ...updatedOption,
            // keep logo and uri from covalent / market list / etc (otherwise would be overwritten by undefined)
            logo: option.logo || updatedOption?.logo,
            uri: option.uri || updatedOption?.uri,
          } as NFTParsedTokenAccount,
          chainId === CHAIN_ID_SOLANA
        );
        closeDialog();
      } catch (e: any) {
        console.error(e);
        setSelectionError(
          e?.message?.includes("v1")
            ? e.message
            : "Unable to retrieve required information about this token. Ensure your wallet is connected, then refresh the list."
        );
      }
    },
    [getAddress, onChange, closeDialog]
  );

  const resetAccountsWrapper = useCallback(() => {
    setHolderString("");
    setTokenIdHolderString("");
    setSelectionError("");
    resetAccounts && resetAccounts();
  }, [resetAccounts]);

  const searchFilter = useCallback(
    (option: NFTParsedTokenAccount) => {
      if (!holderString) {
        return true;
      }

      const optionString = [
        option.publicKey,
        option.mintKey,
        option.symbol,
        option.name,
      ]
        .filter(Boolean)
        .join(" ")
        .toLowerCase();

      return optionString.includes(holderString.toLowerCase());
    },
    [holderString]
  );

  const formateOption = useCallback(
    (option: NFTParsedTokenAccount) => {
      const shouldDisplayBalance = !displayBalance || displayBalance(option);
      return {
        icon: option.logo || option.uri,
        title: option.symbol || option.name || "Unknown",
        subTitle: option.isNativeAsset
          ? "Native"
          : shortenAddress(option.mintKey),
        value: shouldDisplayBalance
          ? balancePretty(option.uiAmountString)
          : undefined,
        item: option,
        onSelect: () => {
          closeDialog();
          handleSelectOption(option, chainId === CHAIN_ID_SOLANA);
        },
      };
    },
    [displayBalance, handleSelectOption]
  );

  const marketChainTokens = marketsData?.tokens?.[chainId];
  const featuredMarkets = marketsData?.tokenMarkets?.[chainId]?.[targetChain];

  const featuredOptions = useMemo(() => {
    // only tokens have featured markets
    if (!nft && featuredMarkets) {
      const ownedMarketTokens = options
        .filter(
          (option: NFTParsedTokenAccount) => featuredMarkets?.[option.mintKey]
        )
        .map(
          (option) =>
            ({
              ...option,
              markets: featuredMarkets[option.mintKey].markets,
            }) as MarketParsedTokenAccount
        );
      return [
        ...ownedMarketTokens,
        ...Object.keys(featuredMarkets)
          .filter(
            (mintKey) =>
              !ownedMarketTokens.find((option) => option.mintKey === mintKey)
          )
          .map(
            (mintKey) =>
              ({
                amount: "0",
                decimals: 0,
                markets: featuredMarkets[mintKey].markets,
                mintKey,
                publicKey: "",
                uiAmount: 0,
                uiAmountString: "0", // if we can't look up by address, we can select the market that isn't in the list of holdings, but can't proceed since the balance will be 0
                symbol: marketChainTokens?.[mintKey]?.symbol,
                logo: marketChainTokens?.[mintKey]?.logo,
              }) as MarketParsedTokenAccount
          ),
      ].filter(searchFilter);
    }
    return [];
  }, [nft, marketChainTokens, featuredMarkets, options, searchFilter]);

  const nonFeaturedOptions = useMemo(() => {
    return options
      .map((option) => ({
        ...option,
        symbol: marketChainTokens?.[option.mintKey]?.symbol || option.symbol,
      }))
      .filter(
        (option: NFTParsedTokenAccount) =>
          searchFilter(option) &&
          // only tokens have featured markets
          (nft || !featuredMarkets?.[option.mintKey])
      );
  }, [nft, options, featuredMarkets, marketChainTokens, searchFilter]);

  const localFind = useCallback(
    (address: string, tokenIdHolderString: string) => {
      return options.find(
        (x) =>
          x.mintKey === address &&
          (!tokenIdHolderString || x.tokenId === tokenIdHolderString)
      );
    },
    [options]
  );

  //This is the effect which allows pasting an address in directly
  useEffect(() => {
    if (!isValidAddress || !getAddress) {
      return;
    }
    if (useTokenId && !tokenIdHolderString) {
      return;
    }
    setLoadingError("");
    let cancelled = false;
    if (isValidAddress(holderString, chainId)) {
      const option = localFind(holderString, tokenIdHolderString);
      if (option) {
        handleSelectOption(option);
        return () => {
          cancelled = true;
        };
      }
      setLocalLoading(true);
      setLoadingError("");
      getAddress(
        holderString,
        useTokenId ? tokenIdHolderString : undefined
      ).then(
        (result) => {
          if (!cancelled) {
            setLocalLoading(false);
            if (result) {
              handleSelectOption(result);
            }
          }
        },
        (error) => {
          if (!cancelled) {
            setLocalLoading(false);
            console.error(error);
            // leaving default "no results"
            if (error?.message !== "Asset not found") {
              setLoadingError("Could not find the specified address.");
            }
          }
        }
      );
    }
    return () => (cancelled = true);
  }, [
    holderString,
    isValidAddress,
    getAddress,
    // handleSelectOption,
    localFind,
    tokenIdHolderString,
    useTokenId,
    chainId,
  ]);

  //TODO reset button
  //TODO debounce & save hotloaded options as an option before automatically selecting
  //TODO sigfigs function on the balance strings

  const localLoader = (
    <div className={classes.loader}>
      <CircularProgress size={20} />
      <Typography variant="body2">
        {showLoader ? "Loading available tokens" : "Searching for results"}
      </Typography>
    </div>
  );

  const displayLocalError = (
    <div className={classes.alignCenter}>
      <Typography variant="body2" color="error">
        {loadingError || selectionError}
      </Typography>
    </div>
  );

  const formattedFeaturedOptions = useMemo(
    () => featuredOptions.map((option) => formateOption(option)),
    [featuredOptions, formateOption]
  );

  const formattedNonFeaturedOptions = useMemo(
    () => nonFeaturedOptions.map((option) => formateOption(option)),
    [nonFeaturedOptions, formateOption]
  );

  const dialog = (
    <>
      <CustomDialog
        title="Select token"
        open={dialogIsOpen}
        handleClose={closeDialog}
        content={
          <Box className={classes.pickerDc}>
            <Box className={classes.fieldWrapper}>
              <Box style={{ flex: 1 }}>
                <CustomTextField
                  placeholder="Search name or paste address"
                  value={holderString}
                  onChange={(event) => setHolderString(event.target.value)}
                />
              </Box>
              <Box style={{ marginRight: 5 }}>
                <Tooltip title="Reload tokens">
                  <IconButton size="small" onClick={resetAccountsWrapper}>
                    <Refresh />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            {isLocalLoading || showLoader ? (
              localLoader
            ) : loadingError || selectionError ? (
              displayLocalError
            ) : (
              <Box className={classes.listContainer}>
                {featuredOptions.length ? (
                  <>
                    <Box className={classes.listSec}>
                      <Typography variant="subtitle2" gutterBottom>
                        Featured {CHAINS_BY_ID[chainId].name} &gt;{" "}
                        {CHAINS_BY_ID[targetChain].name} markets{" "}
                        <Tooltip
                          title={`Markets for these ${CHAINS_BY_ID[chainId].name} tokens exist for the corresponding tokens on ${CHAINS_BY_ID[targetChain].name}`}
                        >
                          <InfoOutlined
                            fontSize="small"
                            style={{ verticalAlign: "text-bottom" }}
                          />
                        </Tooltip>
                      </Typography>
                    </Box>

                    <DialogList items={formattedFeaturedOptions} />

                    {formattedNonFeaturedOptions.length ? (
                      <Box className={classes.listSec}>
                        <Divider style={{ marginTop: 8, marginBottom: 16 }} />
                        <Typography variant="subtitle2" gutterBottom>
                          Other Assets
                        </Typography>
                      </Box>
                    ) : null}
                  </>
                ) : null}

                <DialogList items={formattedNonFeaturedOptions} />

                {featuredOptions.length || nonFeaturedOptions.length ? null : (
                  <div className={classes.alignCenter}>
                    <Typography>No results found</Typography>
                  </div>
                )}
              </Box>
            )}
          </Box>
        }
      />
    </>
  );

  return (
    <>
      {dialog}
      <DisableWrapper disable={disabled}>
        <Box
          className={clsx(classes.tokenBox, !value ? classes.emptyBox : "")}
          onClick={openDialog}
        >
          {value ? (
            <>
              <CustomIcon
                src={value.logo || value.uri}
                alt={value.symbol || "Unknown"}
                className={classes.icon}
              />
              <div className={classes.tokenName}>
                {value.symbol || "Unknown"}
              </div>
            </>
          ) : (
            <div className={classes.tokenName}>Select token</div>
          )}

          <ExpandMore fontSize="medium" />
        </Box>
      </DisableWrapper>
    </>
  );
}
