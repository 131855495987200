import {
  ChainId,
  CHAIN_ID_KLAYTN,
  CHAIN_ID_SOLANA,
  getEmitterAddressEth,
  getEmitterAddressSolana,
  hexToUint8Array,
  isEVMChain,
  parseSequenceFromLogEth,
  parseSequenceFromLogSolana,
  transferFromEth,
  transferFromEthNative,
  uint8ArrayToHex,
  CHAIN_ID_ETH,
  CHAIN_ID_POLYGON,
  tryNativeToUint8Array,
  hexToNativeString,
} from "@certusone/wormhole-sdk";
import { Alert } from "@material-ui/lab";
import { Connection } from "@solana/web3.js";
import { BigNumber, Contract, ContractReceipt, Signer } from "ethers";
import { parseUnits, zeroPad } from "ethers/lib/utils";
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";
import { useEthereumProvider } from "../contexts/EthereumProviderContext";
import {
  transferFromSolana,
  transferNativeSol,
  useSolanaWallet,
} from "../contexts/SolanaWalletContext";

import {
  selectTransferAmount,
  selectTransferIsSendComplete,
  selectTransferIsSending,
  selectTransferIsTBTC,
  selectTransferIsTargetComplete,
  selectTransferOriginAsset,
  selectTransferOriginChain,
  selectTransferRelayerFee,
  selectTransferSourceAsset,
  selectTransferSourceChain,
  selectTransferSourceParsedTokenAccount,
  selectTransferTargetAddressHex,
  selectTransferTargetChain,
  selectTransferTargetParsedTokenAccount,
  selectTransferTargetAsset,
} from "../store/selectors";
import {
  setIsSending,
  setIsVAAPending,
  setSignedVAAHex,
  setTransferTx,
} from "../store/transferSlice";
import {
  getBridgeAddressForChain,
  getTokenBridgeAddressForChain,
  SOLANA_HOST,
  SOL_BRIDGE_ADDRESS,
  SOL_TOKEN_BRIDGE_ADDRESS,
  THRESHOLD_ARBITER_FEE,
  THRESHOLD_NONCE,
  THRESHOLD_GATEWAYS,
  THRESHOLD_TBTC_CONTRACTS,
} from "../utils/consts";
import { getSignedVAAWithRetry } from "../utils/getSignedVAAWithRetry";
import parseError from "../utils/parseError";
import { signSendAndConfirm } from "../utils/solana";
import useTransferTargetAddressHex from "./useTransferTargetAddress";
import { SolanaWallet } from "@xlabs-libs/wallet-aggregator-solana";
import { ThresholdL2WormholeGateway } from "../utils/ThresholdL2WormholeGateway";
import { newThresholdWormholeGateway } from "../assets/providers/tbtc/solana/WormholeGateway.v2";
import { CHAINS_RECORD } from "../utils/consts";

type AdditionalPayloadOverride = {
  receivingContract: Uint8Array;
  payload: Uint8Array;
};
type MaybeAdditionalPayloadFn = () => AdditionalPayloadOverride | null;

async function fetchSignedVAA(
  chainId: ChainId,
  emitterAddress: string,
  sequence: string,
  enqueueSnackbar: any,
  dispatch: any
) {
  enqueueSnackbar(null, {
    content: <Alert severity="info">Fetching VAA</Alert>,
  });
  const { vaaBytes, isPending } = await getSignedVAAWithRetry(
    chainId,
    emitterAddress,
    sequence
  );
  if (vaaBytes !== undefined) {
    dispatch(setSignedVAAHex(uint8ArrayToHex(vaaBytes)));
    dispatch(setIsVAAPending(false));
    enqueueSnackbar(null, {
      content: <Alert severity="success">Fetched Signed VAA</Alert>,
    });
  } else if (isPending) {
    dispatch(setIsVAAPending(isPending));
    enqueueSnackbar(null, {
      content: <Alert severity="warning">VAA is Pending</Alert>,
    });
  } else {
    throw new Error("Error retrieving VAA info");
  }
}

function handleError(e: any, enqueueSnackbar: any, dispatch: any) {
  console.error(e);
  enqueueSnackbar(null, {
    content: <Alert severity="error">{parseError(e)}</Alert>,
  });
  dispatch(setIsSending(false));
  dispatch(setIsVAAPending(false));
}

// async function algo(
//   dispatch: any,
//   enqueueSnackbar: any,
//   wallet: AlgorandWallet,
//   tokenAddress: string,
//   decimals: number,
//   amount: string,
//   recipientChain: ChainId,
//   recipientAddress: Uint8Array,
//   chainId: ChainId,
//   maybeAdditionalPayload: MaybeAdditionalPayloadFn,
//   relayerFee?: string,
//   onError?: (error: any) => void,
//   onStart?: (extra?: Partial<TelemetryTxEvent>) => void
// ) {
//   dispatch(setIsSending(true));
//   try {
//     const baseAmountParsed = parseUnits(amount, decimals);
//     const feeParsed = parseUnits(relayerFee || "0", decimals);
//     const transferAmountParsed = baseAmountParsed.add(feeParsed);
//     const additionalPayload = maybeAdditionalPayload();
//     const algodClient = new algosdk.Algodv2(
//       ALGORAND_HOST.algodToken,
//       ALGORAND_HOST.algodServer,
//       ALGORAND_HOST.algodPort
//     );
//     const txs = await transferFromAlgorand(
//       algodClient as any,
//       ALGORAND_TOKEN_BRIDGE_ID,
//       ALGORAND_BRIDGE_ID,
//       wallet.getAddress()!,
//       BigInt(tokenAddress),
//       transferAmountParsed.toBigInt(),
//       uint8ArrayToHex(additionalPayload?.receivingContract || recipientAddress),
//       recipientChain,
//       feeParsed.toBigInt(),
//       additionalPayload?.payload
//     );
//     const result = await signSendAndConfirmAlgorand(wallet, algodClient, txs);
//     const sequence = parseSequenceFromLogAlgorand(result);
//     const txId = txs[txs.length - 1].tx.txID();
//     onStart?.({ txId });
//     dispatch(
//       setTransferTx({
//         id: txId,
//         block: result["confirmed-round"],
//       })
//     );
//     enqueueSnackbar(null, {
//       content: <Alert severity="success">Transaction confirmed</Alert>,
//     });
//     const emitterAddress = getEmitterAddressAlgorand(ALGORAND_TOKEN_BRIDGE_ID);
//     await fetchSignedVAA(
//       chainId,
//       emitterAddress,
//       sequence,
//       enqueueSnackbar,
//       dispatch
//     );
//   } catch (e) {
//     handleError(e, enqueueSnackbar, dispatch);
//     onError?.(e);
//   }
// }

// async function aptos(
//   dispatch: any,
//   enqueueSnackbar: any,
//   tokenAddress: string,
//   decimals: number,
//   amount: string,
//   recipientChain: ChainId,
//   recipientAddress: Uint8Array,
//   chainId: ChainId,
//   wallet: AptosWallet,
//   maybeAdditionalPayload: MaybeAdditionalPayloadFn,
//   relayerFee?: string,
//   onError?: (error: any) => void,
//   onStart?: (extra?: Partial<TelemetryTxEvent>) => void
// ) {
//   dispatch(setIsSending(true));
//   const tokenBridgeAddress = getTokenBridgeAddressForChain(CHAIN_ID_APTOS);
//   try {
//     const baseAmountParsed = parseUnits(amount, decimals);
//     const feeParsed = parseUnits(relayerFee || "0", decimals);
//     const transferAmountParsed = baseAmountParsed.add(feeParsed);

//     const additionalPayload = maybeAdditionalPayload();

//     const transferPayload = transferFromAptos(
//       tokenBridgeAddress,
//       tokenAddress,
//       transferAmountParsed.toString(),
//       recipientChain,
//       additionalPayload?.receivingContract || recipientAddress,
//       additionalPayload?.payload
//         ? undefined
//         : createNonce().readUInt32LE(0).toString(),
//       additionalPayload?.payload
//     );

//     const hash = await waitForSignAndSubmitTransaction(transferPayload, wallet);
//     onStart?.({ txId: hash });
//     dispatch(setTransferTx({ id: hash, block: 1 }));
//     enqueueSnackbar(null, {
//       content: <Alert severity="success">Transaction confirmed</Alert>,
//     });
//     const result = (await getAptosClient().waitForTransactionWithResult(
//       hash
//     )) as Types.UserTransaction;
//     const { emitterAddress, sequence } =
//       getEmitterAddressAndSequenceFromResult(result);
//     await fetchSignedVAA(
//       chainId,
//       emitterAddress,
//       sequence,
//       enqueueSnackbar,
//       dispatch
//     );
//   } catch (e) {
//     enqueueSnackbar(null, {
//       content: <Alert severity="error">{parseError(e)}</Alert>,
//     });
//     dispatch(setIsSending(false));
//     onError?.(e);
//   }
// }

// Threshold normalize amounts
function normalizeAmount(amount: BigNumber, decimals: number): BigNumber {
  if (decimals > 8) {
    amount = amount.div(BigNumber.from(10).pow(decimals - 8));
  }
  return amount;
}

function deNormalizeAmount(amount: BigNumber, decimals: number): BigNumber {
  if (decimals > 8) {
    amount = amount.mul(BigNumber.from(10).pow(decimals - 8));
  }
  return amount;
}

async function evm(
  dispatch: any,
  enqueueSnackbar: any,
  signer: Signer,
  tokenAddress: string,
  decimals: number,
  amount: string,
  recipientChain: ChainId,
  recipientAddress: Uint8Array,
  isNative: boolean,
  chainId: ChainId,
  isTBTC: boolean,
  maybeAdditionalPayload: MaybeAdditionalPayloadFn,
  emit: (wallet: string, txId?: string) => void,
  relayerFee?: string,
  onError?: (error: any) => void,
  onStart?: (extra?: Partial<any>) => void
) {
  dispatch(setIsSending(true));
  try {
    const baseAmountParsed = parseUnits(amount, decimals);
    const feeParsed = parseUnits(relayerFee || "0", decimals);
    const transferAmountParsed = baseAmountParsed.add(feeParsed);

    let receipt: ContractReceipt;

    if (isTBTC && THRESHOLD_GATEWAYS[chainId]) {
      const sourceAddress = THRESHOLD_GATEWAYS[chainId].toLowerCase();
      const L2WormholeGateway = new Contract(
        sourceAddress,
        ThresholdL2WormholeGateway,
        signer
      );

      const amountNormalizeAmount = deNormalizeAmount(
        normalizeAmount(transferAmountParsed, decimals),
        decimals
      );

      const estimateGas = await L2WormholeGateway.estimateGas.sendTbtc(
        amountNormalizeAmount,
        recipientChain,
        recipientAddress,
        THRESHOLD_ARBITER_FEE,
        THRESHOLD_NONCE
      );

      // We increase the gas limit estimation here by a factor of 10% to account for
      // some faulty public JSON-RPC endpoints.
      const gasLimit = estimateGas.mul(1100).div(1000);
      const overrides = {
        gasLimit,
        // We use the legacy tx envelope here to avoid triggering gas price autodetection using EIP1559 for polygon.
        // EIP1559 is not actually implemented in polygon. The node is only API compatible but this breaks some clients
        // like ethers when choosing fees automatically.
        ...(chainId === CHAIN_ID_POLYGON && { type: 0 }),
      };

      const tx = await L2WormholeGateway.sendTbtc(
        amountNormalizeAmount,
        recipientChain,
        recipientAddress,
        THRESHOLD_ARBITER_FEE,
        THRESHOLD_NONCE,
        overrides
      );

      receipt = await tx.wait();
    } else {
      const baseAmountParsed = parseUnits(amount, decimals);
      const feeParsed = parseUnits(relayerFee || "0", decimals);
      const transferAmountParsed = baseAmountParsed.add(feeParsed);
      // Klaytn requires specifying gasPrice
      const overrides =
        chainId === CHAIN_ID_KLAYTN
          ? { gasPrice: (await signer.getGasPrice()).toString() }
          : {};

      const additionalPayload = maybeAdditionalPayload();

      receipt = isNative
        ? await transferFromEthNative(
            getTokenBridgeAddressForChain(chainId),
            signer,
            transferAmountParsed,
            recipientChain,
            additionalPayload?.receivingContract || recipientAddress,
            feeParsed,
            overrides,
            additionalPayload?.payload
          )
        : await transferFromEth(
            getTokenBridgeAddressForChain(chainId),
            signer,
            tokenAddress,
            transferAmountParsed,
            recipientChain,
            additionalPayload?.receivingContract || recipientAddress,
            feeParsed,
            overrides,
            additionalPayload?.payload
          );
    }

    onStart?.({ txId: receipt.transactionHash });

    dispatch(
      setTransferTx({
        id: receipt.transactionHash,
        block: receipt.blockNumber,
      })
    );

    enqueueSnackbar(null, {
      content: <Alert severity="success">Transaction confirmed</Alert>,
    });

    const sequence = parseSequenceFromLogEth(
      receipt,
      getBridgeAddressForChain(chainId)
    );

    const emitterAddress = getEmitterAddressEth(
      getTokenBridgeAddressForChain(chainId)
    );

    const wAddress = await signer.getAddress();
    emit(wAddress, receipt.transactionHash);

    await fetchSignedVAA(
      chainId,
      emitterAddress,
      sequence,
      enqueueSnackbar,
      dispatch
    );
  } catch (e) {
    handleError(e, enqueueSnackbar, dispatch);
    onError?.(e);

    const wAddress = await signer.getAddress();
    emit(wAddress);
  }
}

// async function near(
//   dispatch: any,
//   enqueueSnackbar: any,
//   wallet: NearWallet,
//   senderAddr: string,
//   tokenAddress: string,
//   decimals: number,
//   amount: string,
//   recipientChain: ChainId,
//   recipientAddress: Uint8Array,
//   chainId: ChainId,
//   maybeAdditionalPayload: MaybeAdditionalPayloadFn,
//   relayerFee?: string,
//   onError?: (error: any) => void,
//   onStart?: (extra?: Partial<TelemetryTxEvent>) => void
// ) {
//   dispatch(setIsSending(true));
//   try {
//     const baseAmountParsed = parseUnits(amount, decimals);
//     const feeParsed = parseUnits(relayerFee || "0", decimals);
//     const transferAmountParsed = baseAmountParsed.add(feeParsed);
//     const additionalPayload = maybeAdditionalPayload();
//     const account = await makeNearAccount(senderAddr);
//     const msgs =
//       tokenAddress === NATIVE_NEAR_PLACEHOLDER
//         ? await transferNearFromNear(
//             account,
//             NEAR_CORE_BRIDGE_ACCOUNT,
//             NEAR_TOKEN_BRIDGE_ACCOUNT,
//             transferAmountParsed.toBigInt(),
//             additionalPayload?.receivingContract || recipientAddress,
//             recipientChain,
//             feeParsed.toBigInt(),
//             additionalPayload?.payload
//               ? uint8ArrayToHex(additionalPayload.payload)
//               : undefined
//           )
//         : await transferTokenFromNear(
//             account,
//             NEAR_CORE_BRIDGE_ACCOUNT,
//             NEAR_TOKEN_BRIDGE_ACCOUNT,
//             tokenAddress,
//             transferAmountParsed.toBigInt(),
//             additionalPayload?.receivingContract || recipientAddress,
//             recipientChain,
//             feeParsed.toBigInt(),
//             additionalPayload?.payload
//               ? uint8ArrayToHex(additionalPayload.payload)
//               : undefined
//           );
//     const receipt = await signAndSendTransactions(account, wallet, msgs);
//     onStart?.({ txId: receipt.transaction_outcome.id });
//     const sequence = parseSequenceFromLogNear(receipt);
//     dispatch(
//       setTransferTx({
//         id: receipt.transaction_outcome.id,
//         block: 0,
//       })
//     );
//     enqueueSnackbar(null, {
//       content: <Alert severity="success">Transaction confirmed</Alert>,
//     });
//     const emitterAddress = getEmitterAddressNear(NEAR_TOKEN_BRIDGE_ACCOUNT);
//     await fetchSignedVAA(
//       chainId,
//       emitterAddress,
//       sequence,
//       enqueueSnackbar,
//       dispatch
//     );
//   } catch (e) {
//     handleError(e, enqueueSnackbar, dispatch);
//     onError?.(e);
//   }
// }

// async function xpla(
//   dispatch: any,
//   enqueueSnackbar: any,
//   wallet: XplaWallet,
//   asset: string,
//   amount: string,
//   decimals: number,
//   targetChain: ChainId,
//   targetAddress: Uint8Array,
//   maybeAdditionalPayload: MaybeAdditionalPayloadFn,
//   relayerFee?: string,
//   onError?: (error: any) => void,
//   onStart?: (extra?: Partial<TelemetryTxEvent>) => void
// ) {
//   dispatch(setIsSending(true));
//   try {
//     const baseAmountParsed = parseUnits(amount, decimals);
//     const feeParsed = parseUnits(relayerFee || "0", decimals);
//     const transferAmountParsed = baseAmountParsed.add(feeParsed);
//     const additionalPayload = maybeAdditionalPayload();
//     const tokenBridgeAddress = getTokenBridgeAddressForChain(CHAIN_ID_XPLA);
//     const msgs = await transferFromXpla(
//       wallet.getAddress()!,
//       tokenBridgeAddress,
//       asset,
//       transferAmountParsed.toString(),
//       targetChain,
//       additionalPayload?.receivingContract || targetAddress,
//       feeParsed.toString(),
//       additionalPayload?.payload
//     );

//     const result = await postWithFeesXpla(
//       wallet,
//       msgs,
//       "Wormhole - Initiate Transfer"
//     );

//     const info = await waitForXplaExecution(result);
//     onStart?.({ txId: info.txhash });
//     dispatch(setTransferTx({ id: info.txhash, block: info.height }));
//     enqueueSnackbar(null, {
//       content: <Alert severity="success">Transaction confirmed</Alert>,
//     });
//     const sequence = parseSequenceFromLogXpla(info);
//     if (!sequence) {
//       throw new Error("Sequence not found");
//     }
//     const emitterAddress = await getEmitterAddressXpla(tokenBridgeAddress);
//     await fetchSignedVAA(
//       CHAIN_ID_XPLA,
//       emitterAddress,
//       sequence,
//       enqueueSnackbar,
//       dispatch
//     );
//   } catch (e) {
//     handleError(e, enqueueSnackbar, dispatch);
//     onError?.(e);
//   }
// }

async function solana(
  dispatch: any,
  enqueueSnackbar: any,
  wallet: SolanaWallet,
  payerAddress: string, //TODO: we may not need this since we have wallet
  fromAddress: string,
  mintAddress: string,
  amount: string,
  decimals: number,
  targetChain: ChainId,
  targetAddress: Uint8Array,
  isNative: boolean,
  maybeAdditionalPayload: MaybeAdditionalPayloadFn,
  isTBTC: boolean,
  emit: (wallet: string, txId?: string) => void,
  originAddressStr?: string,
  originChain?: ChainId,
  relayerFee?: string,
  onError?: (error: any) => void,
  onStart?: (extra?: Partial<any>) => void
) {
  dispatch(setIsSending(true));
  try {
    const connection = new Connection(SOLANA_HOST, "confirmed");
    const baseAmountParsed = parseUnits(amount, decimals);
    const feeParsed = parseUnits(relayerFee || "0", decimals);
    const transferAmountParsed = baseAmountParsed.add(feeParsed);
    const additionalPayload = maybeAdditionalPayload();
    const originAddress = originAddressStr
      ? zeroPad(hexToUint8Array(originAddressStr), 32)
      : undefined;

    if (THRESHOLD_TBTC_CONTRACTS[CHAIN_ID_SOLANA] === mintAddress) {
      const wormholeGateway = newThresholdWormholeGateway(connection, wallet);
      const transaction = await wormholeGateway.sendTbtc(
        transferAmountParsed.toBigInt(),
        targetChain,
        targetAddress,
        fromAddress,
        mintAddress
      );
      const txid = await signSendAndConfirm(wallet, transaction);
      onStart?.({ txId: txid });
      enqueueSnackbar(null, {
        content: <Alert severity="success">Transaction confirmed</Alert>,
      });
      const info = await connection.getTransaction(txid);
      if (!info) {
        throw new Error(
          "An error occurred while fetching the transaction info"
        );
      }
      dispatch(setTransferTx({ id: txid, block: info.slot }));
      emit(payerAddress, txid);

      const sequence = parseSequenceFromLogSolana(info);
      const emitterAddress = await getEmitterAddressSolana(
        SOL_TOKEN_BRIDGE_ADDRESS
      );
      await fetchSignedVAA(
        CHAIN_ID_SOLANA,
        emitterAddress,
        sequence,
        enqueueSnackbar,
        dispatch
      );
    } else {
      const promise = isNative
        ? transferNativeSol(
            connection,
            SOL_BRIDGE_ADDRESS,
            SOL_TOKEN_BRIDGE_ADDRESS,
            payerAddress,
            transferAmountParsed.toBigInt(),
            additionalPayload?.receivingContract || targetAddress,
            targetChain,
            feeParsed.toBigInt(),
            additionalPayload?.payload
          )
        : transferFromSolana(
            connection,
            SOL_BRIDGE_ADDRESS,
            SOL_TOKEN_BRIDGE_ADDRESS,
            payerAddress,
            fromAddress,
            mintAddress,
            transferAmountParsed.toBigInt(),
            additionalPayload?.receivingContract || targetAddress,
            targetChain,
            originAddress,
            originChain,
            undefined,
            feeParsed.toBigInt(),
            additionalPayload?.payload
          );
      const transaction = await promise;
      const txid = await signSendAndConfirm(wallet, transaction);
      onStart?.({ txId: txid });
      enqueueSnackbar(null, {
        content: <Alert severity="success">Transaction confirmed</Alert>,
      });
      const info = await connection.getTransaction(txid);
      if (!info) {
        throw new Error(
          "An error occurred while fetching the transaction info"
        );
      }
      dispatch(setTransferTx({ id: txid, block: info.slot }));
      emit(payerAddress, txid);
      const sequence = parseSequenceFromLogSolana(info);
      const emitterAddress = await getEmitterAddressSolana(
        SOL_TOKEN_BRIDGE_ADDRESS
      );
      await fetchSignedVAA(
        CHAIN_ID_SOLANA,
        emitterAddress,
        sequence,
        enqueueSnackbar,
        dispatch
      );
    }
  } catch (e) {
    console.trace(e);
    handleError(e, enqueueSnackbar, dispatch);
    emit(payerAddress);
    onError?.(e);
  }
}

// async function terra(
//   dispatch: any,
//   enqueueSnackbar: any,
//   wallet: TerraWallet,
//   asset: string,
//   amount: string,
//   decimals: number,
//   targetChain: ChainId,
//   targetAddress: Uint8Array,
//   feeDenom: string,
//   chainId: TerraChainId,
//   maybeAdditionalPayload: MaybeAdditionalPayloadFn,
//   relayerFee?: string,
//   onError?: (error: any) => void,
//   onStart?: (extra?: Partial<TelemetryTxEvent>) => void
// ) {
//   dispatch(setIsSending(true));
//   try {
//     const baseAmountParsed = parseUnits(amount, decimals);
//     const feeParsed = parseUnits(relayerFee || "0", decimals);
//     const transferAmountParsed = baseAmountParsed.add(feeParsed);
//     const additionalPayload = maybeAdditionalPayload();
//     const tokenBridgeAddress = getTokenBridgeAddressForChain(chainId);
//     const msgs = await transferFromTerra(
//       wallet.getAddress()!,
//       tokenBridgeAddress,
//       asset,
//       transferAmountParsed.toString(),
//       targetChain,
//       additionalPayload?.receivingContract || targetAddress,
//       feeParsed.toString(),
//       additionalPayload?.payload
//     );
//     const result = await postWithFees(
//       wallet,
//       msgs,
//       "Wormhole - Initiate Transfer",
//       [feeDenom],
//       chainId
//     );

//     const info = await waitForTerraExecution(result, chainId);
//     dispatch(setTransferTx({ id: info.txhash, block: info.height }));
//     enqueueSnackbar(null, {
//       content: <Alert severity="success">Transaction confirmed</Alert>,
//     });
//     const sequence = parseSequenceFromLogTerra(info);
//     if (!sequence) {
//       throw new Error("Sequence not found");
//     }
//     onStart?.({ txId: info.txhash });
//     const emitterAddress = await getEmitterAddressTerra(tokenBridgeAddress);
//     await fetchSignedVAA(
//       chainId,
//       emitterAddress,
//       sequence,
//       enqueueSnackbar,
//       dispatch
//     );
//   } catch (e) {
//     handleError(e, enqueueSnackbar, dispatch);
//     onError?.(e);
//   }
// }

// async function injective(
//   dispatch: any,
//   enqueueSnackbar: any,
//   wallet: InjectiveWallet,
//   walletAddress: string,
//   asset: string,
//   amount: string,
//   decimals: number,
//   targetChain: ChainId,
//   targetAddress: Uint8Array,
//   maybeAdditionalPayload: MaybeAdditionalPayloadFn,
//   relayerFee?: string,
//   onError?: (error: any) => void,
//   onStart?: (extra?: Partial<TelemetryTxEvent>) => void
// ) {
//   dispatch(setIsSending(true));
//   try {
//     const baseAmountParsed = parseUnits(amount, decimals);
//     const feeParsed = parseUnits(relayerFee || "0", decimals);
//     const transferAmountParsed = baseAmountParsed.add(feeParsed);
//     const additionalPayload = maybeAdditionalPayload();
//     const tokenBridgeAddress =
//       getTokenBridgeAddressForChain(CHAIN_ID_INJECTIVE);
//     const msgs = await transferFromInjective(
//       walletAddress,
//       tokenBridgeAddress,
//       asset,
//       transferAmountParsed.toString(),
//       targetChain,
//       additionalPayload?.receivingContract || targetAddress,
//       feeParsed.toString(),
//       additionalPayload?.payload
//     );
//     const tx = await broadcastInjectiveTx(
//       wallet,
//       walletAddress,
//       msgs,
//       "Wormhole - Initiate Transfer"
//     );
//     onStart?.({ txId: tx.txHash });
//     dispatch(setTransferTx({ id: tx.txHash, block: tx.height }));
//     enqueueSnackbar(null, {
//       content: <Alert severity="success">Transaction confirmed</Alert>,
//     });
//     const sequence = parseSequenceFromLogInjective(tx);
//     if (!sequence) {
//       throw new Error("Sequence not found");
//     }
//     const emitterAddress = await getEmitterAddressInjective(tokenBridgeAddress);
//     await fetchSignedVAA(
//       CHAIN_ID_INJECTIVE,
//       emitterAddress,
//       sequence,
//       enqueueSnackbar,
//       dispatch
//     );
//   } catch (e) {
//     handleError(e, enqueueSnackbar, dispatch);
//     onError?.(e);
//   }
// }

// async function sei(
//   dispatch: any,
//   enqueueSnackbar: any,
//   wallet: SeiWallet,
//   asset: string,
//   amount: string,
//   decimals: number,
//   targetChain: ChainId,
//   targetAddress: Uint8Array,
//   maybeAdditionalPayload: MaybeAdditionalPayloadFn,
//   relayerFee?: string,
//   onError?: (error: any) => void,
//   onStart?: (extra?: Partial<TelemetryTxEvent>) => void
// ) {
//   dispatch(setIsSending(true));
//   try {
//     const baseAmount = parseUnits(amount, decimals);
//     const baseAmountParsed = baseAmount.toString();
//     const feeParsed = parseUnits(relayerFee || "0", decimals).toString();
//     const transferAmountParsed = baseAmount.add(feeParsed);
//     const tokenBridgeAddress = getTokenBridgeAddressForChain(CHAIN_ID_SEI);

//     const encodedRecipient = Buffer.from(targetAddress).toString("base64");

//     // NOTE: this only supports transferring out via the Sei CW20 <> Bank translator
//     // or the usei native denomination
//     const instructions =
//       asset === SEI_NATIVE_DENOM
//         ? [
//             {
//               contractAddress: tokenBridgeAddress,
//               msg: {
//                 deposit_tokens: {},
//               },
//               funds: [{ denom: asset, amount: baseAmountParsed }],
//             },
//             {
//               contractAddress: tokenBridgeAddress,
//               msg: {
//                 initiate_transfer: {
//                   asset: {
//                     amount: baseAmountParsed,
//                     info: { native_token: { denom: asset } },
//                   },
//                   recipient_chain: targetChain,
//                   recipient: encodedRecipient,
//                   fee: feeParsed,
//                   nonce: Math.floor(Math.random() * 100000),
//                 },
//               },
//             },
//           ]
//         : [
//             {
//               contractAddress: SEI_TRANSLATOR,
//               msg: {
//                 convert_and_transfer: {
//                   recipient_chain: targetChain,
//                   recipient: encodedRecipient,
//                   fee: feeParsed,
//                 },
//               },
//               funds: [
//                 { denom: asset, amount: transferAmountParsed.toString() },
//               ],
//             },
//           ];

//     const fee = await calculateFeeForContractExecution(
//       instructions,
//       wallet,
//       "Wormhole - Complete Transfer"
//     );

//     const tx = await wallet.executeMultiple({
//       instructions,
//       fee,
//       memo: "Wormhole - Initiate Transfer",
//     });

//     if (!tx.data?.height) {
//       console.error("Error: No tx height [sei transfer]");
//       return;
//     }
//     onStart?.({ txId: tx.id });

//     dispatch(setTransferTx({ id: tx.id, block: tx.data.height }));
//     enqueueSnackbar(null, {
//       content: <Alert severity="success">Transaction confirmed</Alert>,
//     });

//     const sequence = parseSequenceFromLogSei(tx.data);
//     if (!sequence) {
//       throw new Error("Sequence not found");
//     }
//     const emitterAddress = await getEmitterAddressTerra(tokenBridgeAddress);
//     await fetchSignedVAA(
//       CHAIN_ID_SEI,
//       emitterAddress,
//       sequence,
//       enqueueSnackbar,
//       dispatch
//     );
//   } catch (e) {
//     console.log(">>>>", e);
//     handleError(e, enqueueSnackbar, dispatch);
//     onError?.(e);
//   }
// }

// async function sui(
//   dispatch: any,
//   enqueueSnackbar: any,
//   wallet: SuiWallet,
//   asset: string,
//   amount: string,
//   decimals: number,
//   targetChain: ChainId,
//   targetAddress: Uint8Array,
//   maybeAdditionalPayload: MaybeAdditionalPayloadFn,
//   relayerFee?: string,
//   onError?: (error: any) => void,
//   onStart?: (extra?: Partial<TelemetryTxEvent>) => void
// ) {
//   dispatch(setIsSending(true));
//   try {
//     const address = wallet.getAddress();
//     if (!address) {
//       throw new Error("No wallet address");
//     }
//     const baseAmountParsed = parseUnits(amount, decimals);
//     const feeParsed = parseUnits(relayerFee || "0", decimals);
//     const transferAmountParsed = baseAmountParsed.add(feeParsed);
//     const additionalPayload = maybeAdditionalPayload();
//     const provider = getSuiProvider();
//     // TODO: handle pagination
//     const coins = (
//       await provider.getCoins({
//         owner: address,
//         coinType: asset,
//       })
//     ).data;
//     const tx = await transferFromSui(
//       provider,
//       getBridgeAddressForChain(CHAIN_ID_SUI),
//       getTokenBridgeAddressForChain(CHAIN_ID_SUI),
//       coins,
//       asset,
//       transferAmountParsed.toBigInt(),
//       targetChain,
//       additionalPayload?.receivingContract || targetAddress,
//       undefined,
//       undefined,
//       additionalPayload?.payload,
//       undefined,
//       undefined,
//       wallet.getAddress()!
//     );
//     const response = (
//       await wallet.signAndSendTransaction({
//         transactionBlock: tx,
//         options: {
//           showEvents: true,
//         },
//       })
//     ).data as SuiTransactionBlockResponse;
//     if (!response) {
//       throw new Error("Error parsing transaction results");
//     }
//     onStart?.({ txId: response.digest });
//     dispatch(
//       setTransferTx({
//         id: response.digest,
//         block: Number(response.checkpoint || 0),
//       })
//     );
//     enqueueSnackbar(null, {
//       content: <Alert severity="success">Transaction confirmed</Alert>,
//     });
//     const coreBridgePackageId = await getOriginalPackageId(
//       provider,
//       getBridgeAddressForChain(CHAIN_ID_SUI)
//     );
//     if (!coreBridgePackageId)
//       throw new Error("Unable to retrieve original package id");
//     const { sequence, emitterAddress } =
//       getEmitterAddressAndSequenceFromResponseSui(
//         coreBridgePackageId,
//         response
//       );
//     await fetchSignedVAA(
//       CHAIN_ID_SUI,
//       emitterAddress,
//       sequence,
//       enqueueSnackbar,
//       dispatch
//     );
//   } catch (e) {
//     handleError(e, enqueueSnackbar, dispatch);
//     onError?.(e);
//   }
// }

export function useHandleTransfer() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const sourceChain = useSelector(selectTransferSourceChain);
  const sourceAsset = useSelector(selectTransferSourceAsset);
  const originChain = useSelector(selectTransferOriginChain);
  const originAsset = useSelector(selectTransferOriginAsset);
  const amount = useSelector(selectTransferAmount);
  const targetChain = useSelector(selectTransferTargetChain);
  const targetAddress = useTransferTargetAddressHex();
  const targetAddressHex = useSelector(selectTransferTargetAddressHex);
  const isTargetComplete = useSelector(selectTransferIsTargetComplete);
  const isSending = useSelector(selectTransferIsSending);
  const isSendComplete = useSelector(selectTransferIsSendComplete);
  const isTBTC = useSelector(selectTransferIsTBTC);
  const { signer } = useEthereumProvider(sourceChain as any);
  const { wallet: solanaWallet, publicKey: solPK } = useSolanaWallet();
  // const { wallet: terraWallet } = useTerraWallet(sourceChain as any);
  // const terraFeeDenom = useSelector(selectTerraFeeDenom);
  // const xplaWallet = useXplaWallet();
  // const { address: algoAccount, wallet: algoWallet } = useAlgorandWallet();
  // const { accountId: nearAccountId, wallet } = useNearContext();
  // const { account: aptosAddress, wallet: aptosWallet } = useAptosContext();
  // const { wallet: injWallet, address: injAddress } = useInjectiveContext();
  // const suiWallet = useSuiWallet();
  // const seiWallet = useSeiWallet();
  // const seiAddress = seiWallet?.getAddress();
  const sourceParsedTokenAccount = useSelector(
    selectTransferSourceParsedTokenAccount
  );
  const relayerFee = useSelector(selectTransferRelayerFee);
  const targetAsset = useSelector(selectTransferTargetAsset);
  const targetParsedTokenAccount = useSelector(
    selectTransferTargetParsedTokenAccount
  );

  const sourceTokenPublicKey = sourceParsedTokenAccount?.publicKey;
  const decimals = sourceParsedTokenAccount?.decimals;
  const isNative = sourceParsedTokenAccount?.isNativeAsset || false;
  const disabled = !isTargetComplete || isSending || isSendComplete;

  const maybeAdditionalPayload: MaybeAdditionalPayloadFn = useCallback(() => {
    if (
      isTBTC &&
      originChain === CHAIN_ID_ETH &&
      THRESHOLD_GATEWAYS[targetChain] &&
      targetAddress
    ) {
      const tbtcGateway = tryNativeToUint8Array(
        THRESHOLD_GATEWAYS[targetChain],
        targetChain
      );
      return {
        receivingContract: tbtcGateway,
        payload: targetAddress,
      };
    }

    // assets original from Sei (native denomination or native CW20s)
    // should go through the normal process
    // if (
    //   targetChain === CHAIN_ID_SEI &&
    //   targetAddress &&
    //   originChain !== CHAIN_ID_SEI
    // ) {
    //   return {
    //     receivingContract: SEI_TRANSLATER_TARGET,
    //     payload: new Uint8Array(
    //       Buffer.from(
    //         JSON.stringify({
    //           basic_recipient: {
    //             recipient: Buffer.from(
    //               // Sei wallet addresses are 20 bytes
    //               cosmos.humanAddress("sei", targetAddress.slice(12))
    //             ).toString("base64"),
    //           },
    //         })
    //       )
    //     ),
    //   };
    // }

    return null;
  }, [isTBTC, originChain, targetAddress, targetChain]);

  const handleTransferClick = useCallback(() => {
    const emit = (wallet: string, txId?: string) => {
      mixpanel.track(`Transfer Txn ${txId ? "" : "Rejected"}`, {
        Source: CHAINS_RECORD[sourceChain].name,
        Target: CHAINS_RECORD[targetChain].name,
        "Target Asset": targetAsset,
        "Target Symbol": targetParsedTokenAccount?.symbol,
        "Target Name": targetParsedTokenAccount?.name,
        "Source Asset": sourceAsset,
        "Soucre Symbol": sourceParsedTokenAccount?.symbol,
        "Soucre Name": sourceParsedTokenAccount?.name,
        Amount: amount,
        Decimals: decimals,
        Wallet: wallet,
        "Target Wallet": hexToNativeString(targetAddressHex, targetChain) || "",
        isNative,
        "Txn Hash": txId,
      });
    };

    // const telemetryProps: TelemetryTxEvent = {
    //   fromChainId: sourceChain,
    //   toChainId: targetChain,
    //   fromTokenSymbol: sourceParsedTokenAccount?.symbol,
    //   toTokenSymbol: targetParsedTokenAccount?.symbol,
    //   fromTokenAddress: isNative ? "native" : sourceAsset,
    //   toTokenAddress: targetParsedTokenAccount?.isNativeAsset
    //     ? "native"
    //     : targetAddressHex,
    //   amount: Number(amount),
    // };
    // telemetry.on.transferInit(telemetryProps);

    const onError = (error: any) => {
      // telemetry.on.error({ ...telemetryProps, error });
    };

    const onStart = (extra?: Partial<any>) => {
      // telemetry.on.transferStart({ ...telemetryProps, ...extra });
    };

    // TODO: we should separate state for transaction vs fetching vaa

    if (
      isEVMChain(sourceChain) &&
      !!signer &&
      !!sourceAsset &&
      decimals !== undefined &&
      !!targetAddress
    ) {
      evm(
        dispatch,
        enqueueSnackbar,
        signer,
        sourceAsset,
        decimals,
        amount,
        targetChain,
        targetAddress,
        isNative,
        sourceChain,
        isTBTC,
        maybeAdditionalPayload,
        emit,
        relayerFee,
        onError,
        onStart
      );
    } else if (
      sourceChain === CHAIN_ID_SOLANA &&
      !!solanaWallet &&
      !!solPK &&
      !!sourceAsset &&
      !!sourceTokenPublicKey &&
      !!targetAddress &&
      decimals !== undefined
    ) {
      solana(
        dispatch,
        enqueueSnackbar,
        solanaWallet,
        solPK,
        sourceTokenPublicKey,
        sourceAsset,
        amount,
        decimals,
        targetChain,
        targetAddress,
        isNative,
        maybeAdditionalPayload,
        isTBTC,
        emit,
        originAsset,
        originChain,
        relayerFee,
        onError,
        onStart
      );
    }
    // else if (
    //   isTerraChain(sourceChain) &&
    //   !!terraWallet &&
    //   !!sourceAsset &&
    //   decimals !== undefined &&
    //   !!targetAddress
    // ) {
    //   terra(
    //     dispatch,
    //     enqueueSnackbar,
    //     terraWallet,
    //     sourceAsset,
    //     amount,
    //     decimals,
    //     targetChain,
    //     targetAddress,
    //     terraFeeDenom,
    //     sourceChain,
    //     maybeAdditionalPayload,
    //     relayerFee,
    //     onError,
    //     onStart
    //   );
    // } else if (
    //   sourceChain === CHAIN_ID_SEI &&
    //   seiWallet &&
    //   seiAddress &&
    //   !!sourceAsset &&
    //   decimals !== undefined &&
    //   !!targetAddress
    // ) {
    //   sei(
    //     dispatch,
    //     enqueueSnackbar,
    //     seiWallet,
    //     sourceAsset,
    //     amount,
    //     decimals,
    //     targetChain,
    //     targetAddress,
    //     maybeAdditionalPayload,
    //     relayerFee,
    //     onError,
    //     onStart
    //   );
    // } else if (
    //   sourceChain === CHAIN_ID_XPLA &&
    //   !!xplaWallet &&
    //   !!sourceAsset &&
    //   decimals !== undefined &&
    //   !!targetAddress
    // ) {
    //   xpla(
    //     dispatch,
    //     enqueueSnackbar,
    //     xplaWallet,
    //     sourceAsset,
    //     amount,
    //     decimals,
    //     targetChain,
    //     targetAddress,
    //     maybeAdditionalPayload,
    //     relayerFee,
    //     onError,
    //     onStart
    //   );
    // } else if (
    //   sourceChain === CHAIN_ID_ALGORAND &&
    //   algoAccount &&
    //   !!sourceAsset &&
    //   decimals !== undefined &&
    //   !!targetAddress
    // ) {
    //   algo(
    //     dispatch,
    //     enqueueSnackbar,
    //     algoWallet,
    //     sourceAsset,
    //     decimals,
    //     amount,
    //     targetChain,
    //     targetAddress,
    //     sourceChain,
    //     maybeAdditionalPayload,
    //     relayerFee,
    //     onError,
    //     onStart
    //   );
    // } else if (
    //   sourceChain === CHAIN_ID_NEAR &&
    //   nearAccountId &&
    //   wallet &&
    //   !!sourceAsset &&
    //   decimals !== undefined &&
    //   !!targetAddress
    // ) {
    //   near(
    //     dispatch,
    //     enqueueSnackbar,
    //     wallet,
    //     nearAccountId,
    //     sourceAsset,
    //     decimals,
    //     amount,
    //     targetChain,
    //     targetAddress,
    //     sourceChain,
    //     maybeAdditionalPayload,
    //     relayerFee,
    //     onError,
    //     onStart
    //   );
    // } else if (
    //   sourceChain === CHAIN_ID_APTOS &&
    //   aptosAddress &&
    //   !!sourceAsset &&
    //   decimals !== undefined &&
    //   !!targetAddress
    // ) {
    //   aptos(
    //     dispatch,
    //     enqueueSnackbar,
    //     sourceAsset,
    //     decimals,
    //     amount,
    //     targetChain,
    //     targetAddress,
    //     sourceChain,
    //     aptosWallet!,
    //     maybeAdditionalPayload,
    //     relayerFee,
    //     onError,
    //     onStart
    //   );
    // } else if (
    //   sourceChain === CHAIN_ID_INJECTIVE &&
    //   injWallet &&
    //   injAddress &&
    //   !!sourceAsset &&
    //   decimals !== undefined &&
    //   !!targetAddress
    // ) {
    //   injective(
    //     dispatch,
    //     enqueueSnackbar,
    //     injWallet,
    //     injAddress,
    //     sourceAsset,
    //     amount,
    //     decimals,
    //     targetChain,
    //     targetAddress,
    //     maybeAdditionalPayload,
    //     relayerFee,
    //     onError,
    //     onStart
    //   );
    // } else if (
    //   sourceChain === CHAIN_ID_SUI &&
    //   suiWallet?.isConnected() &&
    //   suiWallet.getAddress() &&
    //   !!sourceAsset &&
    //   decimals !== undefined &&
    //   !!targetAddress
    // ) {
    //   sui(
    //     dispatch,
    //     enqueueSnackbar,
    //     suiWallet,
    //     sourceAsset,
    //     amount,
    //     decimals,
    //     targetChain,
    //     targetAddress,
    //     maybeAdditionalPayload,
    //     relayerFee,
    //     onError,
    //     onStart
    //   );
    // }
  }, [
    sourceChain,
    targetChain,
    sourceParsedTokenAccount?.symbol,
    targetParsedTokenAccount?.symbol,
    targetParsedTokenAccount?.isNativeAsset,
    isNative,
    sourceAsset,
    targetAddressHex,
    amount,
    signer,
    decimals,
    targetAddress,
    solanaWallet,
    solPK,
    sourceTokenPublicKey,
    // terraWallet,
    // seiWallet,
    // seiAddress,
    // xplaWallet,
    // algoAccount,
    // nearAccountId,
    // wallet,
    // aptosAddress,
    // injWallet,
    // injAddress,
    // suiWallet,
    dispatch,
    enqueueSnackbar,
    isTBTC,
    maybeAdditionalPayload,
    relayerFee,
    originAsset,
    originChain,
    // terraFeeDenom,
    // algoWallet,
    // aptosWallet,
  ]);
  return useMemo(
    () => ({
      handleClick: handleTransferClick,
      disabled,
      showLoader: isSending,
    }),
    [handleTransferClick, disabled, isSending]
  );
}
