import { isEVMChain, ChainId } from "@certusone/wormhole-sdk";
import { EVMWallet } from "@xlabs-libs/wallet-aggregator-evm";
import { useWallet } from "@xlabs-libs/wallet-aggregator-react";
import { Button, makeStyles } from "@material-ui/core";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { useEthereumProvider } from "../../contexts/EthereumProviderContext";
import { selectTransferTargetAsset } from "../../store/selectors";
import {
  ethTokenToParsedTokenAccount,
  getEthereumToken,
} from "../../utils/ethereum";

const useStyles = makeStyles((theme) => ({
  addButton: {
    display: "block",
    borderRadius: "12px",
    margin: "10px auto 0 auto",
  },
}));

export default function AddToMetamask({ chainId }: { chainId: ChainId }) {
  const classes = useStyles();
  const targetAsset = useSelector(selectTransferTargetAsset);

  const wallet = useWallet(chainId);
  const { provider, signerAddress } = useEthereumProvider(chainId);

  const isEvmWallet = useMemo<boolean>(() => {
    const w = wallet as EVMWallet;
    return (
      Boolean(w && w.watchAsset) && isEVMChain(chainId) && Boolean(targetAsset)
    );
  }, [wallet, chainId, targetAsset]);

  const handleClick = useCallback(async () => {
    try {
      if (targetAsset && wallet) {
        if (provider && signerAddress) {
          const token = await getEthereumToken(targetAsset, provider);
          const { symbol, decimals } = await ethTokenToParsedTokenAccount(
            token,
            signerAddress
          );
          await (wallet as EVMWallet).watchAsset({
            type: "ERC20",
            options: {
              address: targetAsset,
              symbol: symbol || "wh",
              decimals: decimals,
            },
          });
        }
      }
    } catch {}
  }, [targetAsset, wallet, provider, signerAddress]);

  return isEvmWallet && provider && signerAddress ? (
    <Button
      onClick={handleClick}
      size="small"
      variant="outlined"
      className={classes.addButton}
    >
      {`Add to ${wallet?.getName() || "wallet"}`}
    </Button>
  ) : null;
}
