import { isEVMChain, ChainId } from "@certusone/wormhole-sdk";
import { makeStyles, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useWallet } from "@xlabs-libs/wallet-aggregator-react";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { GasEstimateSummary } from "../../hooks/useTransactionFees";
import { incrementStep, setTargetChain } from "../../store/attestSlice";
import {
  selectAttestIsTargetComplete,
  selectAttestShouldLockFields,
  selectAttestSourceChain,
  selectAttestTargetChain,
} from "../../store/selectors";
import { ATTEST_CHAINS, CHAINS_BY_ID } from "../../utils/consts";
import ButtonWithLoader from "../ButtonWithLoader";
import ChainSelect from "../ChainSelect";
import KeyAndBalance from "../KeyAndBalance";
import LowBalanceWarning from "../LowBalanceWarning";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: "10px",
    borderRadius: "12px",
  },
}));

function Target() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sourceChain = useSelector(selectAttestSourceChain);
  const chains = useMemo(
    () => ATTEST_CHAINS.filter((c) => c.id !== sourceChain),
    [sourceChain]
  );
  const targetChain = useSelector(selectAttestTargetChain);
  const isTargetComplete = useSelector(selectAttestIsTargetComplete);
  const shouldLockFields = useSelector(selectAttestShouldLockFields);
  const wallet = useWallet(targetChain);

  const handleTargetChange = useCallback(
    (chain: ChainId) => {
      dispatch(setTargetChain(chain));
    },
    [dispatch]
  );

  const handleNextClick = useCallback(() => {
    dispatch(incrementStep());
  }, [dispatch]);

  return (
    <>
      <ChainSelect
        label="Target Chain"
        value={targetChain}
        onChange={handleTargetChange}
        disabled={shouldLockFields}
        chains={chains}
      />

      {wallet && (
        <Alert severity="info" variant="outlined" className={classes.alert}>
          <Typography>
            You will have to pay transaction fees on{" "}
            {CHAINS_BY_ID[targetChain].name} to attest this token.{" "}
          </Typography>
          {isEVMChain(targetChain) && (
            <GasEstimateSummary
              methodType="createWrapped"
              chainId={targetChain}
            />
          )}
        </Alert>
      )}

      <LowBalanceWarning chainId={targetChain} />

      <KeyAndBalance chainId={targetChain} />

      {wallet && (
        <ButtonWithLoader
          disabled={!isTargetComplete}
          onClick={handleNextClick}
          showLoader={false}
        >
          Next
        </ButtonWithLoader>
      )}
    </>
  );
}

export default Target;
