import { Divider, Box } from "@material-ui/core";
import { Wallet, WalletState } from "@xlabs-libs/wallet-aggregator-core";
import { useMemo } from "react";

import CustomDialog from "./CustomDialog";
import DialogList from "./DialogList";

const ConnectWalletDialog = ({
  isOpen,
  onSelect,
  onClose,
  wallets,
}: {
  isOpen: boolean;
  onSelect: (w: Wallet) => Promise<void>;
  onClose: () => void;
  wallets: Wallet[];
}) => {
  const [detected, undetected] = useMemo(() => {
    const detected: Wallet[] = [];
    const undetected: Wallet[] = [];
    for (const wallet of wallets) {
      if (
        wallet.getWalletState() === WalletState.Installed ||
        wallet.getWalletState() === WalletState.Loadable
      ) {
        detected.push(wallet);
      } else if (wallet.getWalletState() === WalletState.NotDetected) {
        undetected.push(wallet);
      }
    }
    return [detected, undetected];
  }, [wallets]);

  const formattedDetectedWallets = useMemo(
    () =>
      detected.map((wallet) => ({
        icon: wallet.getIcon(),
        title: wallet.getName(),
        onSelect: () => {
          onSelect(wallet).then(onClose);
        },
      })),
    [detected, onSelect, onClose]
  );

  const formattedUnDetectedWallets = useMemo(
    () =>
      undetected.map((wallet) => ({
        icon: wallet.getIcon(),
        title: "Install " + wallet.getName(),
        onSelect: () => {
          window.open(wallet.getUrl(), "_blank");
        },
      })),
    [undetected]
  );

  return (
    <CustomDialog
      open={isOpen}
      handleClose={onClose}
      title="Select Wallet"
      content={
        <Box style={{ width: "100%" }}>
          <DialogList items={formattedDetectedWallets} />
          {!!detected.length && !!undetected.length ? (
            <Divider variant="middle" />
          ) : (
            <></>
          )}
          <DialogList items={formattedUnDetectedWallets} />
        </Box>
      }
    />
  );
};

export default ConnectWalletDialog;
