import { Box, TextField, makeStyles, TextFieldProps } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";

type CustomTextFieldProps = {
  className?: string;
  hideIcon?: boolean;
} & TextFieldProps;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "12px",
    margin: "10px 15px",
    padding: "0 10px",
    border: "1px solid",
    borderColor: theme.palette.divider,
  },
  field: {
    margin: "5px 0",
    width: "100%",
    padding: "0 8px",
  },
  icon: {
    color: theme.palette.secondary.light,
  },
}));

export default function CustomTextField({
  placeholder,
  value,
  onChange,
  hideIcon,
  className,
}: CustomTextFieldProps) {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.root, className ? className : "")}>
      {!hideIcon ? <SearchIcon className={classes.icon} /> : null}
      <TextField
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        fullWidth
        InputProps={{ disableUnderline: true }}
        className={classes.field}
      />
    </Box>
  );
}
