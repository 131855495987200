import {
  CHAIN_ID_SOLANA,
  isEVMChain,
  uint8ArrayToHex,
} from "@certusone/wormhole-sdk";
import { arrayify, zeroPad } from "@ethersproject/bytes";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  getAssociatedTokenAddress,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEthereumProvider } from "../contexts/EthereumProviderContext";
import { useSolanaWallet } from "../contexts/SolanaWalletContext";
import {
  selectTransferIsTBTC,
  selectTransferTargetAsset,
  selectTransferTargetChain,
  selectTransferTargetParsedTokenAccount,
} from "../store/selectors";
import { setTargetAddressHex as setTransferTargetAddressHex } from "../store/transferSlice";

function useSyncTargetAddress(shouldFire: boolean, nft?: boolean) {
  const dispatch = useDispatch();
  // const targetChain = useSelector(
  //   nft ? selectNFTTargetChain : selectTransferTargetChain
  // );
  const targetChain = useSelector(selectTransferTargetChain);
  const { signerAddress } = useEthereumProvider(targetChain as any);
  const { publicKey: solPK } = useSolanaWallet();
  // const targetAsset = useSelector(
  //   nft ? selectNFTTargetAsset : selectTransferTargetAsset
  // );
  const targetAsset = useSelector(selectTransferTargetAsset);
  const targetParsedTokenAccount = useSelector(
    selectTransferTargetParsedTokenAccount
  );
  const targetTokenAccountPublicKey = targetParsedTokenAccount?.publicKey;
  // const terraWallet = useTerraWallet(targetChain as any);
  // const xplaWallet = useXplaWallet();
  // const { address: algoAccount } = useAlgorandWallet();
  // const { account: aptosAddress } = useAptosContext();
  // const { accountId: nearAccountId, wallet } = useNearContext();
  // const { address: injAddress } = useInjectiveContext();
  const isTBTC = useSelector(selectTransferIsTBTC);
  // const suiWallet = useSuiWallet();
  // const suiAddress = suiWallet?.getAddress();
  // const seiWallet = useSeiWallet();
  // const seiAddress = seiWallet?.getAddress();

  // const setTargetAddressHex = nft
  //   ? setNFTTargetAddressHex
  //   : setTransferTargetAddressHex;
  const setTargetAddressHex = setTransferTargetAddressHex;
  useEffect(() => {
    if (shouldFire) {
      let cancelled = false;
      if (isEVMChain(targetChain) && signerAddress) {
        dispatch(
          setTargetAddressHex(
            uint8ArrayToHex(zeroPad(arrayify(signerAddress), 32))
          )
        );
      }
      // TODO: have the user explicitly select an account on solana
      else if (!nft && solPK && targetChain === CHAIN_ID_SOLANA && isTBTC) {
        dispatch(
          setTargetAddressHex(
            uint8ArrayToHex(zeroPad(new PublicKey(solPK).toBytes(), 32))
          )
        );
      } else if (
        !nft && // only support existing, non-derived token accounts for token transfers (nft flow doesn't check balance)
        targetChain === CHAIN_ID_SOLANA &&
        targetTokenAccountPublicKey
      ) {
        // use the target's TokenAccount if it exists
        dispatch(
          setTargetAddressHex(
            uint8ArrayToHex(
              zeroPad(new PublicKey(targetTokenAccountPublicKey).toBytes(), 32)
            )
          )
        );
      } else if (targetChain === CHAIN_ID_SOLANA && solPK && targetAsset) {
        // otherwise, use the associated token account (which we create in the case it doesn't exist)
        (async () => {
          try {
            const associatedTokenAccount = await getAssociatedTokenAddress(
              new PublicKey(targetAsset), // this might error
              new PublicKey(solPK),
              undefined,
              TOKEN_PROGRAM_ID,
              ASSOCIATED_TOKEN_PROGRAM_ID
            );
            if (!cancelled) {
              dispatch(
                setTargetAddressHex(
                  uint8ArrayToHex(zeroPad(associatedTokenAccount.toBytes(), 32))
                )
              );
            }
          } catch (e) {
            if (!cancelled) {
              dispatch(setTargetAddressHex(undefined));
            }
          }
        })();
      }
      // else if (
      //   isTerraChain(targetChain) &&
      //   terraWallet &&
      //   terraWallet.walletAddress
      // ) {
      //   dispatch(
      //     setTargetAddressHex(
      //       uint8ArrayToHex(
      //         zeroPad(cosmos.canonicalAddress(terraWallet.walletAddress), 32)
      //       )
      //     )
      //   );
      // } else if (
      //   targetChain === CHAIN_ID_XPLA &&
      //   xplaWallet &&
      //   xplaWallet.getAddress()
      // ) {
      //   dispatch(
      //     setTargetAddressHex(
      //       uint8ArrayToHex(
      //         zeroPad(cosmos.canonicalAddress(xplaWallet.getAddress()!), 32)
      //       )
      //     )
      //   );
      // } else if (targetChain === CHAIN_ID_APTOS && aptosAddress) {
      //   dispatch(
      //     setTargetAddressHex(uint8ArrayToHex(zeroPad(aptosAddress, 32)))
      //   );
      // } else if (targetChain === CHAIN_ID_ALGORAND && algoAccount) {
      //   dispatch(
      //     setTargetAddressHex(
      //       uint8ArrayToHex(decodeAddress(algoAccount).publicKey)
      //     )
      //   );
      // } else if (targetChain === CHAIN_ID_INJECTIVE && injAddress) {
      //   dispatch(
      //     setTargetAddressHex(
      //       uint8ArrayToHex(zeroPad(cosmos.canonicalAddress(injAddress), 32))
      //     )
      //   );
      // } else if (targetChain === CHAIN_ID_NEAR && nearAccountId && wallet) {
      //   (async () => {
      //     try {
      //       const account = await makeNearAccount(nearAccountId);
      //       // So, near can have account names up to 64 bytes but wormhole can only have 32...
      //       //   as a result, we have to hash our account names to sha256's..  What we are doing
      //       //   here is doing a RPC call (does not require any interaction with the wallet and is free)
      //       //   that both tells us our account hash AND if we are already registered...
      //       let account_hash = await account.viewFunction(
      //         NEAR_TOKEN_BRIDGE_ACCOUNT,
      //         "hash_account",
      //         {
      //           account: nearAccountId,
      //         }
      //       );
      //       if (!cancelled) {
      //         let myAddress = account_hash[1];
      //         console.log("account hash for", nearAccountId, account_hash);

      //         if (!account_hash[0]) {
      //           console.log("Registering the receiving account");

      //           let myAddress2 = getTransactionLastResult(
      //             await signAndSendTransactions(account, wallet, [
      //               {
      //                 contractId: NEAR_TOKEN_BRIDGE_ACCOUNT,
      //                 methodName: "register_account",
      //                 args: { account: nearAccountId },
      //                 gas: new BN("100000000000000"),
      //                 attachedDeposit: new BN("2000000000000000000000"), // 0.002 NEAR
      //               },
      //             ])
      //           );

      //           console.log("account hash returned: " + myAddress2);
      //         } else {
      //           console.log("account already registered");
      //         }
      //         if (!cancelled) {
      //           dispatch(setTargetAddressHex(myAddress));
      //         }
      //       }
      //     } catch (e) {
      //       console.log(e);
      //       if (!cancelled) {
      //         dispatch(setTargetAddressHex(undefined));
      //       }
      //     }
      //   })();
      // } else if (targetChain === CHAIN_ID_SUI && suiAddress) {
      //   dispatch(setTargetAddressHex(uint8ArrayToHex(zeroPad(suiAddress, 32))));
      // } else if (targetChain === CHAIN_ID_SEI && seiAddress) {
      //   dispatch(
      //     setTargetAddressHex(
      //       uint8ArrayToHex(zeroPad(cosmos.canonicalAddress(seiAddress), 32))
      //     )
      //   );
      // }
      else {
        dispatch(setTargetAddressHex(undefined));
      }
      return () => {
        cancelled = true;
      };
    }
  }, [
    dispatch,
    shouldFire,
    targetChain,
    signerAddress,
    solPK,
    targetAsset,
    targetTokenAccountPublicKey,
    // terraWallet,
    nft,
    setTargetAddressHex,
    // algoAccount,
    // nearAccountId,
    // wallet,
    // xplaWallet,
    // aptosAddress,
    // injAddress,
    // suiAddress,
    isTBTC,
    // seiAddress,
  ]);
}

export default useSyncTargetAddress;
