import { makeStyles, Box, Typography, Divider } from "@material-ui/core";

interface AddressTableDataType {
  title: string;
  address: React.ReactNode;
  info: string;
}

const useStyles = makeStyles((theme) => ({
  table: {
    padding: "5px 20px",
    border: "1px solid",
    borderColor: theme.palette.divider,
    borderRadius: "12px",
    backgroundColor: theme.palette.secondary.main,
    width: "100%",
    marginTop: "10px",
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0",
  },
  otherInfo: {
    textAlign: "end",
  },
  secondaryText: {
    color: theme.palette.secondary.light,
  },
}));

export default function AddressTable({
  rows,
}: {
  rows: Array<AddressTableDataType | null>;
}) {
  const classes = useStyles();

  return (
    <Box className={classes.table}>
      {rows.map(
        (item, index) =>
          item && (
            <>
              <Box className={classes.row}>
                <Box>
                  <Typography variant="body1">{item.title}</Typography>
                </Box>
                <Box>
                  {item.address}
                  <Typography className={classes.secondaryText} variant="body2">
                    {item.info}
                  </Typography>
                </Box>
              </Box>
              {index === 0 && rows.length > 1 && <Divider />}
            </>
          )
      )}
    </Box>
  );
}
