import { useCallback, useMemo } from "react";

import DefualtFallback from "../icons/logo_mob.png";

export default function CustomIcon({
  alt,
  className,
  src,
  fallBack,
}: {
  alt?: string;
  className?: string;
  src?: string;
  fallBack?: string;
}) {
  const fallbackImg = useMemo<string>(
    () => fallBack ?? DefualtFallback,
    [fallBack]
  );

  const onError = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
      event.currentTarget.src = fallbackImg;
    },
    [fallbackImg]
  );

  return (
    <img
      src={src ?? fallbackImg}
      className={className}
      alt={alt}
      onError={onError}
    />
  );
}
