import { ChainId } from "@certusone/wormhole-sdk";
import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { CHAINS_BY_ID } from "../../utils/consts";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: "10px",
    borderRadius: "12px",
  },
}));

const PendingVAAWarning = ({ sourceChain }: { sourceChain: ChainId }) => {
  const classes = useStyles();
  const chainName = CHAINS_BY_ID[sourceChain]?.name || "unknown";
  const message = `The daily notional value limit for transfers on ${chainName} has been exceeded. As
      a result, the VAA for this transfer is pending.`;
  return (
    <Alert variant="outlined" severity="warning" className={classes.alert}>
      {message}
    </Alert>
  );
};

export default PendingVAAWarning;
