import { CHAIN_ID_SOLANA, isEVMChain } from "@certusone/wormhole-sdk";
import {
  Button,
  DialogActions,
  Typography,
  Box,
  makeStyles,
} from "@material-ui/core";
import { ArrowDownward } from "@material-ui/icons";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  selectTransferOriginChain,
  selectTransferSourceChain,
  selectTransferSourceParsedTokenAccount,
  selectTransferIsTBTC,
} from "../../store/selectors";
import {
  CHAINS_BY_ID,
  CLUSTER,
  MULTI_CHAIN_TOKENS,
  THRESHOLD_TBTC_CONTRACTS,
} from "../../utils/consts";
import SmartAddress from "../SmartAddress";
import SolanaTPSWarning from "../SolanaTPSWarning";
import { useTargetInfo } from "./Target";
import TokenWarning from "./TokenWarning";
import CustomDialog from "../CustomDialog";

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    width: "100%",
    marginTop: "10px",
    marginBottom: "20px",
    padding: "0 20px",
  },
  button: {
    width: "100%",
    borderRadius: "10px",
  },
  dc: {
    width: "100%",
    minWidth: "400px",
    "@media (max-width:550px)": {
      width: "100%",
      minWidth: "auto",
    },
  },
}));

function SendConfirmationContent({
  open,
  onClose,
  onClick,
}: {
  open: boolean;
  onClose: () => void;
  onClick: () => void;
}) {
  const classes = useStyles();

  const isTBTC = useSelector(selectTransferIsTBTC);
  const sourceChain = useSelector(selectTransferSourceChain);
  const sourceParsedTokenAccount = useSelector(
    selectTransferSourceParsedTokenAccount
  );
  const { targetChain, targetAsset, symbol, tokenName, logo } = useTargetInfo();
  const originChain = useSelector(selectTransferOriginChain);

  //TODO this check is essentially duplicated.
  const deservesTimeout = useMemo(() => {
    if (originChain && sourceParsedTokenAccount?.mintKey) {
      const searchableAddress = isEVMChain(originChain)
        ? sourceParsedTokenAccount.mintKey.toLowerCase()
        : sourceParsedTokenAccount.mintKey;
      return (
        originChain !== targetChain &&
        !!MULTI_CHAIN_TOKENS[sourceChain]?.[searchableAddress]
      );
    } else {
      return false;
    }
  }, [originChain, targetChain, sourceChain, sourceParsedTokenAccount]);
  const timeoutDuration = 5;

  const [countdown, setCountdown] = useState(
    deservesTimeout ? timeoutDuration : 0
  );

  useEffect(() => {
    if (!deservesTimeout || countdown === 0) {
      return;
    }
    let cancelled = false;

    setInterval(() => {
      if (!cancelled) {
        setCountdown((state) => state - 1);
      }
    }, 1000);

    return () => {
      cancelled = true;
    };
  }, [deservesTimeout, countdown]);

  useEffect(() => {
    if (open && deservesTimeout) {
      //Countdown starts on mount, but we actually want it to start on open
      setCountdown(timeoutDuration);
    }
  }, [open, deservesTimeout]);

  const sendConfirmationContent = (
    <>
      <>
        {targetAsset ? (
          <div style={{ textAlign: "center", marginBottom: 16 }}>
            <Typography variant="subtitle1" style={{ marginBottom: 8 }}>
              You are about to perform this transfer:
            </Typography>
            <SmartAddress
              variant="h6"
              chainId={sourceChain}
              parsedTokenAccount={sourceParsedTokenAccount}
              isAsset
            />
            <div>
              <Typography variant="caption">
                {CHAINS_BY_ID[sourceChain].name}
              </Typography>
            </div>
            <div style={{ paddingTop: 4 }}>
              <ArrowDownward fontSize="inherit" />
            </div>
            <SmartAddress
              variant="h6"
              chainId={targetChain}
              address={targetAsset}
              symbol={symbol}
              tokenName={tokenName}
              logo={logo}
              isAsset
            />
            <div>
              <Typography variant="caption">
                {CHAINS_BY_ID[targetChain].name}
              </Typography>
            </div>
          </div>
        ) : null}

        <Box style={{ width: "80%", margin: "0 auto" }}>
          <TokenWarning
            sourceAsset={sourceParsedTokenAccount?.mintKey}
            sourceChain={sourceChain}
            originChain={originChain}
            targetAsset={targetAsset ?? undefined}
            targetChain={targetChain}
            showCanonicalTbtcMessage={
              isTBTC && THRESHOLD_TBTC_CONTRACTS[targetChain]
            }
          />
        </Box>

        {sourceChain === CHAIN_ID_SOLANA && CLUSTER === "mainnet" && (
          <SolanaTPSWarning />
        )}
      </>

      <DialogActions className={classes.buttonsContainer}>
        <Button className={classes.button} variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={onClick}
          size={"medium"}
          disabled={!!countdown}
        >
          {!!countdown ? countdown.toString() : "Confirm"}
        </Button>
      </DialogActions>
    </>
  );

  return sendConfirmationContent;
}

export default function SendConfirmationDialog({
  open,
  onClick,
  onClose,
}: {
  open: boolean;
  onClick: () => void;
  onClose: () => void;
}) {
  const classes = useStyles();
  return (
    <CustomDialog
      open={open}
      handleClose={onClose}
      title="Confirmation"
      content={
        <Box className={classes.dc}>
          <SendConfirmationContent
            open={open}
            onClose={onClose}
            onClick={onClick}
          />
        </Box>
      }
    />
  );
}
