import { Button, makeStyles } from "@material-ui/core";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setSourceAsset,
  setSourceChain,
  setStep,
  setTargetChain,
} from "../../store/attestSlice";
import {
  selectAttestSignedVAAHex,
  selectTransferOriginAsset,
  selectTransferOriginChain,
  selectTransferSourceAsset,
  selectTransferTargetChain,
} from "../../store/selectors";
import { ChainId, hexToNativeAssetString } from "@certusone/wormhole-sdk";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
    borderRadius: "12px",
    height: "50px",
    fontSize: "18px",
    marginTop: "10px",
  },
}));

export function RegisterNowButtonCore({
  originChain,
  originAsset,
  targetChain,
  forceAsset,
}: {
  originChain: ChainId | undefined;
  originAsset: string | undefined;
  targetChain: ChainId;
  forceAsset?: string;
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  // user might be in the middle of a different attest
  const signedVAAHex = useSelector(selectAttestSignedVAAHex);
  const sourceAsset = useSelector(selectTransferSourceAsset);
  const canSwitch = originChain && originAsset && !signedVAAHex;
  const handleClick = useCallback(() => {
    // const nativeAsset = originChain
    //   ? originChain === CHAIN_ID_TERRA2 ||
    //     originChain === CHAIN_ID_NEAR ||
    //     originChain === CHAIN_ID_XPLA ||
    //     originChain === CHAIN_ID_APTOS ||
    //     originChain === CHAIN_ID_INJECTIVE ||
    //     originChain === CHAIN_ID_SUI ||
    //     originChain === CHAIN_ID_SEI
    //     ? sourceAsset || forceAsset
    //     : hexToNativeAssetString(originAsset, originChain)
    //   : undefined;
    const nativeAsset = originChain
      ? hexToNativeAssetString(originAsset, originChain)
      : undefined;
    if (originChain && originAsset && nativeAsset && canSwitch) {
      dispatch(setSourceChain(originChain));
      dispatch(setSourceAsset(nativeAsset));
      dispatch(setTargetChain(targetChain));
      dispatch(setStep(2));
      history.push("/register");
    }
  }, [
    dispatch,
    canSwitch,
    originChain,
    originAsset,
    targetChain,
    history,
    sourceAsset,
    forceAsset,
  ]);
  if (!canSwitch) return null;
  return (
    <Button
      className={classes.button}
      color="primary"
      variant="contained"
      onClick={handleClick}
    >
      Register Now
    </Button>
  );
}

export default function RegisterNowButton() {
  const originChain = useSelector(selectTransferOriginChain);
  const originAsset = useSelector(selectTransferOriginAsset);
  const targetChain = useSelector(selectTransferTargetChain);
  return (
    <RegisterNowButtonCore
      originChain={originChain}
      originAsset={originAsset}
      targetChain={targetChain}
    />
  );
}
