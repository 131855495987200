import { createTheme, responsiveFontSizes } from "@material-ui/core";

export const COLORS = {
  gray: "#9b9b9b",
  nearBlack: "#05061A",
  red: "#aa0818",
  white: "#FFFFFF",
  whiteWithTransparency: "rgba(255,255,255,.07)",
  orange: "#F0932A",

  themePrimary: "#1FC7D4",
  themePrimary2: "#B8ADD2",
  themeSecondary: "rgb(30, 41, 60)",
  themeSecondaryLight: "rgb(148, 163, 184)",
  darkBackground: "#27262C",
};

const suisse = {
  fontFamily: '"Kanit", Sans-serif',
  // fontStyle: "normal",
  // fontDisplay: "swap",
  // fontWeight: 400,
};

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      type: "dark",
      background: {
        default: COLORS.nearBlack,
        paper: COLORS.nearBlack,
      },
      divider: COLORS.whiteWithTransparency,
      text: {
        primary: COLORS.white,
        secondary: COLORS.gray,
      },
      primary: {
        main: COLORS.themePrimary,
        light: COLORS.themePrimary2,
      },
      secondary: {
        main: COLORS.themeSecondary,
        light: COLORS.themeSecondaryLight,
      },
      error: {
        main: COLORS.red,
      },
      warning: {
        main: COLORS.orange,
      },
      border: {
        lightBorder: COLORS.whiteWithTransparency,
      },
    },

    typography: {
      fontFamily: "Kanit, sans-serif",
      fontSize: 13,
      // fontWeight: 600,
      h1: {
        fontFamily: "Kanit, sans-serif",
        fontWeight: 600,
      },
      h2: {
        fontWeight: 600,
      },
      h4: {
        fontWeight: 600,
      },
      h5: {
        fontWeight: 600,
      },
      h6: {
        fontWeight: 600,
      },
      // body1: {
      //   fontWeight: 600,
      // },
      // body2: {
      //   fontWeight: 600,
      // },
      // subtitle1: {
      //   fontWeight: 600,
      // },
      // subtitle2: {
      //   fontWeight: 600,
      // },
    },
    zIndex: {
      modal: 50,
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          // "@font-face": [suisse],
          body: {
            overscrollBehaviorY: "none",
            // fontWeight: 600,
          },
          "*": {
            scrollbarWidth: "thin",
            scrollbarColor: `${COLORS.gray} ${COLORS.nearBlackWithMinorTransparency}`,
            // fontWeight: 600,
          },
          "*::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
            backgroundColor: COLORS.nearBlackWithMinorTransparency,
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: COLORS.gray,
            borderRadius: "4px",
          },
          "*::-webkit-scrollbar-corner": {
            // this hides an annoying white box which appears when both scrollbars are present
            backgroundColor: "transparent",
          },
        },
      },
      MuiAccordion: {
        root: {
          backgroundColor: COLORS.whiteWithTransparency,
          "&:before": {
            display: "none",
          },
        },
        rounded: {
          "&:first-child": {
            borderTopLeftRadius: "28px",
            borderTopRightRadius: "28px",
          },
          "&:last-child": {
            borderBottomLeftRadius: "28px",
            borderBottomRightRadius: "28px",
          },
        },
      },
      MuiAlert: {
        root: {
          borderRadius: "12px",
          border: "1px solid",
          // fontWeight: 600,
        },
      },
      MuiStepLabel: {
        label: {
          fontSize: "16px",
        },
      },
      MuiButton: {
        root: {
          borderRadius: "22px",
          fontWeight: 600,
          letterSpacing: "0.03em",
          // lineHeight: "24px",
          color: "#fff",
        },
        label: {
          color: "#fff",
        },
        outlinedSizeSmall: {
          padding: "6px 9px",
          fontSize: "0.70rem",
        },
      },
      MuiLink: {
        root: {
          color: COLORS.lightBlue,
          // fontWeight: 600,
        },
      },
      MuiPaper: {
        rounded: {
          borderRadius: "28px",
          backdropFilter: "blur(4px)",
        },
      },
      MuiTabs: {
        root: {
          borderBottom: `1px solid ${COLORS.white}`,
        },
        indicator: {
          height: "100%",
          background: "linear-gradient(20deg, #f44b1b 0%, #eeb430 100%);",
          zIndex: -1,
        },
      },
      MuiTab: {
        root: {
          color: COLORS.white,
          fontFamily: "Kanit, sans-serif",
          fontWeight: "bold",
          fontSize: 18,
          padding: 12,
          letterSpacing: "-0.69px",
          textTransform: "none",
        },
        textColorInherit: {
          opacity: 1,
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: "none",
        },
      },
    },
  })
);
