import { makeStyles } from "@material-ui/core";
import { useWallet } from "@xlabs-libs/wallet-aggregator-react";
import { ChainId } from "@certusone/wormhole-sdk";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  incrementStep,
  setSourceAsset,
  setSourceChain,
} from "../../store/attestSlice";
import {
  selectAttestIsSourceComplete,
  selectAttestShouldLockFields,
  selectAttestSourceAsset,
  selectAttestSourceChain,
} from "../../store/selectors";
import { CHAINS } from "../../utils/consts";
import ButtonWithLoader from "../ButtonWithLoader";
import ChainSelect from "../ChainSelect";
import KeyAndBalance from "../KeyAndBalance";
import LowBalanceWarning from "../LowBalanceWarning";
import CustomTextField from "../CustomTextField";

const useStyles = makeStyles((theme) => ({
  transferField: {
    marginTop: theme.spacing(5),
  },
  textField: {
    margin: "10px 0 0 0",
    padding: "5px 20px",
    borderColor: theme.palette.divider,
  },
}));

function Source() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sourceChain = useSelector(selectAttestSourceChain);
  const sourceAsset = useSelector(selectAttestSourceAsset);
  const isSourceComplete = useSelector(selectAttestIsSourceComplete);
  const shouldLockFields = useSelector(selectAttestShouldLockFields);
  const wallet = useWallet(sourceChain);

  const handleSourceChange = useCallback(
    (chain: ChainId) => {
      dispatch(setSourceChain(chain));
    },
    [dispatch]
  );

  const handleAssetChange = useCallback(
    (event) => {
      dispatch(setSourceAsset(event.target.value));
    },
    [dispatch]
  );

  const handleNextClick = useCallback(() => {
    dispatch(incrementStep());
  }, [dispatch]);

  return (
    <>
      <ChainSelect
        label="Source Chain"
        value={sourceChain}
        onChange={handleSourceChange}
        disabled={shouldLockFields}
        chains={CHAINS}
      />

      <CustomTextField
        placeholder="Paste asset address"
        value={sourceAsset}
        onChange={handleAssetChange}
        disabled={shouldLockFields}
        className={classes.textField}
        hideIcon
      />

      <LowBalanceWarning chainId={sourceChain} />

      <KeyAndBalance chainId={sourceChain} />

      {wallet && (
        <ButtonWithLoader
          disabled={!isSourceComplete}
          onClick={handleNextClick}
          showLoader={false}
        >
          Next
        </ButtonWithLoader>
      )}
    </>
  );
}

export default Source;
