import { Container, Box, makeStyles, Typography } from "@material-ui/core";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import useCheckIfWormholeWrapped from "../../hooks/useCheckIfWormholeWrapped";
import useFetchTargetAsset from "../../hooks/useFetchTargetAsset";
import {
  selectTransferActiveStep,
  selectTransferIsRedeemComplete,
  selectTransferIsRedeeming,
  selectTransferIsSendComplete,
  selectTransferIsSending,
} from "../../store/selectors";
import {
  setSourceChain,
  setTargetChain,
  setStep,
} from "../../store/transferSlice";
import Redeem from "./Redeem";
import RedeemPreview from "./RedeemPreview";
import Send from "./Send";
import Source from "./Source";
import Target from "./Target";
import { useDeepLinkTransferParams } from "../../hooks/useDeepLinkTransferParams";
import CustomStepper from "../CustomStepper";

const useStyles = makeStyles((theme) => ({
  con: {
    marginTop: "60px",
  },
  box: {
    padding: "30px 20px",
  },
  header: {
    textAlign: "center",
    fontWeight: 600,
  },
}));

function Transfer() {
  const classes = useStyles();
  useCheckIfWormholeWrapped();
  useFetchTargetAsset();
  const dispatch = useDispatch();
  const activeStep = useSelector(selectTransferActiveStep);
  const isSending = useSelector(selectTransferIsSending);
  const isSendComplete = useSelector(selectTransferIsSendComplete);
  const isRedeeming = useSelector(selectTransferIsRedeeming);
  const isRedeemComplete = useSelector(selectTransferIsRedeemComplete);
  const preventNavigation =
    (isSending || isSendComplete || isRedeeming) && !isRedeemComplete;

  const { search } = useLocation();
  const { sourceChain, targetChain } = useDeepLinkTransferParams(search);

  const actions = useMemo(() => {
    return {
      Source: () => dispatch(setStep(0)),
      Target: () => activeStep > 1 && dispatch(setStep(1)),
    };
  }, [activeStep, dispatch, setStep]);

  useEffect(() => {
    if (sourceChain && targetChain) {
      dispatch(setSourceChain(sourceChain));
      dispatch(setTargetChain(targetChain));
    }
  }, [sourceChain, targetChain, dispatch]);

  useEffect(() => {
    if (preventNavigation) {
      window.onbeforeunload = () => true;
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [preventNavigation]);

  return (
    <>
      <Container className={classes.con} maxWidth="sm">
        <Typography className={classes.header} variant="h2">
          Bridge Tokens
        </Typography>
        <Typography className={classes.header} variant="h5">
          Tokenwrap.finance is currently supported on desktop devices only.</Typography>
        <CustomStepper
          activeStep={activeStep}
          actions={actions}
          steps={["Source", "Target", "Send", "Redeem"]}
          content={
            <Box className={classes.box}>
              {activeStep === 0 ? (
                <Source />
              ) : activeStep === 1 ? (
                <Target />
              ) : activeStep === 2 ? (
                <Send />
              ) : activeStep === 3 ? (
                isRedeemComplete ? (
                  <RedeemPreview />
                ) : (
                  <Redeem />
                )
              ) : null}
            </Box>
          }
        />
      </Container>
    </>
  );
}

{
  /* <Container maxWidth="md">
<Stepper activeStep={activeStep} orientation="vertical">
  <Step
    expanded={activeStep >= 0}
    disabled={preventNavigation || isRedeemComplete}
  >
    <StepButton onClick={() => dispatch(setStep(0))} icon={null}>
      1. Source
    </StepButton>
    <StepContent>
      {activeStep === 0 ? <Source /> : <SourcePreview />}
    </StepContent>
  </Step>
  <Step
    expanded={activeStep >= 1}
    disabled={preventNavigation || isRedeemComplete}
  >
    <StepButton
      disabled={preventNavigation || isRedeemComplete || activeStep === 0}
      onClick={() => dispatch(setStep(1))}
      icon={null}
    >
      2. Target
    </StepButton>
    <StepContent>
      {activeStep === 1 ? <Target /> : <TargetPreview />}
    </StepContent>
  </Step>
  <Step expanded={activeStep >= 2} disabled={isSendComplete}>
    <StepButton disabled icon={null}>
      3. Send tokens
    </StepButton>
    <StepContent>
      {activeStep === 2 ? <Send /> : <SendPreview />}
    </StepContent>
  </Step>
  <Step expanded={activeStep >= 3} completed={isRedeemComplete}>
    <StepButton
      onClick={() => dispatch(setStep(3))}
      disabled={!isSendComplete || isRedeemComplete}
      icon={null}
    >
      4. Redeem tokens
    </StepButton>
    <StepContent>
      {isRedeemComplete ? <RedeemPreview /> : <Redeem />}
    </StepContent>
  </Step>
</Stepper>
</Container> */
}

export default Transfer;
