import {
  ChainId,
  CHAIN_ID_SOLANA,
  isEVMChain,
  redeemOnEth,
  redeemOnEthNative,
  redeemOnSolana,
  CHAIN_ID_POLYGON,
  hexToNativeString,
} from "@certusone/wormhole-sdk";
import { Alert } from "@material-ui/lab";
import { Connection } from "@solana/web3.js";
import { Contract, Signer } from "ethers";
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEthereumProvider } from "../contexts/EthereumProviderContext";
import { useSolanaWallet } from "../contexts/SolanaWalletContext";
import {
  selectTransferIsRedeeming,
  selectTransferTargetChain,
  selectTransferIsTBTC,
  selectTransferSourceChain,
  selectTransferSourceAsset,
  selectTransferSourceParsedTokenAccount,
  selectTransferTargetAddressHex,
  selectTransferTargetParsedTokenAccount,
  selectTransferTargetAsset,
  selectTransferAmount,
} from "../store/selectors";
import { setIsRedeeming, setRedeemTx } from "../store/transferSlice";
import {
  getTokenBridgeAddressForChain,
  SOLANA_HOST,
  SOL_BRIDGE_ADDRESS,
  SOL_TOKEN_BRIDGE_ADDRESS,
  THRESHOLD_GATEWAYS,
  MAX_VAA_UPLOAD_RETRIES_SOLANA,
} from "../utils/consts";
import parseError from "../utils/parseError";
import { postVaa, signSendAndConfirm } from "../utils/solana";
import useTransferSignedVAA from "./useTransferSignedVAA";
import { SolanaWallet } from "@xlabs-libs/wallet-aggregator-solana";
import { ThresholdL2WormholeGateway } from "../utils/ThresholdL2WormholeGateway";
import { newThresholdWormholeGateway } from "../assets/providers/tbtc/solana/WormholeGateway.v2";
import { addComputeBudget } from "../utils/computeBudget";
import { redeemAndUnwrapOnSolana } from "../utils/redeemAndUnwrap";
import mixpanel from "mixpanel-browser";
import { CHAINS_RECORD } from "../utils/consts";

// async function algo(
//   dispatch: any,
//   enqueueSnackbar: any,
//   wallet: AlgorandWallet,
//   signedVAA: Uint8Array,
//   onSuccess?: () => void
// ) {
//   dispatch(setIsRedeeming(true));
//   try {
//     const algodClient = new algosdk.Algodv2(
//       ALGORAND_HOST.algodToken,
//       ALGORAND_HOST.algodServer,
//       ALGORAND_HOST.algodPort
//     );
//     const txs = await redeemOnAlgorand(
//       algodClient as any,
//       ALGORAND_TOKEN_BRIDGE_ID,
//       ALGORAND_BRIDGE_ID,
//       signedVAA,
//       wallet.getAddress()!
//     );
//     const result = await signSendAndConfirmAlgorand(wallet, algodClient, txs);
//     // TODO: fill these out correctly
//     dispatch(
//       setRedeemTx({
//         id: txs[txs.length - 1].tx.txID(),
//         block: result["confirmed-round"],
//       })
//     );
//     enqueueSnackbar(null, {
//       content: <Alert severity="success">Transaction confirmed</Alert>,
//     });
//     onSuccess?.();
//   } catch (e) {
//     enqueueSnackbar(null, {
//       content: <Alert severity="error">{parseError(e)}</Alert>,
//     });
//     dispatch(setIsRedeeming(false));
//   }
// }

// async function aptos(
//   dispatch: any,
//   enqueueSnackbar: any,
//   signedVAA: Uint8Array,
//   wallet: AptosWallet,
//   onSuccess?: () => void
// ) {
//   dispatch(setIsRedeeming(true));
//   const tokenBridgeAddress = getTokenBridgeAddressForChain(CHAIN_ID_APTOS);
//   try {
//     const msg = await completeTransferAndRegister(
//       getAptosClient(),
//       tokenBridgeAddress,
//       signedVAA
//     );
//     msg.arguments[0] = Array.from(msg.arguments[0]);
//     const result = await waitForSignAndSubmitTransaction(msg, wallet);
//     dispatch(setRedeemTx({ id: result, block: 1 }));
//     enqueueSnackbar(null, {
//       content: <Alert severity="success">Transaction confirmed</Alert>,
//     });
//     onSuccess?.();
//   } catch (e) {
//     enqueueSnackbar(null, {
//       content: <Alert severity="error">{parseError(e)}</Alert>,
//     });
//     dispatch(setIsRedeeming(false));
//   }
// }

async function evm(
  dispatch: any,
  enqueueSnackbar: any,
  signer: Signer,
  signedVAA: Uint8Array,
  isNative: boolean,
  chainId: ChainId,
  emit: (wallet: string, isNative: boolean, txId?: string) => void,
  isTBTC?: boolean
) {
  dispatch(setIsRedeeming(true));

  try {
    let receipt;
    /**
     * if THRESHOLD_GATEWAYS[chainId] has something
     * we have a gateway contract on the target chain to use
     */
    const isCanonicalTarget = !!THRESHOLD_GATEWAYS[chainId];
    if (isTBTC && isCanonicalTarget) {
      console.log("redeem tbtc on canonical");
      const targetAddress = THRESHOLD_GATEWAYS[chainId];
      const L2WormholeGateway = new Contract(
        targetAddress,
        ThresholdL2WormholeGateway,
        signer
      );

      const estimateGas =
        await L2WormholeGateway.estimateGas.receiveTbtc(signedVAA);

      // We increase the gas limit estimation here by a factor of 10% to account for some faulty public JSON-RPC endpoints.
      const gasLimit = estimateGas.mul(1100).div(1000);
      const overrides = {
        gasLimit,
        // We use the legacy tx envelope here to avoid triggering gas price autodetection using EIP1559 for polygon.
        // EIP1559 is not actually implemented in polygon. The node is only API compatible but this breaks some clients
        // like ethers when choosing fees automatically.
        ...(chainId === CHAIN_ID_POLYGON && { type: 0 }),
      };

      const tx = await L2WormholeGateway.receiveTbtc(signedVAA, overrides);
      receipt = await tx.wait();
    }
    // REGULAR PORTAL BRIDGE FLOW
    else {
      // Klaytn requires specifying gasPrice
      // const overrides =
      //   chainId === CHAIN_ID_KLAYTN
      //     ? { gasPrice: (await signer.getGasPrice()).toString() }
      //     : {};

      const overrides = {};

      receipt = isNative
        ? await redeemOnEthNative(
            getTokenBridgeAddressForChain(chainId),
            signer,
            signedVAA,
            overrides
          )
        : await redeemOnEth(
            getTokenBridgeAddressForChain(chainId),
            signer,
            signedVAA,
            overrides
          );
    }

    dispatch(
      setRedeemTx({ id: receipt.transactionHash, block: receipt.blockNumber })
    );
    enqueueSnackbar(null, {
      content: <Alert severity="success">Transaction confirmed</Alert>,
    });

    const wAddress = await signer.getAddress();
    emit(wAddress, isNative, receipt.transactionHash);
  } catch (e) {
    console.error(e);
    enqueueSnackbar(null, {
      content: <Alert severity="error">{parseError(e)}</Alert>,
    });
    dispatch(setIsRedeeming(false));

    const wAddress = await signer.getAddress();
    emit(wAddress, isNative);
  }
}

// async function near(
//   dispatch: any,
//   enqueueSnackbar: any,
//   senderAddr: string,
//   signedVAA: Uint8Array,
//   wallet: NearWallet,
//   onSuccess?: () => void
// ) {
//   dispatch(setIsRedeeming(true));
//   try {
//     const account = await makeNearAccount(senderAddr);
//     const msgs = await redeemOnNear(
//       account,
//       NEAR_TOKEN_BRIDGE_ACCOUNT,
//       signedVAA
//     );
//     const receipt = await signAndSendTransactions(account, wallet, msgs);
//     dispatch(
//       setRedeemTx({
//         id: receipt.transaction_outcome.id,
//         block: 0,
//       })
//     );
//     enqueueSnackbar(null, {
//       content: <Alert severity="success">Transaction confirmed</Alert>,
//     });
//     onSuccess?.();
//   } catch (e) {
//     enqueueSnackbar(null, {
//       content: <Alert severity="error">{parseError(e)}</Alert>,
//     });
//     dispatch(setIsRedeeming(false));
//   }
// }

// async function xpla(
//   dispatch: any,
//   enqueueSnackbar: any,
//   wallet: XplaWallet,
//   signedVAA: Uint8Array,
//   onSuccess?: () => void
// ) {
//   dispatch(setIsRedeeming(true));
//   try {
//     const msg = redeemOnXpla(
//       getTokenBridgeAddressForChain(CHAIN_ID_XPLA),
//       wallet.getAddress()!,
//       signedVAA
//     );
//     const result = await postWithFeesXpla(
//       wallet,
//       [msg],
//       "Wormhole - Complete Transfer"
//     );
//     dispatch(
//       setRedeemTx({ id: result.result.txhash, block: result.result.height })
//     );
//     enqueueSnackbar(null, {
//       content: <Alert severity="success">Transaction confirmed</Alert>,
//     });
//     onSuccess?.();
//   } catch (e) {
//     enqueueSnackbar(null, {
//       content: <Alert severity="error">{parseError(e)}</Alert>,
//     });
//     dispatch(setIsRedeeming(false));
//   }
// }

// async function sei(
//   dispatch: any,
//   enqueueSnackbar: any,
//   wallet: SeiWallet,
//   signedVAA: Uint8Array,
//   onSuccess?: () => void
// ) {
//   dispatch(setIsRedeeming(true));
//   try {
//     const vaa = parseVaa(signedVAA);
//     const transfer = parseTokenTransferPayload(vaa.payload);
//     const receiver = cosmos.humanAddress("sei", transfer.to);
//     const contractAddress =
//       receiver === SEI_TRANSLATOR
//         ? SEI_TRANSLATOR
//         : getTokenBridgeAddressForChain(CHAIN_ID_SEI);

//     const instructions =
//       receiver === SEI_TRANSLATOR
//         ? [
//             {
//               contractAddress,
//               msg: {
//                 complete_transfer_and_convert: {
//                   vaa: fromUint8Array(signedVAA),
//                 },
//               },
//             },
//           ]
//         : [
//             {
//               contractAddress,
//               msg: {
//                 submit_vaa: {
//                   data: fromUint8Array(signedVAA),
//                 },
//               },
//             },
//           ];
//     const fee = await calculateFeeForContractExecution(
//       instructions,
//       wallet,
//       "Wormhole - Complete Transfer"
//     );

//     // Increase timeout to 3 minutes
//     const tx = await wallet.executeMultiple(
//       {
//         instructions,
//         fee,
//         memo: "Wormhole - Complete Transfer",
//       },
//       { broadcastTimeoutMs: 180000 }
//     );

//     if (!tx.data?.height) {
//       console.error("Error: No tx height [sei redeem]");
//       return;
//     }

//     dispatch(setRedeemTx({ id: tx.id, block: tx.data.height }));
//     enqueueSnackbar(null, {
//       content: <Alert severity="success">Transaction confirmed</Alert>,
//     });
//     onSuccess?.();
//   } catch (e) {
//     enqueueSnackbar(null, {
//       content: <Alert severity="error">{parseError(e)}</Alert>,
//     });
//     dispatch(setIsRedeeming(false));
//   }
// }

// async function injective(
//   dispatch: any,
//   enqueueSnackbar: any,
//   wallet: InjectiveWallet,
//   walletAddress: string,
//   signedVAA: Uint8Array,
//   onSuccess?: () => void
// ) {
//   dispatch(setIsRedeeming(true));
//   try {
//     const msg = await redeemOnInjective(
//       getTokenBridgeAddressForChain(CHAIN_ID_INJECTIVE),
//       walletAddress,
//       signedVAA
//     );
//     const tx = await broadcastInjectiveTx(
//       wallet,
//       walletAddress,
//       msg,
//       "Wormhole - Complete Transfer"
//     );
//     dispatch(setRedeemTx({ id: tx.txHash, block: tx.height }));
//     enqueueSnackbar(null, {
//       content: <Alert severity="success">Transaction confirmed</Alert>,
//     });
//     onSuccess?.();
//   } catch (e) {
//     enqueueSnackbar(null, {
//       content: <Alert severity="error">{parseError(e)}</Alert>,
//     });
//     dispatch(setIsRedeeming(false));
//   }
// }

async function solana(
  dispatch: any,
  enqueueSnackbar: any,
  wallet: SolanaWallet,
  payerAddress: string, //TODO: we may not need this since we have wallet
  signedVAA: Uint8Array,
  isNative: boolean,
  isTbtc: boolean,
  emit: (wallet: string, isNative: boolean, txId?: string) => void
) {
  dispatch(setIsRedeeming(true));
  try {
    if (!wallet.signTransaction) {
      throw new Error("wallet.signTransaction is undefined");
    }
    const connection = new Connection(SOLANA_HOST, "confirmed");
    // TODO compute the amount of tx that postVaaSolanaWithRetry
    // will create to notice the user up front
    // we could call createPostSignedVaaTransactions to create fake txs
    // and read the length of the array
    await postVaa(
      connection,
      wallet.signTransaction.bind(wallet),
      SOL_BRIDGE_ADDRESS,
      payerAddress,
      Buffer.from(signedVAA),
      { maxRetries: MAX_VAA_UPLOAD_RETRIES_SOLANA }
    );

    if (isTbtc) {
      const tbtcGateway = newThresholdWormholeGateway(connection, wallet);
      const transaction = await tbtcGateway.receiveTbtc(
        signedVAA,
        payerAddress
      );
      const txid = await signSendAndConfirm(wallet, transaction);
      dispatch(setRedeemTx({ id: txid, block: 1 }));
      enqueueSnackbar(null, {
        content: <Alert severity="success">Transaction confirmed</Alert>,
      });

      emit(payerAddress, isNative, txid);
    } else {
      // TODO: how do we retry in between these steps
      const transaction = isNative
        ? await redeemAndUnwrapOnSolana(
            connection,
            SOL_BRIDGE_ADDRESS,
            SOL_TOKEN_BRIDGE_ADDRESS,
            payerAddress,
            signedVAA
          )
        : await redeemOnSolana(
            connection,
            SOL_BRIDGE_ADDRESS,
            SOL_TOKEN_BRIDGE_ADDRESS,
            payerAddress,
            signedVAA
          );

      if (!isNative) await addComputeBudget(connection!, transaction);
      const txid = await signSendAndConfirm(wallet, transaction);
      // TODO: didn't want to make an info call we didn't need, can we get the block without it by modifying the above call?
      dispatch(setRedeemTx({ id: txid, block: 1 }));
      enqueueSnackbar(null, {
        content: <Alert severity="success">Transaction confirmed</Alert>,
      });
      emit(payerAddress, isNative, txid);
    }
  } catch (e) {
    console.log(e);
    enqueueSnackbar(null, {
      content: <Alert severity="error">{parseError(e)}</Alert>,
    });
    dispatch(setIsRedeeming(false));
    emit(payerAddress, isNative);
  }
}

// async function terra(
//   dispatch: any,
//   enqueueSnackbar: any,
//   wallet: TerraWallet,
//   signedVAA: Uint8Array,
//   feeDenom: string,
//   chainId: TerraChainId,
//   onSuccess?: () => void
// ) {
//   dispatch(setIsRedeeming(true));
//   try {
//     const msg = await redeemOnTerra(
//       getTokenBridgeAddressForChain(chainId),
//       wallet.getAddress()!,
//       signedVAA
//     );
//     const result = await postWithFees(
//       wallet,
//       [msg],
//       "Wormhole - Complete Transfer",
//       [feeDenom],
//       chainId
//     );
//     dispatch(
//       setRedeemTx({ id: result.result.txhash, block: result.result.height })
//     );
//     enqueueSnackbar(null, {
//       content: <Alert severity="success">Transaction confirmed</Alert>,
//     });
//     onSuccess?.();
//   } catch (e) {
//     enqueueSnackbar(null, {
//       content: <Alert severity="error">{parseError(e)}</Alert>,
//     });
//     dispatch(setIsRedeeming(false));
//   }
// }

// async function sui(
//   dispatch: any,
//   enqueueSnackbar: any,
//   wallet: SuiWallet,
//   signedVAA: Uint8Array,
//   onSuccess?: () => void
// ) {
//   dispatch(setIsRedeeming(true));
//   try {
//     const provider = getSuiProvider();
//     const tx = await redeemOnSui(
//       provider,
//       getBridgeAddressForChain(CHAIN_ID_SUI),
//       getTokenBridgeAddressForChain(CHAIN_ID_SUI),
//       signedVAA
//     );
//     const response = (
//       await wallet.signAndSendTransaction({
//         transactionBlock: tx,
//       })
//     ).data;
//     if (!response) {
//       throw new Error("Error parsing transaction results");
//     }
//     dispatch(
//       setRedeemTx({
//         id: response.digest,
//         block: Number(response.checkpoint || 0),
//       })
//     );
//     enqueueSnackbar(null, {
//       content: <Alert severity="success">Transaction confirmed</Alert>,
//     });
//     onSuccess?.();
//   } catch (e) {
//     enqueueSnackbar(null, {
//       content: <Alert severity="error">{parseError(e)}</Alert>,
//     });
//     dispatch(setIsRedeeming(false));
//   }
// }

export function useHandleRedeem() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const targetChain = useSelector(selectTransferTargetChain);
  const isTBTC = useSelector(selectTransferIsTBTC);
  const { publicKey: solPK, wallet: solanaWallet } = useSolanaWallet();
  const { signer } = useEthereumProvider(targetChain as any);
  // const { wallet: terraWallet } = useTerraWallet(targetChain as any);
  // const terraFeeDenom = useSelector(selectTerraFeeDenom);
  // const xplaWallet = useXplaWallet();
  // const { address: algoAccount, wallet: algoWallet } = useAlgorandWallet();
  // const { accountId: nearAccountId, wallet } = useNearContext();
  // const { account: aptosAddress, wallet: aptosWallet } = useAptosContext();
  // const { wallet: injWallet, address: injAddress } = useInjectiveContext();
  // const suiWallet = useSuiWallet();
  // const seiWallet = useSeiWallet();
  // const seiAddress = seiWallet?.getAddress();
  const signedVAA = useTransferSignedVAA();
  const isRedeeming = useSelector(selectTransferIsRedeeming);
  const sourceChain = useSelector(selectTransferSourceChain);
  const sourceAsset = useSelector(selectTransferSourceAsset);
  const targetAsset = useSelector(selectTransferTargetAsset);
  const amount = useSelector(selectTransferAmount);
  const sourceParsedTokenAccount = useSelector(
    selectTransferSourceParsedTokenAccount
  );
  const targetParsedTokenAccount = useSelector(
    selectTransferTargetParsedTokenAccount
  );
  const targetAddressHex = useSelector(selectTransferTargetAddressHex);

  const emit = useCallback(
    (wallet: string, isNative: boolean, txId?: string) => {
      mixpanel.track(`Redeem Txn ${txId ? "" : "Rejected"}`, {
        Source: CHAINS_RECORD[sourceChain].name,
        Target: CHAINS_RECORD[targetChain].name,
        "Target Asset": targetAsset,
        "Target Symbol": targetParsedTokenAccount?.symbol,
        "Target Name": targetParsedTokenAccount?.name,
        "Source Asset": sourceAsset,
        "Soucre Symbol": sourceParsedTokenAccount?.symbol,
        "Soucre Name": sourceParsedTokenAccount?.name,
        Amount: amount,
        Wallet: wallet,
        "Target Wallet": hexToNativeString(targetAddressHex, targetChain) || "",
        isNative,
        "Txn Hash": txId,
      });
    },
    [
      sourceChain,
      targetChain,
      targetAsset,
      targetParsedTokenAccount?.symbol,
      targetParsedTokenAccount?.name,
      sourceAsset,
      sourceParsedTokenAccount?.symbol,
      sourceParsedTokenAccount?.name,
      amount,
      targetAddressHex,
    ]
  );

  const handleRedeemClick = useCallback(() => {
    if (isEVMChain(targetChain) && !!signer && signedVAA) {
      evm(
        dispatch,
        enqueueSnackbar,
        signer,
        signedVAA,
        false,
        targetChain,
        emit,
        isTBTC
      );
    } else if (
      targetChain === CHAIN_ID_SOLANA &&
      !!solanaWallet &&
      !!solPK &&
      signedVAA
    ) {
      solana(
        dispatch,
        enqueueSnackbar,
        solanaWallet,
        solPK,
        signedVAA,
        false,
        isTBTC,
        emit
      );
    }
    // else if (isTerraChain(targetChain) && !!terraWallet && signedVAA) {
    //   terra(
    //     dispatch,
    //     enqueueSnackbar,
    //     terraWallet,
    //     signedVAA,
    //     terraFeeDenom,
    //     targetChain,
    //     onSuccess
    //   );
    // }
    // else if (targetChain === CHAIN_ID_XPLA && !!xplaWallet && signedVAA) {
    //   xpla(dispatch, enqueueSnackbar, xplaWallet, signedVAA, onSuccess);
    // }
    // else if (
    //   targetChain === CHAIN_ID_SEI &&
    //   seiWallet &&
    //   seiAddress &&
    //   signedVAA
    // ) {
    //   sei(dispatch, enqueueSnackbar, seiWallet, signedVAA, onSuccess);
    // }
    //  else if (targetChain === CHAIN_ID_APTOS && !!aptosAddress && signedVAA) {
    //   aptos(dispatch, enqueueSnackbar, signedVAA, aptosWallet!, onSuccess);
    // }
    // else if (
    //   targetChain === CHAIN_ID_ALGORAND &&
    //   algoAccount &&
    //   !!signedVAA
    // ) {
    //   algo(dispatch, enqueueSnackbar, algoWallet, signedVAA, onSuccess);
    // }
    // else if (
    //   targetChain === CHAIN_ID_NEAR &&
    //   nearAccountId &&
    //   wallet &&
    //   !!signedVAA
    // ) {
    //   near(
    //     dispatch,
    //     enqueueSnackbar,
    //     nearAccountId,
    //     signedVAA,
    //     wallet,
    //     onSuccess
    //   );
    // }
    // else if (
    //   targetChain === CHAIN_ID_INJECTIVE &&
    //   injWallet &&
    //   injAddress &&
    //   signedVAA
    // ) {
    //   injective(
    //     dispatch,
    //     enqueueSnackbar,
    //     injWallet,
    //     injAddress,
    //     signedVAA,
    //     onSuccess
    //   );
    // }
    // else if (
    //   targetChain === CHAIN_ID_SUI &&
    //   suiWallet?.getAddress() &&
    //   !!signedVAA
    // ) {
    //   sui(dispatch, enqueueSnackbar, suiWallet, signedVAA, onSuccess);
    // }
  }, [
    targetChain,
    signer,
    signedVAA,
    solanaWallet,
    solPK,
    // terraWallet,
    // xplaWallet,
    // seiWallet,
    // seiAddress,
    // aptosAddress,
    // algoAccount,
    // nearAccountId,
    // wallet,
    // injWallet,
    // injAddress,
    // suiWallet,
    dispatch,
    enqueueSnackbar,
    isTBTC,
    emit,
    // terraFeeDenom,
    // aptosWallet,
    // algoWallet,
  ]);

  const handleRedeemNativeClick = useCallback(() => {
    if (isEVMChain(targetChain) && !!signer && signedVAA) {
      evm(
        dispatch,
        enqueueSnackbar,
        signer,
        signedVAA,
        true,
        targetChain,
        emit,
        undefined
      );
    } else if (
      targetChain === CHAIN_ID_SOLANA &&
      !!solanaWallet &&
      !!solPK &&
      signedVAA
    ) {
      solana(
        dispatch,
        enqueueSnackbar,
        solanaWallet,
        solPK,
        signedVAA,
        true,
        isTBTC,
        emit
      );
    }
    // else if (isTerraChain(targetChain) && !!terraWallet && signedVAA) {
    //   terra(
    //     dispatch,
    //     enqueueSnackbar,
    //     terraWallet,
    //     signedVAA,
    //     terraFeeDenom,
    //     targetChain,
    //     onSuccess
    //   ); //TODO isNative = true
    // } else if (
    //   targetChain === CHAIN_ID_ALGORAND &&
    //   algoAccount &&
    //   !!signedVAA
    // ) {
    //   algo(dispatch, enqueueSnackbar, algoWallet, signedVAA, onSuccess);
    // } else if (
    //   targetChain === CHAIN_ID_INJECTIVE &&
    //   injWallet &&
    //   injAddress &&
    //   signedVAA
    // ) {
    //   injective(
    //     dispatch,
    //     enqueueSnackbar,
    //     injWallet,
    //     injAddress,
    //     signedVAA,
    //     onSuccess
    //   );
    // } else if (
    //   targetChain === CHAIN_ID_SEI &&
    //   seiWallet &&
    //   seiAddress &&
    //   signedVAA
    // ) {
    //   sei(dispatch, enqueueSnackbar, seiWallet, signedVAA, onSuccess);
    // } else if (
    //   targetChain === CHAIN_ID_SUI &&
    //   suiWallet?.getAddress() &&
    //   signedVAA
    // ) {
    //   sui(dispatch, enqueueSnackbar, suiWallet, signedVAA, onSuccess);
    // }
  }, [
    targetChain,
    signer,
    signedVAA,
    solanaWallet,
    solPK,
    // terraWallet,
    // algoAccount,
    // injWallet,
    // injAddress,
    // seiWallet,
    // seiAddress,
    // suiWallet,
    dispatch,
    enqueueSnackbar,
    emit,
    isTBTC,
    // terraFeeDenom,
    // algoWallet,
  ]);

  const handleAcalaRelayerRedeemClick = useCallback(async () => {
    // if (!signedVAA) return;
    // dispatch(setIsRedeeming(true));
    // try {
    //   const res = await axios.post(ACALA_RELAY_URL, {
    //     targetChain,
    //     signedVAA: uint8ArrayToHex(signedVAA),
    //   });
    //   dispatch(
    //     setRedeemTx({
    //       id: res.data.transactionHash,
    //       block: res.data.blockNumber,
    //     })
    //   );
    //   enqueueSnackbar(null, {
    //     content: <Alert severity="success">Transaction confirmed</Alert>,
    //   });
    // } catch (e) {
    //   enqueueSnackbar(null, {
    //     content: <Alert severity="error">{parseError(e)}</Alert>,
    //   });
    //   dispatch(setIsRedeeming(false));
    // }
  }, [targetChain, signedVAA, enqueueSnackbar, dispatch]);

  return useMemo(
    () => ({
      handleNativeClick: handleRedeemNativeClick,
      handleClick: handleRedeemClick,
      handleAcalaRelayerRedeemClick,
      disabled: !!isRedeeming,
      showLoader: !!isRedeeming,
    }),
    [
      handleRedeemClick,
      isRedeeming,
      handleRedeemNativeClick,
      handleAcalaRelayerRedeemClick,
    ]
  );
}
