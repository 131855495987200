import React from "react";

const Disclaimer: React.FC = () => {
  return (
    <div
      style={{
        margin: "20px auto",
        maxWidth: "90%", // Flexible width for mobile devices
        fontSize: "14px",
        lineHeight: "1.6",
        textAlign: "justify", // Justified text alignment for better readability
        color: "#666", // Subtle text color
      }}
    >
      <p>
        This Interface is developed by <strong>Tokenwrap</strong>, independent developers leveraging PortalBridge and
        Wormhole SDK. The code will be open-sourced to encourage transparency and collaboration.
      </p>
      <p>
        This Interface and the Wormhole protocol are provided “as is” and at your own risk. Tokenwrap is not responsible
        for any claims, losses, or damages arising from its use, including loss of funds or tokens.
      </p>
      <p>
        By using this Interface, you confirm compliance with all applicable laws and regulations, and that you are not
        restricted or sanctioned under any jurisdiction.
      </p>
    </div>
  );
};

export default Disclaimer;
