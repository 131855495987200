import { Container, Typography, makeStyles, Box } from "@material-ui/core";
import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  selectAttestActiveStep,
  selectAttestIsCreateComplete,
  selectAttestIsCreating,
  selectAttestIsSendComplete,
  selectAttestIsSending,
} from "../../store/selectors";
import { setStep } from "../../store/attestSlice";
import Create from "./Create";
import CreatePreview from "./CreatePreview";
import Send from "./Send";
import Source from "./Source";
import Target from "./Target";
import CustomStepper from "../CustomStepper";

const useStyles = makeStyles((theme) => ({
  con: {
    marginTop: "60px",
  },
  box: {
    padding: "30px 20px",
  },
  header: {
    textAlign: "center",
    fontWeight: 600,
    letterSpacing: 2,
  },
}));

function Attest() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeStep = useSelector(selectAttestActiveStep);
  const isSending = useSelector(selectAttestIsSending);
  const isSendComplete = useSelector(selectAttestIsSendComplete);
  const isCreating = useSelector(selectAttestIsCreating);
  const isCreateComplete = useSelector(selectAttestIsCreateComplete);

  const preventNavigation =
    (isSending || isSendComplete || isCreating) && !isCreateComplete;

  const actions = useMemo(() => {
    return {
      Source: () => dispatch(setStep(0)),
      Target: () => activeStep > 1 && dispatch(setStep(1)),
    };
  }, [activeStep, dispatch, setStep]);

  useEffect(() => {
    if (preventNavigation) {
      window.onbeforeunload = () => true;
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [preventNavigation]);

  return (
    <Container maxWidth="sm" className={classes.con}>
      <Typography className={classes.header} variant="h2">
        Register Token
      </Typography>
      <CustomStepper
        activeStep={activeStep}
        actions={actions}
        steps={["Source", "Target", "Attest", "Create"]}
        content={
          <Box className={classes.box}>
            {activeStep === 0 ? (
              <Source />
            ) : activeStep === 1 ? (
              <Target />
            ) : activeStep === 2 ? (
              <Send />
            ) : activeStep === 3 ? (
              isCreateComplete ? (
                <CreatePreview />
              ) : (
                <Create />
              )
            ) : null}
          </Box>
        }
      />
    </Container>
  );
}

export default Attest;
