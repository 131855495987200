import { makeStyles, Typography, Box } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectTransferRedeemTx,
  selectTransferTargetChain,
} from "../../store/selectors";
import { reset } from "../../store/transferSlice";
import ButtonWithLoader from "../ButtonWithLoader";
import ShowTx from "../ShowTx";
import AddToMetamask from "./AddToMetamask";

const useStyles = makeStyles((theme) => ({
  successBox: {
    width: "100%",
    fontSize: "100px",
    color: "#4BB543",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  description: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
}));

export default function RedeemPreview({
  overrideExplainerString,
}: {
  overrideExplainerString?: string;
  handleIncrement?: () => void;
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const targetChain = useSelector(selectTransferTargetChain);
  const redeemTx = useSelector(selectTransferRedeemTx);

  const handleResetClick = useCallback(() => {
    dispatch(reset());
  }, [dispatch]);

  const explainerString =
    overrideExplainerString ||
    "Success! The redeem transaction was submitted. The tokens will become available once the transaction confirms.";

  return (
    <>
      <Box className={classes.successBox}>
        <CheckCircleIcon fontSize="inherit" />
      </Box>
      <Typography
        component="div"
        variant="subtitle1"
        className={classes.description}
      >
        {explainerString}
      </Typography>

      {redeemTx ? (
        <Box style={{ marginBottom: "10px" }}>
          <ShowTx
            chainId={targetChain}
            tx={redeemTx}
            showWormscanLink={false}
          />
        </Box>
      ) : null}

      <AddToMetamask chainId={targetChain} />

      <ButtonWithLoader onClick={handleResetClick}>
        Transfer More Tokens!
      </ButtonWithLoader>
    </>
  );
}
