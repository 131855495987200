import axios from "axios";

const INDEXING_SERVER = "https://tokenwrapbe-f2a8af640428.herokuapp.com";

const axiosInstance = axios.create({
  baseURL: INDEXING_SERVER,
  headers: {
    "Content-type": "application/json",
  },
});

export async function createToken(chain: number, hash: string) {
  try {
    await axiosInstance.post("/token-registry", {
      chain,
      hash,
    });
  } catch (error) {
    console.error("completeTxn", error);
  }
}

export async function getTokens() {
  try {
    const res = await axiosInstance.get("/token-registry");
    if (res.data) {
      return res.data.result;
    }
  } catch (error) {
    console.error("getTokens", error);
  }
}
