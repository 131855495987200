import { IconButton, makeStyles } from "@material-ui/core";
import { SwapHoriz } from "@material-ui/icons";

import DisableWrapper from "./DisableWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    width: "auto",
    borderRadius: "50%",
    left: "47%",
    background: theme.palette.background.paper,
    borderColor: theme.palette.divider,
    color: theme.palette.primary.main,
    border: "1px solid",
    zIndex: 1,
    "@media (max-width:450px)": {
      left: "45%",
    },
  },
}));

export default function ChainSelectArrow({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) {
  const classes = useStyles();

  return (
    <DisableWrapper className={classes.root} disable={disabled}>
      <IconButton size="small" onClick={onClick}>
        <SwapHoriz />
      </IconButton>
    </DisableWrapper>
  );
}
