import { ChainId, CHAIN_ID_SOLANA, isEVMChain } from "@certusone/wormhole-sdk";
import ConnectWalletButton from "./ConnectWalletButton";

function isChainAllowed(chainId: ChainId) {
  return isEVMChain(chainId) || chainId === CHAIN_ID_SOLANA;
}

function KeyAndBalance({ chainId }: { chainId: ChainId }) {
  if (isChainAllowed(chainId)) {
    return <ConnectWalletButton chainId={chainId} />;
  }

  return null;
}

export default KeyAndBalance;
